import React, { useEffect } from "react";
import { Button } from "@mui/material";

let userPermissions = JSON.parse(localStorage.getItem("userPermission"));
const DownloadButton = ({ onclick, disabled, children, ...rest }) => {
  useEffect(() => {
    userPermissions = JSON.parse(localStorage.getItem("userPermission"));
  }, [children]);
  const onClick = () => {
    if (
      typeof onClick !== "undefined" &&
      (userPermissions?.permissions?.includes("Publish Content") ||
        userPermissions?.roles.includes("admin"))
    ) {
      onclick();
    }
  };
  return (
    <Button
      {...rest}
      onClick={onClick}
      variant="outlined"
      disabled={
        !(
          (userPermissions?.permissions?.includes("Publish Content") ||
            userPermissions?.roles.includes("admin")) &&
          disabled
        )
      }
      style={{
        pointerEvents: "auto !important",
        cursor:
          userPermissions?.permissions?.includes("Publish Content") ||
          userPermissions?.roles.includes("admin")
            ? "pointer"
            : "not-allowed !important",
      }}
      sx={{
        background:
          (userPermissions?.permissions?.includes("Publish Content") ||
            userPermissions?.roles.includes("admin")) &&
          disabled
            ? "#4ac24b"
            : "transparent",
        color: "#fefefe",
        maxHeight: "2.5rem",
        "&:hover": {
          color: "#fefefe",
          background: "#1aaa26",
        },
      }}
    >
      {children}
    </Button>
  );
};

export default DownloadButton;
