import React, { useState, useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { snackbarMessageUpdate } from "@store/config/configSlice";
import { useDispatch, useSelector } from "react-redux";
import GlobalDropdown from "@components/common/GlobalDropedown";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../services/apiQueryServices";
import PropTypes from "prop-types";

import { addFeedOwnerList } from "../../store/ExportDataModule/FeedExecutionListSlice/AddFeedExecutionSlice";
import { updateTableRow } from "../../store/ExportDataModule/FeedExecutionListSlice";

const AddExecutionList = ({
  setOpen,
  isOpen,
  defaultValues,
  name,
  loadData,
  isEditMode,
}) => {
  const [defaultValuesSchema, setDefaultValuesSchema] = useState();

  const dispatch = useDispatch();
  const FeedOwnerListData = useSelector((state) => state?.feedOwnerList?.data);

  const headers = {
    "Content-Type": "application/json", // Example header
    Authorization: `Bearer ${
      JSON.parse(localStorage.getItem("user"))?.token.access_token
    }`, // Example header
  };
  const FeedOwnerQuery = useGetApiCallQuery({
    endPoint: "dl/export/data-source/list-items",
    method: "GET",
    headers: { headers },
  });

  useEffect(() => {
    FeedOwnerQuery.refetch();
  }, []);

  useEffect(() => {
    if (FeedOwnerQuery.isSuccess) {
      if (FeedOwnerQuery?.data?.data !== null) {
        dispatch(addFeedOwnerList([...FeedOwnerQuery?.data?.data].reverse()));
      } else {
        dispatch(addFeedOwnerList([]));
      }
    }
  }, [FeedOwnerQuery.data]);

  const [apiCall, res] = useApiCallMutation();

  useEffect(() => {
    if (res.isSuccess && res?.data?.data !== null) {
      successCallback(res);
      dispatch(updateTableRow(res.data.data));
    }
  }, [res]);

  const validationSchema = Yup.object().shape({
    // name: Yup.string().required("This Field is required"),

    feedType: Yup.string().required("This field is required"),
    sourceId: Yup.mixed().test("sdfdsfdf", function (value) {
      const { feedType } = this.parent;
      if (feedType === "single" && (value === undefined || value === "")) {
        return this.createError({
          path: "sourceId",
          message: "For single feed type, sourceId is required",
        });
      } else if (
        feedType === "multi_sync" &&
        (value === undefined || value.length <= 1)
      ) {
        return this.createError({
          path: "sourceId",
          message:
            "For multi_sync feed type, sourceId should have more than one value",
        });
      } else {
        return true; // Validation passed
      }
    }),
  });

  const feedExecutinoForm = useForm({
    defaultValues: {
      name: "",
      feedType: "",
      remark: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, register, control, setValue, watch, getValues } =
    feedExecutinoForm;

  const { errors } = feedExecutinoForm.formState;

  const handleMultiSelectChange = () => {
    if (getValues("sourceId") === "single") {
      setValue("sourceId", "");
    } else {
      setValue("sourceId", []);
    }
  };

  useEffect(() => {
    setDefaultValuesSchema(defaultValues);
    if (defaultValuesSchema?.config) {
      setValue("feedType", defaultValuesSchema?.feedType, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue("name", defaultValuesSchema?.name, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue("sourceId", defaultValuesSchema?.sourceId, {
        shouldTouch: true,
        shouldDirty: true,
        // shouldValidate: true,
      });
      setValue("remark", defaultValuesSchema?.remark);
    }
  }, [defaultValuesSchema]);

  const successCallback = (response) => {
    const status = response?.data?.status;

    setOpen(false);
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "success",
        message: status?.value,
      })
    );
  };

  let data = {};
  const onFormSubmit = (formValue) => {
    if (
      typeof formValue?.sourceId === "number" ||
      typeof formValue?.sourceId === "string"
    ) {
      const finalName = FeedOwnerListData?.filter(
        (item) => item.value === formValue?.sourceId
      );
      data = {
        name: finalName[0]?.label,
        sourceId: [formValue?.sourceId],
        feedType: formValue?.feedType,
        remark: formValue?.remark,
      };
    } else {
      const stringName = `${formValue?.sourceId?.map((item) => item.label)}`;
      data = {
        name: stringName.toString(),
        sourceId: formValue?.sourceId?.map((item) => item.value),
        feedType: formValue?.feedType,
        remark: formValue?.remark,
      };
    }
    if (data.name) {
      apiCall({
        endPoint: `dl/export/data-source-execution/create`,
        method: "POST",
        data: { data },
      });
    }
  };

  return (
    <Drawer anchor={"right"} open={isOpen} onClose={() => setOpen(false)}>
      <Box
        sx={{
          width: "50vw",
          pt: "1rem",
          pr: "1rem",
          pl: "1rem",
        }}
      >
        <Box marginBottom={"15px"}>
          <Typography component="h5" variant="h5" fontWeight={600}>
            {"Add Feed Execution"}
          </Typography>
        </Box>
        {errors.sourceId?.message}
        {errors.remark?.message}
        <Divider
          sx={{
            mb: "20px",
          }}
        />
        <FormProvider {...feedExecutinoForm}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <GlobalDropdown
                  options={[
                    { label: "Single", value: "single" },
                    { label: "Multi-sync", value: "multi_sync" },
                  ]}
                  control={control}
                  name="feedType"
                  label="Execution Type"
                  error={!!errors.feedType}
                  helperText={errors.feedType?.message}
                  required
                  onClick={handleMultiSelectChange}
                />
              </Grid>
              {watch("feedType") === "single" && (
                <Grid item xs={12}>
                  <GlobalDropdown
                    options={FeedOwnerListData}
                    control={control}
                    name="sourceId"
                    label="Feeds"
                    error={!!errors.sourceId}
                    helperText={errors.sourceId?.message}
                    required
                  />
                </Grid>
              )}

              {watch("feedType") === "multi_sync" && (
                <Grid item xs={12}>
                  <Controller
                    name="sourceId"
                    control={control}
                    // defaultValue={[]}
                    render={({ field }) => {
                      const handleAutocompleteChange = (event, newValue) => {
                        const uniqueValues = newValue.filter(
                          (value, index, self) =>
                            index ===
                            self.findIndex((v) => v.label === value.label)
                        );
                        field.onChange(uniqueValues);
                      };
                      return (
                        <Autocomplete
                          multiple
                          id="tags-outlined"
                          options={
                            FeedOwnerListData.length > 0
                              ? FeedOwnerListData
                              : []
                          }
                          getOptionLabel={(option) => option?.label}
                          filterSelectedOptions
                          disableCloseOnSelect
                          // value={field.value}
                          onChange={handleAutocompleteChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              {...field}
                              label="Feeds"
                              error={!!errors.sourceId}
                              helperText={errors.sourceId?.message}
                            />
                          )}
                        />
                      );
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  {...register("remark")}
                  label="Remark"
                  error={!!errors.remark}
                  helperText={errors.remark?.message}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>

        <Box
          position={"fixed"}
          bottom={8}
          sx={{
            backgroundColor: "#fefffe",
            width: "100%",
          }}
        >
          <Grid container spacing={2} sx={{ width: "50vw" }}>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                style={{ width: "100%" }}
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="outlined"
                style={{
                  color: "#ff831f",
                  width: "100%",
                  borderColor: "#ff831f",
                  opacity: res.isLoading ? 0.8 : 1,
                  pointerEvents: res.isLoading ? "none" : "auto",
                  cursor: res.isLoading ? "not-allowed" : "pointer",
                }}
                onClick={handleSubmit(onFormSubmit)}
              >
                {res.isLoading ? "Wait..." : "Add"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
};

AddExecutionList.propTypes = {
  setOpen: PropTypes.any,
  isOpen: PropTypes.any,
  defaultValues: PropTypes.any,
  name: PropTypes.any,
  loadData: PropTypes.any,
  isEditMode: PropTypes.any,
};

export default AddExecutionList;
