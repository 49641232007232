import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],

};

const mapHeaderTableData = createSlice({
  name: "mapHeaderTableData",
  initialState,
  reducers: {
    updateMapHeaderDataList: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { updateMapHeaderDataList } = mapHeaderTableData.actions;
export default mapHeaderTableData.reducer;
