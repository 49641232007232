import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Grid,
  Autocomplete,
  Card,
  FormControl,
  MenuItem,
  Select,
  FormHelperText,
  Box,
} from "@mui/material";
import AgGridComponent from "../../../../components/common/AgGridComponent";
import { styled } from "@mui/material/styles";
import CircularProgressLoading from "@components/loaders/CircularProgressLoading";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../services/apiQueryServices";
import { updateMapHeaderDataList } from "../../../../store/MapAndImportModule/DataTableSlice";
import { updateDataSourceIdData } from "../../../../store/MapAndImportModule/DataSourceIdSlice";
import { updateHeaderList } from "../../../../store/MapAndImportModule/HeaderDataSlice";

const reportsList = [
  { label: "Count Report", value: "countReport" },
  { label: "Email Domain", value: "emailDomain" },
  { label: "Group By Count", value: "groupByCount" },
];

const MapDataColumnAndImport = () => {
  const [columnDef, setColumnDef] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState("");
  const [dataSource, setDataSource] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [error, setError] = useState(false);
  const [headers, setHeaders] = useState({});
  const dispatch = useDispatch();
  //store datas
  const TableHeaderData = useSelector(
    (state) => state?.mapHeaderTableData?.data
  );
  const DataSourceId = useSelector((state) => state?.dataSourceIdData?.data);
  const HeaderNameData = useSelector((state) => state?.headerData?.data);

  //calling headerData Api
  const getTableHeaderData = useGetApiCallQuery({
    endPoint: "dl/map-and-import/table/list-items/0",
    method: "GET",
    headers: {},
  });
  useEffect(() => {
    getTableHeaderData.refetch();
    if (
      getTableHeaderData?.isSuccess &&
      getTableHeaderData.data?.data !== null
    ) {
      dispatch(
        updateMapHeaderDataList([...getTableHeaderData.data?.data].reverse())
      );
    }
  }, [getTableHeaderData.data, getTableHeaderData.isSuccess]);

  //calling dataSourceId Api
  const getDataSourceId = useGetApiCallQuery({
    endPoint: "dl/map-and-import/source/list-items",
    method: "POST",
    data: { data: { ownerId: "" } },
  });
  useEffect(() => {
    getDataSourceId.refetch();
    if (getDataSourceId?.isSuccess && getDataSourceId.data?.data !== null) {
      dispatch(updateDataSourceIdData([...getDataSourceId.data?.data]));
    }
  }, [getDataSourceId.data, getDataSourceId.isSuccess]);

  //calling HeaderDataList
  const getHeaderNameList = useGetApiCallQuery({
    endPoint: "dl/import/data-source/headers/list-items",
    method: "GET",
    headers: {},
  });
  useEffect(() => {
    getHeaderNameList.refetch();
    if (getHeaderNameList?.isSuccess && getHeaderNameList.data?.data !== null) {
      dispatch(updateHeaderList([...getHeaderNameList.data?.data]));
    }
  }, [getHeaderNameList.data, getHeaderNameList.isSuccess]);

  //calling gridData Api
  const [getGridApiCall, gridResponse] = useApiCallMutation();

  useEffect(() => {
    if (gridResponse.isSuccess && gridResponse?.data?.data) {
      setGridData(gridResponse?.data?.data);
    }
  }, [gridResponse.isSuccess]);

  //calling map Api
  const [mapApiCall, mapResponse] = useApiCallMutation();

  useEffect(() => {
    if (mapResponse?.isSuccess) {
      reSetPage();
      successCallback(mapResponse);
      getTableHeaderData?.refetch();
    }
  }, [mapResponse?.isSuccess]);

  const customDropdownRenderer = (props) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            marginTop: "6px",
            marginBottom: "6px",
            fontSize: "0.95rem",
          }}
        >
          {props?.column?.colId}
        </div>
        <div>
          {HeaderNameData.length > 0 && (
            <FormControl
              sx={{ maxWidth: "300px", width: "100%", marginBottom: "5px" }}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size="small"
                defaultValue={"select header"}
                // disablePortal
                value={headers[props?.column?.colId]}
                onChange={(e) => handleChange1(e, props?.column?.colId)}
                renderValue={(selected) => <div>{selected}</div>}
                // disabled={findKeysWithSameValues(headers)}
              >
                {HeaderNameData.map((res) => (
                  <MenuItem key={res?.value} value={res?.label}>
                    {res?.label}
                  </MenuItem>
                ))}
              </Select>

              {headers[props?.column?.colId] !== "select header" &&
                hasSameValueForKey(headers, headers[props?.column?.colId]) && (
                  <FormHelperText
                    sx={{
                      color: "red",
                    }}
                  >
                    Header Selected Already
                  </FormHelperText>
                )}
            </FormControl>
          )}
        </div>
      </div>
    );
  };
  function hasSameValueForKey(obj, valueToCheck) {
    const keysWithMatchingValue = Object.keys(obj).filter(
      (key) => obj[key] === valueToCheck
    );
    return keysWithMatchingValue.length > 1;
  }

  function hasDuplicateValue(obj) {
    const valuesSet = new Set();

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (valuesSet.has(value)) {
          return true;
        }
        valuesSet.add(value);
      }
    }
    return false;
  }
  const handleChange1 = (event, dM) => {
    if (Object.values(headers).includes(event.target.value)) {
      setError(true);
    } else {
      const abc = Object.values(headers);
      const xyz = new Set(abc);
      if (event.target.value !== "select header" && xyz.size <= 1) {
        setError(false);
      }
    }
    setSelectedValue(event.target.value);
    setDisplayName(dM);
  };

  useEffect(() => {
    let abc = {};
    if (gridData.length) {
      const keys = Object.keys(gridData[0]);
      if (Object.keys(headers).length === 0)
        keys?.map((item) => {
          abc[item] = "select header";
        });
      setHeaders(abc);
    }
  }, [gridData]);

  useEffect(() => {
    const xyz = { ...headers };
    if (displayName) {
      xyz[displayName] = selectedValue;
    }
    setHeaders(xyz);
  }, [displayName, selectedValue]);

  useEffect(() => {
    if (gridData.length) {
      const keys = Object.keys(gridData[0]);
      const column = keys
        ?.map((item, index) => {
          if (item === "filename" || item === "created" || item === "remark") {
            return null;
          }
          return {
            // headerName: item.toString(),
            field: item.toString(),
            // filter: true,
            headerComponent: customDropdownRenderer,
          };
        })
        .filter(Boolean);
      setColumnDef(column);
    }
  }, [gridData, headers, error]);

  //fetching table data
  const fetchDataDetails = async () => {
    getGridApiCall({
      endPoint: `dl/map-and-import/files-table/${dataTable?.value}/200`,
      method: "GET",
    });
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
    fetchDataDetails();
  };

  const successCallback = (response) => {
    const status = response?.data?.status;
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "success",
        message: status.value,
      })
    );
  };

  const errorCallback = (error) => {
    setIsLoading(false);
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "error",
        message: error.response.data.status.value,
      })
    );
  };

  const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.primary.main,
  }));

  const GroupItems = styled("ul")({
    padding: 0,
  });

  const mapHeadersApiCAll = () => {
    const data = {
      tableName: dataTable?.label,
      sourceId: String(dataSource),
      dataHeader: headers,
    };
    mapApiCall({
      endPoint: `dl/map-and-import/header-mapping`,
      method: "POST",
      data: { data },
    });
  };

  const reSetPage = () => {
    setColumnDef([]);
    setGridData([]);
    setIsLoading(false);
    // setdataSourceId([]);
    setDataSource("");
    setSelectedValue("");
    // setHeadersNameList([]);
    setDisplayName("");
    setHeaders({});
    setDataTable({});
  };

  return (
    <div className="ag-theme-alpine" style={{ height: "calc(100vh - 235px)" }}>
      <Box sx={{ padding: "20px" }}>
        <Card>
          <Box
            sx={{
              padding: "20px 30px",
              borderBottom: "1px solid #E0E0E0",
            }}
          >
            <Box
              component={"form"}
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={8}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Autocomplete
                    id="grouped-demo"
                    options={TableHeaderData}
                    groupBy={(option) => option?.tablePrefix}
                    getOptionLabel={(option) => option?.value || ""}
                    // isOptionEqualToValue={(option, value) => {
                    //   return option && value && option.value == value;
                    // }}
                    value={dataTable || {}}
                    onChange={(e, val) => setDataTable(val)}
                    sx={{ width: "60vw" }}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Select Data Table" />
                    )}
                    renderGroup={(e) => (
                      <li key={e.key}>
                        <GroupHeader>
                          {<Typography variant="body2">{e.group}</Typography>}
                        </GroupHeader>
                        <GroupItems>
                          {
                            <Typography variant="body2">
                              {e.children}
                            </Typography>
                          }
                        </GroupItems>
                      </li>
                    )}
                  />
                  <Button
                    variant="outlined"
                    type="submit"
                    sx={{
                      ml: 1,
                      padding: "6px 45px ",
                      whiteSpace: "nowrap",
                    }}
                    onClick={onFormSubmit}
                    disabled={dataTable?.value ? false : true}
                  >
                    Fetch Table Data
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Card>
        <Box>
          {!isLoading && columnDef.length ? (
            <Box
              sx={{
                pt: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <Box
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: "500",
                  }}
                >
                  <div
                    style={{
                      marginTop: "0.8rem",
                    }}
                  >
                    Map headers with system Data Columns
                  </div>
                </Box>
                <Box item xs={6} sx={{ textAlign: "right", display: "flex" }}>
                  <Autocomplete
                    id="grouped-demo"
                    options={DataSourceId}
                    groupBy={(option) => option.tablePrefix}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, val) => setDataSource(val?.value)}
                    sx={{ width: "20rem" }}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Select Data Source Id" />
                    )}
                  />
                  <Button
                    variant="outlined"
                    type="submit"
                    sx={{
                      ml: 1,
                      padding: "0px 45px ",
                      whiteSpace: "nowrap",
                    }}
                    onClick={mapHeadersApiCAll}
                    disabled={
                      !(
                        dataSource &&
                        Object.values(headers).filter(
                          (res) => res !== "select header"
                        ).length
                      )
                    }
                  >
                    Map Headers
                  </Button>
                </Box>
              </Box>

              <AgGridComponent
                rowData={gridData || []}
                columnDefs={columnDef || []}
                pagination={true}
                paginationPageSize={10}
                floatingFilter={false}
                onRowSelectionChangedHandler={(arg) => console.log(arg)}
                rowMode
              />
            </Box>
          ) : null}
          {(gridResponse?.isLoading || mapResponse?.isLoading) && (
            <CircularProgressLoading />
          )}
        </Box>
      </Box>
    </div>
  );
};
export default MapDataColumnAndImport;
