import React, { useState, useEffect } from "react";
import AgGridComponent from "../../../../components/common/AgGridComponent";
import CircularProgressLoading from "../../../../components/loaders/CircularProgressLoading";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import { useDispatch } from "react-redux";
import { Box, Button, Card, Grid } from "@mui/material";
import { axiosInstance } from "@services/axiosInstance";
import { baseURL } from "@configs/envConfig";
import DatePickerComponent from "../../../../components/common/GlobalDatePicker";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useApiCallMutation } from "../../../../services/apiQueryServices";

const ImportDataAnalysis = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [coloumSelect, setcoloumSelect] = useState([]);
  const [isDisplayGrid, setIsDisplayGrid] = useState(false);
  const [totalData, setTotalData] = useState({});

  const [apiCall, res] = useApiCallMutation();

  useEffect(() => {
    if (res.isSuccess) {
      if (Object.entries(res.data).length) {
        setTotalData(res.data.data.data);
        setcoloumSelect(res.data.data?.headers);
      } else if (res.data.length === 0) {
        setTotalData({});
        dispatch(
          snackbarMessageUpdate({
            open: true,
            color: "warning",
            message: "No Data Found",
          })
        );
      }
    } else {
      setIsDisplayGrid(true);
    }
  }, [res]);

  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      sourceOwner: "",
      fromDate: dayjs().subtract(1, "day"),
      toDate: dayjs(),
    },
    mode: "all",
  });

  const onFormSubmit = (formData) => {
    const data = {
      fromDate: dayjs(formData.fromDate.$d).format("YYYY-MM-DD"),
      toDate: dayjs(formData.toDate.$d).format("YYYY-MM-DD"),
    };
    apiCall({
      endPoint: `dl/import/data-analysis-report`,
      method: "POST",
      data: { data },
    });
  };

  if (res?.isLoading) return <CircularProgressLoading />;
  return (
    <div className="ag-theme-alpine" style={{ height: "calc(100vh - 235px)" }}>
      <Card>
        <Box
          sx={{
            padding: "20px 30px",
            borderBottom: "1px solid #E0E0E0",
          }}
        >
          <Box
            component={"form"}
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Grid container spacing={4} display={"flex"}>
              <Grid item xs={2}>
                <DatePickerComponent
                  name="fromDate"
                  label="From Date"
                  control={control}
                  minDate={dayjs("2000-01-01")}
                  maxDate={watch("toDate")}
                />
              </Grid>
              <Grid item xs={2}>
                <DatePickerComponent
                  name="toDate"
                  label="Date To"
                  control={control}
                  minDate={watch("fromDate")}
                  maxDate={dayjs()}
                />
              </Grid>
              <Grid item xs={8} display={"flex"} justifyContent={"end"}>
                <Button
                  variant="outlined"
                  type="submit"
                  onClick={handleSubmit(onFormSubmit)}
                  //
                  // disabled={watch("sourceOwner").length ? false : true}
                  sx={{
                    maxHeight: "2.5rem",
                    mr: "10px",
                  }}
                >
                  Generate Report
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
      <Box
        sx={{
          padding: "25px 0",
        }}
      >
        <AgGridComponent
          rowData={totalData}
          columnDefs={coloumSelect}
          onRowSelectionChangedHandler={(arg) => {
            setIsEditMode(true);
          }}
          pagination={true}
          paginationPageSize={10}
          rowMode
        />
        ;
      </Box>
    </div>
  );
};

export default ImportDataAnalysis;
