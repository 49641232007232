import React, { useRef, useState, useEffect } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";

 const AnalyticsReport = () => {
  const [value, setValue] = useState(0);  
    
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };  
  return (
  
    <>
    <Box sx={{ width: "100%", padding: "10px" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Weekly Month Wise Report" />
              <Tab label="User Wise Insights Posting Import & Destination" />
              <Tab label="Ml-Al Targeting Suggestion Based on Behaviour" />
            </Tabs>
          </Box>         
          <TabPanel value={value} index={0}>
       
          </TabPanel>
          <TabPanel value={value} index={1}>
           
          </TabPanel>
          <TabPanel value={value} index={1}>
           
          </TabPanel>
        </Box>
   </>
  )
}
export default AnalyticsReport