import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],

};

const reportDataStoreList = createSlice({
  name: "reportDataStoreList",
  initialState,
  reducers: {
    updateReportDataStoreList: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { updateReportDataStoreList } = reportDataStoreList.actions;
export default reportDataStoreList.reducer;
