import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import JwtService from "@services/jwtService";
import { setUser } from "@store/auth/userSlice";

import { SimpleBackdrop } from "@components/loaders";
import { baseURL, callBackURL } from "@configs/envConfig";

const CallbackHandlerPage = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const code = searchParams.get("code");

  const handlerCore = async () => {
    const data = {
      data: {
        redirectUri: callBackURL,
        clientName: "datalabs",
        code: code,
      },
    };
    await axios.post(`${baseURL}/core/get-token`, data).then((response) => {
      const rdata = response.data;
      if (rdata.status.code === 200) {
        dispatch(setUser(rdata.data));
        JwtService.setSession(rdata.data);
        return navigate("/home");
      }
    });
  };

  useEffect(() => {
    handlerCore();
  }, []);

  return (
    <>
      <SimpleBackdrop open={true} />
    </>
  );
};

export default CallbackHandlerPage;
