import React, { useCallback, useEffect, useState } from "react";
import AgGridComponent from "@components/common/AgGridComponent";
import { baseURL } from "@configs/envConfig";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CircularProgressLoading from "../../../../components/loaders/CircularProgressLoading";
import { axiosInstance } from "@services/axiosInstance";
import Icon from "@mdi/react";
import { mdiFile, mdiListBoxOutline } from "@mdi/js";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../services/apiQueryServices";
import { updateChannelListTableData } from "../../../../store/ExportDataModule/ChannelListSlice";
import { updateDataCountDetailData } from "../../../../store/ExportDataModule/ChannelListSlice/DataCountDetailSlice";

const ChannelList = () => {
  const [rowSelected, setRowSelected] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();
  const TableData = useSelector((state) => state.channelList?.tableData);

  const DialogData = useSelector((state) => state.dataCountDetail?.tableData);

  const { data, refetch, isError, isLoading, isFetching, isSuccess } =
    useGetApiCallQuery({
      endPoint: "dl/export/count-report",
      method: "GET",
      headers: {},
    });
  const [apiCall, res] = useApiCallMutation();

  useEffect(() => {
    if (res.isSuccess && res.data !== null) {
      const keys = Object.keys(res?.data?.data[0]);
      const column = keys?.map((item, index) => {
        return { field: item, filter: true };
      });
      const tabledata = {
        data: [...res?.data?.data],
        headers: column,
      };
      dispatch(updateDataCountDetailData(tabledata));
    }
  }, [res]);

  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    dispatchData();
  }, [data, isSuccess]);

  const dispatchData = useCallback(() => {
    if (isSuccess && data?.data !== null) {
      const keys = Object.keys(data.data[0]);
      const column = keys?.map((item, index) => {
        return { field: item, filter: true };
      });
      column.splice(1, 0, {
        field: "Data count details",
        cellRenderer: actionButtonsPdf,
      });
      const tableData = {
        data: [...data.data].reverse(),
        headers: column ? column : [],
      };
      dispatch(updateChannelListTableData(tableData));
    }
  }, [isSuccess, data]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const actionButtonsPdf = (params) => {
    return (
      <div style={{ marginTop: "0.3rem" }}>
        <Icon
          path={mdiListBoxOutline}
          title="Data count details"
          onClick={() => openPdfFile(params.data)}
          size={1}
          style={{
            color: "#1976d2",
            cursor: "pointer",
          }}
        />
      </div>
    );
  };

  const openPdfFile = async (items) => {
    const data = {
      exportSourceId: items?.exportSourceId,
      sentDate: items?.sentDate,
    };
    apiCall({
      endPoint: `dl/export/import-source/count-report`,
      method: "POST",
      data: { data },
    });
    setOpen(true);
  };

  const successCallback = (response) => {
    const status = response.data.status;

    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "success",
        message: status.value,
      })
    );
  };

  const errorCallback = (error) => {
    // setIsLoading(false);
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: error.response.data.status.severity,
        message: error.response.data.status.value,
      })
    );
  };

  if (isLoading) return <CircularProgressLoading />;

  return (
    <>
      <Box
        sx={{
          padding: "20px",
        }}
      >
        <AgGridComponent
          rowData={TableData?.data || []}
          columnDefs={TableData?.headers || []}
          onRowSelectionChangedHandler={(arg) => {
            setIsEditMode(true);
            setRowSelected(arg);
          }}
          pagination={true}
          paginationPageSize={10}
          rowMode
        />
      </Box>

      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          maxWidth={"md"}
          fullWidth={true}
        >
          <DialogTitle id="responsive-dialog-title">
            Data count details
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {DialogData?.data?.length && DialogData?.data?.length && (
                <AgGridComponent
                  rowData={DialogData?.data || []}
                  columnDefs={DialogData?.headers || []}
                  onRowSelectionChangedHandler={(arg) => console.log(arg)}
                  pagination={false}
                  paginationPageSize={10}
                  rowMode
                />
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Cancel
            </Button>
            {/* <Button onClick={handleClose} autoFocus>
              Agree
            </Button> */}
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default ChannelList;
