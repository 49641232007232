import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import Slide from "@mui/material/Slide";

import { snackbarMessageUpdate } from "@store/config/configSlice";
import { useDispatch, useSelector } from "react-redux";
import GlobalDropdown from "@components/common/GlobalDropedown";
import AgGridComponent from "../../components/common/AgGridComponent";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../services/apiQueryServices";
import { updateMapHeadersInTableRow } from "../../store/ExportDataModule/DestinationSourceSlice";
import { addSystemFieldList } from "../../store/ExportDataModule/DestinationSourceSlice/AddEditMapHeadersSlice";
import CircularProgressLoading from "../../components/loaders/CircularProgressLoading";
const dataSourceCategoryType = [
  { label: "Header", value: "header" },
  { label: "Parameter", value: "params" },
  { label: "JSON", value: "json" },
  { label: "XML", value: "xml" },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddDestSourceMappedHeaders = (props) => {
  const { setOpen, isOpen, defaultValues, name, loadData, isEditMode } = props;
  const [defaultValuesSchema, setDefaultValuesSchema] = useState({});
  const [mapHeadersColumnData, setMapHeadersColumnData] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [mapHeadersRowData, setMapHeadersRowData] = useState([]);
  const [dialogHeaderData, setdialogHeaderData] = useState([]);
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const SystemFieldList = useSelector((state) => state?.systemFieldList?.data);
  const systemFieldListQuery = useGetApiCallQuery({
    endPoint: "dl/import/data-source/headers/list-items",
    method: "GET",
  });
  useEffect(() => {
    if (systemFieldListQuery.isSuccess) {
      systemFieldListQuery.refetch();
      dispatch(
        addSystemFieldList([
          { label: "static_value", value: "static_value" },
          ...systemFieldListQuery?.data?.data,
        ])
      );
    }
    if (defaultValues) {
      setDefaultValuesSchema(defaultValues);
    }
  }, [systemFieldListQuery.data]);

  const [createCall, createdData] = useApiCallMutation();
  const [findOneCall, findOneData] = useApiCallMutation();
  const [updateCall, updatedData] = useApiCallMutation();
  const [testApi, testApiData] = useApiCallMutation();

  useEffect(() => {
    if (createdData.isSuccess && createdData?.data?.data !== null) {
      dispatch(
        updateMapHeadersInTableRow({
          sourceId: createdData?.data?.data?.sourceId,
          mappedHeader: true,
        })
      );
      successCallback(createdData?.data);
    }
    if (updatedData.isSuccess) {
      successCallback(updatedData?.data);
    }
    if (findOneData.isSuccess) {
      handleUpdateMappedValues(findOneData?.data?.data);
    }
  }, [createdData, updatedData, findOneData]);

  useEffect(() => {
    if (defaultValues?.mappedHeader) {
      const data = {
        sourceId: defaultValues?.sourceId,
      };
      findOneCall({
        endPoint: `dl/export/header-mapping/find-one`,
        method: "POST",
        data: { data },
      });
    }
  }, []);

  const handleUpdateMappedValues = (data) => {
    const updatedData = [];
    if (data?.length) {
      data.map((item, index) => {
        updatedData.push({
          srNo: index + 1,
          apiHeader: item["apiHeader"],
          systemHeader: item["systemHeader"],
          fieldType: item["fieldType"],
          defaultValue: item["defaultValue"],
        });
      });
    }
    setMapHeadersRowData(updatedData);
  };

  const centerAlignedHeaderComponent = (props) => {
    return <div style={{ textAlign: "center" }}>{props?.displayName}</div>;
  };

  const actionButtons = (params) => {
    return (
      <span>
        <Icon
          path={mdiClose}
          title="Remove"
          onClick={() => removeAddedMapHeaders(params)}
          size={1}
          style={{
            color: "#b03f28",
            cursor: "pointer",
            marginTop: "8px",
          }}
        />
      </span>
    );
  };

  const removeAddedMapHeaders = (params) => {
    setMapHeadersRowData((prevState) =>
      prevState.filter((item) => item.srNo !== params.data.srNo)
    );
  };

  const validationSchema = Yup.object().shape({
    apiHeader: Yup.string()
      .matches(/^\S*$/, "Blank spaces are not allowed")
      .required("This Field is required"),
    systemHeader: Yup.string().required("This Field is required"),
    fieldType: Yup.string().required("This Field is required"),
    // defaultValue: Yup.string().required("This Field is required"),
  });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      apiHeader: "",
      systemHeader: "",
      fieldType: "",
      defaultValue: "",
    },
    resolver: yupResolver(validationSchema),
    mode: "all",
  });
  const headers = {
    "Content-Type": "application/json", // Example header
    Authorization: `Bearer ${
      JSON.parse(localStorage.getItem("user"))?.token.access_token
    }`, // Example header
  };

  const successCallback = (response) => {
    const status = response?.status;
    setOpen(false);
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "success",
        message: status.value,
      })
    );
  };

  //***********FORM SUBMISSION ********//

  const onFormSubmit = (arg) => {
    arg.srNo = mapHeadersRowData.length + 1;
    if (mapHeadersRowData.some((val) => val.apiHeader === arg.apiHeader)) {
      dispatch(
        snackbarMessageUpdate({
          open: true,
          color: "error",
          message: "This destination field is already used",
        })
      );
    } else {
      setMapHeadersRowData((prevState) => [...prevState, arg]);
      reset();
    }
  };
  useEffect(() => {
    setMapHeadersColumnData([
      {
        field: "srNo",
      },
      {
        field: "apiHeader",
        headerName: "Destination Header",
      },
      {
        field: "systemHeader",
      },
      {
        field: "fieldType",
      },
      {
        field: "defaultValue",
      },
      {
        field: "Action",
        cellRenderer: actionButtons,
        headerComponentFramework: centerAlignedHeaderComponent,
        cellStyle: { textAlign: "center" },
      },
    ]);
  }, []);

  const submitFormData = () => {
    if (defaultValuesSchema?.mappedHeader) {
      const data = {
        sourceId: defaultValuesSchema?.sourceId,
        headers: mapHeadersRowData,
        remark: "test",
      };
      updateCall({
        endPoint: `dl/export/header-mapping/update/${defaultValuesSchema?.sourceId}`,
        method: "PUT",
        data: { data },
        headers: { headers },
      });
    } else {
      const data = {
        id: defaultValuesSchema?.id,
        sourceId: defaultValuesSchema?.sourceId,
        headers: mapHeadersRowData,
        remark: "test",
      };
      createCall({
        endPoint: `dl/export/header-mapping/create`,
        method: "POST",
        data: { data },
        headers: { headers },
      });
    }
  };

  //*********** Test API ********//

  const handleTestAPI = () => {
    const data = {
      sourceId: String(defaultValuesSchema?.sourceId),
    };
    testApi({
      endPoint: `dl/export/test-api`,
      method: "POST",
      data: { data },
    });
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };
  useEffect(() => {
    if (testApiData.isSuccess && testApiData?.data?.data !== null) {
      setdialogHeaderData(testApiData?.data?.data?.data);
    } else {
      setdialogHeaderData([]);
    }
  }, [testApiData]);

  return (
    <>
      <Drawer anchor={"right"} open={isOpen} onClose={() => setOpen(false)}>
        <Box
          sx={{
            width: "80vw",
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Box marginBottom={"15px"}>
            <Typography component="h5" variant="h5" fontWeight={600}>
              {(defaultValuesSchema?.mappedHeader && "Update Map header") ||
                "Map header"}
            </Typography>
          </Box>
          <Divider
            sx={{
              mb: "20px",
            }}
          />

          <Box component={"form"}>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <TextField
                  required
                  size="small"
                  fullWidth
                  {...register("apiHeader")}
                  id="outlined-required"
                  label="Destination Field"
                  error={!!errors.apiHeader}
                  helperText={errors.apiHeader?.message}
                />
              </Grid>
              <Grid item xs={4}>
                <GlobalDropdown
                  options={SystemFieldList}
                  size="small"
                  control={control}
                  name="systemHeader"
                  label="System Field"
                  error={!!errors.systemHeader}
                  helperText={errors.systemHeader?.message}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <GlobalDropdown
                  options={dataSourceCategoryType}
                  size="small"
                  control={control}
                  name="fieldType"
                  label="Type"
                  error={!!errors.fieldType}
                  helperText={errors.fieldType?.message}
                  required
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth
                  {...register("defaultValue")}
                  id="outlined-required"
                  label="Default Value"
                  error={!!errors.defaultValue}
                  helperText={errors.defaultValue?.message}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  style={{
                    color: "#fefefe",
                    width: "100%",
                    borderColor: "#ff831f",
                  }}
                  onClick={handleSubmit(onFormSubmit)}
                >
                  Add Headers
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              pt: "20px",
            }}
          >
            <AgGridComponent
              rowData={mapHeadersRowData}
              columnDefs={mapHeadersColumnData}
              onRowSelectionChangedHandler={(arg) => {
                console.log(arg);
              }}
              pagination={true}
              paginationPageSize={10}
              height={"60vh"}
              // rowMode
            />
          </Box>
          {/* )} */}

          <Box
            position={"fixed"}
            bottom={8}
            sx={{
              backgroundColor: "#fefffe",
              width: "100%",
              marginTop: "5%",
            }}
          >
            <Grid container spacing={2} sx={{ width: "79vw" }}>
              <Grid item xs={4}>
                <Button
                  variant="outlined"
                  style={{ width: "100%" }}
                  disabled={!mapHeadersRowData.length}
                  onClick={() => {
                    handleTestAPI();
                    setIsDialogOpen(true);
                  }}
                >
                  Test API
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fieldType="submit"
                  variant="outlined"
                  style={{
                    color: mapHeadersRowData.length ? "#ff831f" : "#919eabcc",
                    width: "100%",
                    borderColor: "#ff831f",
                    opacity:
                      createdData.isLoading || updatedData.isLoading ? 0.8 : 1,
                    pointerEvents:
                      createdData.isLoading || updatedData.isLoading
                        ? "none"
                        : "auto",
                    cursor:
                      createdData.isLoading || updatedData.isLoading
                        ? "not-allowed"
                        : "pointer",
                  }}
                  onClick={submitFormData}
                  disabled={!mapHeadersRowData.length}
                >
                  {createdData.isLoading || updatedData.isLoading
                    ? "Wait..."
                    : defaultValuesSchema?.mappedHeader
                    ? "Update"
                    : "Add "}
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="outlined"
                  style={{ width: "100%" }}
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
      <Dialog
        fullScreen={fullScreen}
        open={isDialogOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="responsive-dialog-title"
        // maxWidth={"xd"}
        fullWidth={true}
      >
        <DialogTitle id="responsive-dialog-title">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>API Response</div>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {testApiData?.isLoading && <CircularProgressLoading />}
            {!testApiData?.isLoading && JSON.stringify(testApiData?.data)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddDestSourceMappedHeaders;
