import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import PageHeader from "@components/ui/pageHeader/PageHeader";
import {
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  styled,
  Switch,
} from "@mui/material";
import SecondDashboard from "../Dashboard2";
import { axiosInstance } from "@services/axiosInstance";
import { baseURL } from "@configs/envConfig";

import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../services/apiQueryServices";

const Dashboard = () => {
  const [isPercentage, setIsPercentage] = useState(false);
  const [mode, setMode] = useState("");
  const [importCountReport, setImportCountReport] = useState({});
  const [exportCountReport, setExportCountReport] = useState({});
  const [emailVerifyReport, setEmailVerifyReport] = useState({});

  const [apiCallMutation] = useApiCallMutation();

  const pageHeaderContent = {
    title: "Dashboard",
    description: "Business Insights at a Glance",
    navButtons: [],
  };

  // const newData = useGetApiCallQuery({
  //   endPoint: "/dl/dashboard/import-count-report",
  //   method: "GET",
  //   header: {},
  // });

  // // if (newData) {
  // //   setImportCountReport(newData);
  // // } else {
  // //   setImportCountReport({});
  // // }

  // useEffect(() => {
  //   debugger
  //   newData.refetch();
  //   getExportCountReport();
  //   getEmailVerificationReport();
  // }, []);

  // useEffect(() => {
  //   if (newData?.data && newData.isSuccess) {
  //     // debugger
  //     setImportCountReport(newData?.data);
  //   } else {
  //     debugger
  //     setImportCountReport({});
  //   }
  // },[newData]);

  // const { data, error, refetch } = useGetApiCallQuery({ 
  //   endPoint: '/dl/dashboard/import-count-report',
  //   method: "GET",
  // });

  // useEffect(() => {
  //   refetch();
  // }, [refetch]);

  useEffect(() => {
    
    getImportCountReport()
    getExportCountReport();
    getEmailVerificationReport();
  }, []);

  // useEffect(() => {
  //   debugger
  //   if (data) {
  //     setImportCountReport(data);
  //   } else if (error) {
  //     // Handle error
  //     console.error("Error fetching import count report:", error);
  //   }
  // }, [data, error]);

  const getImportCountReport = () => {

  //   debugger  
  //   if ( data) {
  //     setImportCountReport(data.data);
  //   } else {  
  //     setImportCountReport({});
  //   }
  // };

  // const getImportCountReport = () => {
  //   debugger;

  //   // if (!error && newData) {
  //   //   setImportCountReport(newData);
  //   // } else {
  //   //   setImportCountReport({});
  //   // }

  //   // try {
  //   //   const res = apiCallMutation({
  //   //     endPoint: "/dl/dashboard/import-count-report",
  //   //     method: "GET",
  //   //   })
  //   //   if (res) {
  //   //     setImportCountReport(res.data.data);
  //   //   }

  //   // } catch (error) {
  //   //   setImportCountReport({});

  //   // }

    axiosInstance
      .get(`${baseURL}/dl/dashboard/import-count-report`)
      .then((res) => {
        if (res) {
          setImportCountReport(res.data.data);
        }
      })
      .catch((error) => {
        setImportCountReport({});
      });
  };

  const getExportCountReport = () => {
    axiosInstance
      .get(`${baseURL}/dl/dashboard/export-count-report`)
      .then((res) => {
        if (res) {
          setExportCountReport(res.data.data);
        }
      })
      .catch((error) => {
        setExportCountReport({});
      });

    // try {
    //   const res = apiCallMutation({
    //     endPoint: "/dl/dashboard/export-count-report",
    //     method: "GET",
    //   })
    //   if (res) {
    //     setExportCountReport(res.data.data);
    //   }

    // } catch (error) {
    //   setExportCountReport({});

    // }
  };

  const getEmailVerificationReport = () => {
    axiosInstance
      .get(`${baseURL}/dl/dashboard/email-verified/count-report`)
      .then((res) => {
        if (res) {
          setEmailVerifyReport(res.data.data);
        }
      })
      .catch((error) => {
        setEmailVerifyReport({});
      });
  };

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            "#fff"
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
      width: 32,
      height: 32,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));

  const handlePersantageValues = (isPercent) => {
    if (isPercent) {
      setMode("");
    } else {
      setMode("dark");
    }
    setIsPercentage(!isPercent);
  };

  return (
    <>
      <div>
        <PageHeader
          title={pageHeaderContent.title}
          description={pageHeaderContent?.description}
          navButtons={pageHeaderContent?.navButtons}
        ></PageHeader>

        <Box
          sx={{
            width: "fit-content",
            position: "absolute",
            right: "0%",
            top: "8% !important",
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <MaterialUISwitch
                  sx={{ m: 1 }}
                  checked={isPercentage}
                  onChange={() => handlePersantageValues(isPercentage)}
                />
              }
            />
          </FormGroup>
        </Box>

        <div>
          <Box
            pt={2}
            sx={{
              width: "100%  ",
              height: "28vh",
            }}
          >
            {/* <iframe
                width={"100%"}
                height={"100%"}
                scrolling="no"
                frameBorder="0"
                src="https://reports.whatthestats.com/baec3e5e-5457-4d80-81c2-b0e0df129b74/4663cbd8-f95a-435a-9d57-daaf04d1d0f7/all/0/0"
              ></iframe> */}
            <SecondDashboard
              data={importCountReport}
              mode={mode}
              title={"Import Trend (Past 15 Days)"}
            />
          </Box>
          <Divider
            sx={{
              margin: "8px",
              borderColor: "#0000003d",
              borderBottomWidth: "4px",
            }}
          />
          <Box
            sx={{
              width: "100%  ",
              height: "28vh",
            }}
          >
            <SecondDashboard
              data={exportCountReport}
              mode={mode}
              title={"Export Trend (Past 15 Days)"}
            />
          </Box>
          <Divider
            sx={{
              margin: "8px",
              borderColor: "#0000003d",
              borderBottomWidth: "4px",
            }}
          />
          <Box
            sx={{
              width: "100%  ",
              height: "28vh",
            }}
          >
            <SecondDashboard
              data={emailVerifyReport}
              mode={mode}
              title={"API Validations & Enhancements Trend (Past 15 Days)"}
            />
          </Box>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Dashboard;
