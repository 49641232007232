import React, { useEffect, useState } from "react";
import { Button, Grid, Card, Box } from "@mui/material";
import { mdiPencil, mdiCog } from "@mdi/js";
import Icon from "@mdi/react";
import AgGridComponent from "../../../../components/common/AgGridComponent";
import { Tdata1 } from "@store/static-data/TableData";
import { baseURL } from "@configs/envConfig";
import { useForm } from "react-hook-form";
import GlobalDropdown from "@components/common/GlobalDropedown";
import CircularProgressLoading from "@components/loaders/CircularProgressLoading";
import { axiosInstance } from "@services/axiosInstance";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../services/apiQueryServices";
import { updateImportPreTextList } from "../../../../store/FileSourceModule/ImportedFilesModule/ImportPreTextSlice";

const ImportedFiles = () => {
  const [columnDef, setColumnDef] = useState([]);
  const [gridData, setGridData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [data, setdata] = useState([]);
  const dispatch = useDispatch();

  //Fetching Data from Store
  const ImportPreTextData = useSelector(
    (state) => state.importPreTextList.data
  );

  //calling gridData api
  const importPreTextDataApi = useGetApiCallQuery({
    endPoint: "dl/file-source/pre-text/list-items",
    method: "GET",
    headers: {},
  });

  useEffect(() => {
    importPreTextDataApi.refetch();
    if (
      importPreTextDataApi?.isSuccess &&
      importPreTextDataApi?.data?.data !== null
    ) {
      dispatch(updateImportPreTextList([...importPreTextDataApi?.data?.data]));
    }
  }, [importPreTextDataApi?.data, importPreTextDataApi?.isSuccess]);

  // useEffect(() => {
  //   fetchData();
  // }, [null]);
  // const fetchData = async () => {
  //   await axiosInstance
  //     .get(`${baseURL}/dl/file-source/pre-text/list-items`)
  //     .then((result) => {
  //       if (result.data.data) {
  //         setdata(result.data.data);
  //       } else {
  //         setdata([]);
  //       }
  //     });
  // };

  //api call for import files pretext details
  const [importFilePreTextDetailsApi, res] = useApiCallMutation();
  useEffect(() => {
    if (res.isSuccess && res?.data?.data?.data !== null) {
      setGridData(res.data.data.data);

      setColumnDef(res.data.data.headers);
    }
  }, [res]);

  const fetchDataDetails = async (formData) => {
    setIsLoading(true);
    const data = {
      preText: formData.preText,
    };
    importFilePreTextDetailsApi({
      endPoint: `dl/file-source/import-file/pre-text/details`,
      method: "POST",
      data: { data },
    });
    // await axiosInstance
    //   .post(`${baseURL}/dl/file-source/import-file/pre-text/details`, { data })
    //   .then((result) => {
    //     if (result.data?.data?.data?.length) {
    //       setGridData(result.data.data.data);

    //       setColumnDef(result.data.data.headers);
    //     }
    //     setIsLoading(false);
    //   })
    // .catch(errorCallback);
  };

  const errorCallback = (error) => {
    setIsLoading(false);
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "error",
        message: error.response.data.status.value,
      })
    );
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      preText: "",
    },
    mode: "all",
  });

  if (res?.isLoading) return <CircularProgressLoading />;

  const actionButtons = () => {
    return (
      <span>
        <>
          <Icon path={mdiPencil} onClick={() => alert(`uuuuuu`)} size={1} />
          <Icon path={mdiCog} size={1} onClick={() => alert(`ahdkjashdkj`)} />
        </>
      </span>
    );
  };
  const keys = Object.keys(Tdata1.data[0]);
  const coloum = keys.map((item, index) => {
    return { field: item, filter: true };
  });
  coloum.push({ field: "Action", cellRenderer: actionButtons });

  const onFormSubmit = (data) => {
    fetchDataDetails(data);
  };
  if (importPreTextDataApi?.isLoading) return <CircularProgressLoading />;
  return (
    <div className="ag-theme-alpine" style={{ height: "calc(100vh - 235px)" }}>
      <Box sx={{ padding: "20px" }}>
        <Card>
          <Box
            sx={{
              padding: "20px 30px",
              borderBottom: "1px solid #E0E0E0",
            }}
          >
            <Box
              component={"form"}
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sx={{                    
                    alignItems: "center",
                  }}
                >
                  <GlobalDropdown
                    options={ImportPreTextData || []}
                    control={control}
                    name="preText"
                    label="Select Pre-text"
                    error={!!errors.preText}
                    helperText={errors.preText?.message}
                    required
                    size="small"
                  />
                </Grid>
                <Box
                  sx={{
                    float: "right",
                  }}
                >
                  <Button
                    variant="outlined"
                    type="submit"
                    sx={{
                      ml: 1,
                      padding: "6px 25px ",
                      whiteSpace: "nowrap",
                    }}
                    onClick={handleSubmit(onFormSubmit)}
                    disabled={watch("preText") ? false : true}
                  >
                    Get File List
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Box>
        </Card>
        <Box
          sx={{
            pt: 3,
          }}
        >
          {columnDef.length > 0 && (
            <AgGridComponent
              rowData={gridData}
              columnDefs={columnDef}
              pagination={true}
              paginationPageSize={10}
              onRowSelectionChangedHandler={(arg) => console.log(arg)}
              rowMode
            />
          )}
        </Box>
      </Box>
    </div>
  );
};
export default ImportedFiles;
