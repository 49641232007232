import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],

};

const preTextIdList = createSlice({
  name: "preTextIdList",
  initialState,
  reducers: {
    updatePreTextIdList: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { updatePreTextIdList } = preTextIdList.actions;
export default preTextIdList.reducer;
