import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { ApiProvider } from "@reduxjs/toolkit/query/react";
import { apiQueryServices } from "@services/apiQueryServices";
import { CookiesProvider } from "react-cookie";
import { ErrorBoundry } from "./features";
import ThemeProvider from "./theme";
import Router from "./routes";
import { SimpleBackdrop } from "./components/loaders";
import ScrollToTop from "./components/features/scroll-to-top";
import store from "./store";
import { SnackbarCom } from "@components/ui";
import { baseURL } from "@configs/envConfig";
import CircularProgressLoading from "@components/loaders/CircularProgressLoading";

function App() {
  return (
    <Provider store={store}>
      {/* <ApiProvider api={apiQueryServices}> */}
      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider>
            <ScrollToTop />
            <ErrorBoundry>
              <CookiesProvider>
                {/* <AuthMiddleware> */}
                {/* <Suspense fallback={<SimpleBackdrop open={true} />}> */}
                {/* <Suspense fallback={<CircularProgressLoading />}> */}
                <section>
                  <SnackbarCom />
                  <Router />
                </section>
                {/* </Suspense> */}
                {/* </AuthMiddleware> */}
              </CookiesProvider>
            </ErrorBoundry>
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
      {/* </ApiProvider> */}
    </Provider>
  );
}

export default App;
