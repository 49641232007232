import React from "react";
import { mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import { mdiCog } from "@mdi/js";
import AgGridComponent from "../../../../components/common/AgGridComponent";
import { Tdata1 } from "@store/static-data/TableData";
import { Box } from "@mui/material";

const SourceWiseCount = () => {
  return (
    <Box
      pt={2}
      sx={{
        width: "100%  ",
        height: "100vh",
      }}
    >
      <iframe
        width={"100%"}
        height={"100%"}
        scrolling="no"
        frameBorder="0"
        src="https://reports.whatthestats.com/baec3e5e-5457-4d80-81c2-b0e0df129b74/1e93b7c9-b450-4b5d-a485-b54e85c70bd3/all/0/0"
      ></iframe>
    </Box>
  );
};

export default SourceWiseCount;
