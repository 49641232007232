import React, { useState, useEffect, useCallback } from "react";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import { useDispatch } from "react-redux";
import { Box, Button, Card } from "@mui/material";
import { axiosInstance } from "@services/axiosInstance";
import { baseURL } from "@configs/envConfig";
import AgGridComponent from "../../../../../../components/common/AgGridComponent";
import CircularProgressLoading from "@components/loaders/CircularProgressLoading";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useSelector } from "react-redux";
import { useGetApiCallQuery } from "../../../../../../services/apiQueryServices";
import { addScriptrunningReportList } from "../../../../../../store/ExportDataModule/ReportsSlice/ScriptRunnigReportSlice";

const ScriptRunningReport = () => {
  const [rowSelected, setRowSelected] = useState({});
  const [columnDef, setColumnDef] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [coloum, setColoum] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useDispatch();

  const TableData = useSelector(
    (state) => state.ScriptRunningReport?.tableData
  );

  const { data, refetch, isError, isFetching, isLoading, isSuccess } =
    useGetApiCallQuery({
      endPoint: "dl/export/script-running-time/report",
      method: "GET",
    });
  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (isSuccess && data?.data?.data != null) {
      const tableData = {
        data: [...data?.data?.data].reverse(),
        headers: [...data?.data?.headers],
      };
      dispatch(addScriptrunningReportList(tableData));
    }
  }, [isSuccess, data, isFetching, isLoading]);

  if (isLoading) return <CircularProgressLoading />;

  return (
    <Box
      sx={{
        padding: "20px",
      }}
    >
      <Card
        sx={{
          mb: "10px",
        }}
      >
        <Box
          container
          sx={{
            padding: "20px 30px",
            borderBottom: "1px solid #E0E0E0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box item xs={4}>
            <div style={{ fontSize: "1.2rem" }}>
              Total scripts running : <b>{gridData.length}</b>
            </div>
          </Box>
          <Box
            item
            xs={8}
            sx={{
              float: "left",
            }}
          >
            <Button
              startIcon={<RefreshIcon />}
              variant="contained"
              type="submit"
              onClick={() => refetch()}
              disabled={isFetching}
              sx={{
                maxHeight: "2.5rem",
                mr: "10px",
              }}
            >
              Refresh
            </Button>
          </Box>
        </Box>
      </Card>
      <Box>{isFetching && <CircularProgressLoading />}</Box>
      {!isFetching && (
        <AgGridComponent
          rowData={TableData?.data || []}
          columnDefs={TableData?.headers || []}
          onRowSelectionChangedHandler={(arg) => {
            setIsEditMode(true);
          }}
          pagination={true}
          paginationPageSize={10}
          rowMode
        />
      )}
    </Box>
  );
};

export default ScriptRunningReport;
