import React, { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import DatePickerComponent from "../../../../components/common/GlobalDatePicker";
import AgGridComponent from "../../../../components/common/AgGridComponent";
import CircularProgressLoading from "@components/loaders/CircularProgressLoading";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../services/apiQueryServices";

const DomainEspExportReportTrend = () => {
  const [espData, setEspData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedChannelOptions, setSelectedChannelOptions] = useState([]);
  const [chanelData, setChanelData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [coloumSelect, setcoloumSelect] = useState([]);

  const [ChannelDetail, ChannelDetailOption] = useApiCallMutation();
  const [apiCall, res] = useApiCallMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      fromDate: dayjs().subtract(1, "day"),
      toDate: dayjs(),
      owner_id: [],
      channelId: [],
    },
    mode: "all",
  });

  const getEspApi = useGetApiCallQuery({
    endPoint: "dl/export/source-owner/list-items",
    method: "GET",
    header: {},
  });

  useEffect(() => {
    getEspApi.refetch();
    if (getEspApi.isSuccess && getEspApi.data?.data !== null) {
      setEspData(getEspApi.data?.data);
    }
  }, [getEspApi?.data, getEspApi?.isSuccess]);

  const ChannelDetailData = watch("esp_name");

  useEffect(() => {
    const data = {
      ownerId: selectedOptions.map((item) => `${item.value}`),
    };
    if (selectedOptions && selectedOptions?.length) {
      ChannelDetail({
        endPoint: `dl/export/data-source/channel-detail/list-items`,
        method: "POST",
        data: { data },
      });
    } else {
      setChanelData([]);
      setSelectedChannelOptions([]);
    }
  }, [ChannelDetailData]);

  useEffect(() => {
    if (ChannelDetailData && ChannelDetailData.length) {
      setChanelData(ChannelDetailOption?.data?.data);
    }
  }, [ChannelDetailOption]);

  const onFormSubmit = (formData) => {
    const data = {
      fromDate: dayjs(formData.fromDate.$d).format("YYYY-MM-DD"),
      toDate: dayjs(formData.toDate.$d).format("YYYY-MM-DD"),
      ownerId: selectedOptions.map((item) => `${item.value}`),
      channelId: selectedChannelOptions.map((item) => `${item.value}`),
    };
    apiCall({
      endPoint: `dl/export/esp-domain-wise/count-report`,
      method: "POST",
      data: { data },
    });
  };

  useEffect(() => {
    if (res.isSuccess) {
      if (res.data?.error) {
        setTotalData([]);
      } else {
        setTotalData(res?.data?.data?.data);
        console.log(res?.data?.data?.data);
        setcoloumSelect(res.data.data?.headers);
      }
    } else {
      setTotalData([]);
      console.log(res);
    }
  }, [res]);
  const pinnedBottomRowData = useMemo(() => {
    if (!totalData || totalData.length === 0) {
      return [];
    }

    const initialAccumulator = {
      datasourceid: "Total",
      totalCount: 0,
      uniqueCount: 0,
      gmail: 0,
      yahoo: 0,
      hotmail: 0,
      aol:0,
      icloud: 0,
      comcast: 0,
      others: 0,
    };

    const result = totalData?.reduce((accumulator, row) => {
      accumulator.totalCount += Number(row.totalCount) || 0;
      accumulator.uniqueCount += Number(row.uniqueCount) || 0;
      accumulator.gmail += Number(row.gmail) || 0;
      accumulator.yahoo += Number(row.yahoo) || 0;
      accumulator.hotmail += Number(row.hotmail) || 0;
      accumulator.aol += Number(row.aol) || 0;
      accumulator.icloud += Number(row.icloud) || 0;
      accumulator.comcast += Number(row.comcast) || 0;
      accumulator.others += Number(row.others) || 0;
      return accumulator;
    }, initialAccumulator);

    return [result];
  }, [totalData]);


  return (
    <div className="ag-theme-alpine" style={{ height: "calc(100vh - 235px)" }}>
      <Card>
        <Box sx={{ padding: "20px 30px", borderBottom: "1px solid #E0E0E0" }}>
          <Box
            component={"form"}
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Grid container spacing={4} display={"flex"}>
              <Grid item xs={2}>
                <DatePickerComponent
                  name="fromDate"
                  label="From Date"
                  control={control}
                  minDate={dayjs("2000-01-01")}
                  maxDate={watch("toDate")}
                />
              </Grid>
              <Grid item xs={2}>
                <DatePickerComponent
                  name="toDate"
                  label="Date To"
                  control={control}
                  minDate={watch("fromDate")}
                  maxDate={dayjs()}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="esp_name"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => {
                    const allSelectedEsp =
                      espData.length === selectedOptions.length;
                    const handleToggleOption = (selectedOps) =>
                      setSelectedOptions(selectedOps);
                    const handleClearOptions = () => setSelectedOptions([]);
                    const handleSelectAll = (isSelected) => {
                      if (isSelected) {
                        setSelectedOptions(espData);
                      } else {
                        handleClearOptions();
                      }
                    };
                    const handleToggleSelectAll = () => {
                      handleSelectAll(!allSelectedEsp);
                    };
                    const handleChange = (event, selectedOps, reason) => {
                      if (
                        reason === "selectOption" ||
                        reason === "removeOption"
                      ) {
                        if (
                          selectedOps.find(
                            (option) => option.label === "Select All"
                          )
                        ) {
                          handleToggleSelectAll();
                          const result = espData.filter(
                            (el) => el.label !== "Select All"
                          );
                          return field.onChange(result);
                        } else {
                          handleToggleOption(selectedOps);
                          return field.onChange(selectedOps);
                        }
                      } else if (reason === "clear") {
                        handleClearOptions();
                        handleToggleOption(selectedOps);
                        return field.onChange(selectedOps);
                      }
                    };
                    return (
                      <Autocomplete
                        multiple
                        limitTags={1}
                        includeInputInList
                        size="small"
                        id="tags-outlined"
                        value={selectedOptions}
                        disableCloseOnSelect
                        options={espData || []}
                        getOptionLabel={(option) => option?.label}
                        filterOptions={(options, params) => {
                          return [
                            { label: "Select All", value: "" },
                            ...options.filter((option) =>
                              option.label
                                .toLowerCase()
                                .includes(params.inputValue.toLowerCase())
                            ),
                          ];
                        }}
                        onChange={handleChange}
                        sx={{
                          width: "100%",
                          mr: 1,
                          "& .MuiAutocomplete-inputRoot": {
                            flexWrap: "nowrap !important",
                            overflow: "hidden",
                          },
                          "& .MuiAutocomplete-endAdornment": {
                            right: "0px !important",
                            background: "#FFFFFF",
                            overflow: "hidden",
                          },
                        }}
                        renderOption={(props, option, { selected }) => {
                          const selectAllProps =
                            option.label === "Select All"
                              ? { checked: allSelectedEsp }
                              : {};
                          return (
                            <li {...props}>
                              <Checkbox
                                checked={selected}
                                {...selectAllProps}
                                style={{ marginRight: 8 }}
                              />
                              {option.label}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            label="ESP"
                            error={!!errors.esp_name}
                            helperText={errors.esp_name?.message}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="channel_id"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => {
                    const allSelectedChannel =
                      chanelData?.length === selectedChannelOptions.length;
                    const handleToggleOption = (selectedChannelOps) =>
                      setSelectedChannelOptions(selectedChannelOps);
                    const handleClearOptions = () =>
                      setSelectedChannelOptions([]);
                    const handleSelectAll = (isSelected) => {
                      if (isSelected) {
                        setSelectedChannelOptions(chanelData);
                      } else {
                        handleClearOptions();
                      }
                    };
                    const handleToggleSelectAll = () => {
                      handleSelectAll(!allSelectedChannel);
                    };
                    const handleChange = (
                      event,
                      selectedChannelOps,
                      reason
                    ) => {
                      if (
                        reason === "selectOption" ||
                        reason === "removeOption"
                      ) {
                        if (
                          selectedChannelOps.find(
                            (option) => option.label === "Select All"
                          )
                        ) {
                          handleToggleSelectAll();
                          const result = chanelData.filter(
                            (el) => el.label !== "Select All"
                          );
                          return field.onChange(result);
                        } else {
                          handleToggleOption(selectedChannelOps);
                          return field.onChange(selectedChannelOps);
                        }
                      } else if (reason === "clear") {
                        handleClearOptions();
                        handleToggleOption(selectedChannelOps);
                        return field.onChange(selectedChannelOps);
                      }
                    };
                    return (
                      <Autocomplete
                        multiple
                        limitTags={1}
                        includeInputInList
                        size="small"
                        id="tags-outlined"
                        value={selectedChannelOptions}
                        disableCloseOnSelect
                        options={chanelData || []}
                        getOptionLabel={(option) => option?.label}
                        filterOptions={(options, params) => {
                          if (chanelData && chanelData?.length) {
                            return [
                              { label: "Select All", value: "" },
                              ...options.filter((option) =>
                                option.label
                                  .toLowerCase()
                                  .includes(params.inputValue.toLowerCase())
                              ),
                            ];
                          } else {
                            return [
                              ...options.filter((option) =>
                                option.label
                                  .toLowerCase()
                                  .includes(params.inputValue.toLowerCase())
                              ),
                            ];
                          }
                        }}
                        onChange={handleChange}
                        sx={{
                          width: "100%",
                          mr: 1,
                          "& .MuiAutocomplete-inputRoot": {
                            flexWrap: "nowrap !important",
                            overflow: "hidden",
                          },
                          "& .MuiAutocomplete-endAdornment": {
                            right: "0px !important",
                            background: "#FFFFFF",
                            overflow: "hidden",
                          },
                        }}
                        renderOption={(props, option, { selected }) => {
                          const selectAllProps =
                            option.label === "Select All"
                              ? { checked: allSelectedChannel }
                              : {};
                          return (
                            <li {...props}>
                              <Checkbox
                                checked={selected}
                                {...selectAllProps}
                                style={{ marginRight: 8 }}
                              />
                              {option.label}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            label="Channel Detail"
                            error={!!errors.channel_id}
                            helperText={errors.channel_id?.message}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={2} display={"flex"} justifyContent={"end"}>
                <Button
                  variant="outlined"
                  type="submit"
                  onClick={handleSubmit(onFormSubmit)}
                  // disabled={res.isLoading}
                  sx={{
                    maxHeight: "2.5rem",
                    mr: "10px",
                  }}
                >
                  Generate Report
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
      {res.isLoading && <CircularProgressLoading />}

      {!res.isLoading && (
        <Box
          sx={{
            pt: 3,
          }}
        >
          <AgGridComponent
            columnDefs={coloumSelect}
            // search={false}
            rowData={totalData}
            // domLayout="autoHeight"
            // height={"100%"}
            pagination={false}
            floatingFilter={false}
            paginationPageSize={10}
            onRowSelectionChangedHandler={(arg) => console.log(arg)}
            pinnedBottomRowData={pinnedBottomRowData}
            rowMode
          />
        </Box>
      )}
    </div>
  );
};

export default DomainEspExportReportTrend;
