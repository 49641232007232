import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { showMessage } from '/messageSlice';
import settingsConfig from "@configs/settingsConfig";
import jwtService from "@services/jwtService";

export const setUser = createAsyncThunk("user/setUser", async (user) => {
  /*
    You can redirect the logged-in user to a specific route depending on his role
    */
  if (user.loginRedirectUrl) {
    settingsConfig.loginRedirectUrl = user.loginRedirectUrl;
  }

  return user;
});

export const updateUserShortcuts = createAsyncThunk(
  "user/",
  async (shortcuts, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = {
      ...user,
      data: {
        ...user.data,
        shortcuts,
      },
    };

    dispatch(updateUserData(newUser));

    return newUser;
  }
);

export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState();

  if (!user.role || user.role.length === 0) {
    // is guest
    return null;
  }

  window.location.href = "/";

  // Set initial settings
  dispatch({});

  return dispatch(userLoggedOut());
};

export const updateUserData = (user) => async (dispatch, getState) => {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }

  jwtService
    .updateUserData(user)
    .then(() => {
      // dispatch(showMessage({ message: 'User data saved with api' }));
      alert("success JWT");
    })
    .catch((error) => {
      // dispatch(showMessage({ message: error.message }));
      alert("error", error);
    });
};

const initialState = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : {};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLoggedOut: (state, action) => initialState,
  },
  extraReducers: {
    [updateUserShortcuts.fulfilled]: (state, action) => action.payload,
    [setUser.fulfilled]: (state, action) => action.payload,
  },
});

export const { userLoggedOut } = userSlice.actions;

export const selectUser = ({ user }) => user;

export const selectUserShortcuts = ({ user }) => user.data.shortcuts;

export default userSlice.reducer;
