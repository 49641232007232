import React, { useState, useEffect } from "react";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import { useDispatch } from "react-redux";
import { Box, Button, Card, Grid } from "@mui/material";
import { axiosInstance } from "@services/axiosInstance";
import { baseURL } from "@configs/envConfig";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import AgGridComponent from "../../../../../../components/common/AgGridComponent";
import DatePickerComponent from "../../../../../../components/common/GlobalDatePicker";
import GlobalDropdown from "@components/common/GlobalDropedown";
import CommonFunctions from "../../../../../../utils/CommonFunctions";
import CircularProgressLoading from "../../../../../../components/loaders/CircularProgressLoading";
import DownloadButton from "../../../../../../components/common/DownloadButton";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../../../services/apiQueryServices";

const isDataRefresh = [
  { label: "No", value: "false" },
  { label: "Yes", value: "true" },
];
const LMReport = () => {
  const [rowSelected, setRowSelected] = useState({});
  const [columnDef, setColumnDef] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [coloum, setColoum] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      dataRefresh: "false",
      fromDate: dayjs().subtract(1, "day"),
      toDate: dayjs(),
    },
    mode: "all",
  });

  const [apiCall2, res2] = useApiCallMutation();

  useEffect(() => {
    if (res2.isSuccess && res2?.data?.data !== null) {
      setGridData(res2?.data?.data?.output);
      setColumnDef(res2?.data?.data?.headers);
    } else if (res2.isSuccess) {
      dispatch(
        snackbarMessageUpdate({
          open: true,
          color: "warning",
          message: "No Data Found",
        })
      );
    }
  }, [res2]);

  const onFormSubmit = (formData) => {
    setIsLoading(true);
    const data = {
      fromDate: dayjs(formData.fromDate.$d).format("YYYY-MM-DD"),
      toDate: dayjs(formData.toDate.$d).format("YYYY-MM-DD"),
      dataRefresh: formData?.dataRefresh,
    };
    apiCall2({
      endPoint: `dl/export/lm-report`,
      method: "POST",
      data: { data },
    });
  };

  const csvDownload = () => {
    setIsLoading(true);
    CommonFunctions.downloadFile(gridData);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (
    <div className="ag-theme-alpine" style={{ height: "calc(100vh - 235px)" }}>
      <Card>
        <Box
          sx={{
            padding: "20px 30px",
            borderBottom: "1px solid #E0E0E0",
          }}
        >
          <Box
            component={"form"}
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Grid container spacing={4} display={"flex"}>
              <Grid item xs={2}>
                <DatePickerComponent
                  name="fromDate"
                  label="From Date"
                  control={control}
                  minDate={dayjs("2000-01-01")}
                  maxDate={watch("toDate").add(1, "day")}
                />
              </Grid>
              <Grid item xs={2}>
                <DatePickerComponent
                  name="toDate"
                  label="Date To"
                  control={control}
                  minDate={watch("fromDate")}
                  maxDate={dayjs()}
                />
              </Grid>
              <Grid item xs={2}>
                <GlobalDropdown
                  options={isDataRefresh}
                  size="small"
                  control={control}
                  name="dataRefresh"
                  label="Generate New Summary"
                  error={!!errors.dataRefresh}
                  helperText={errors.dataRefresh?.message}
                  required
                />
              </Grid>
              <Grid item xs={6} display={"flex"} justifyContent={"end"}>
                <Button
                  variant="outlined"
                  type="submit"
                  onClick={handleSubmit(onFormSubmit)}
                  // disabled={watch("sourceOwner").length ? false : true}
                  sx={{
                    maxHeight: "2.5rem",
                    mr: "10px",
                  }}
                >
                  Generate Report
                </Button>

                {/* <Button
                  variant="outlined"
                  onClick={csvDownload}
                  disabled={gridData.length !== 0 ? false : true}
                  sx={{
                    background:
                      gridData.length !== 0 ? "#4ac24b" : "transparent",
                    color: "#fefefe",
                    maxHeight: "2.5rem",
                    "&:hover": {
                      background: "#1aaa26",
                    },
                  }}
                >
                  Download CSV
                </Button> */}
                <DownloadButton
                  onclick={csvDownload}
                  disabled={gridData.length}
                >
                  Download CSV
                </DownloadButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
      <Box
        sx={{
          padding: "20px",
        }}
      >
        {res2.isLoading && <CircularProgressLoading />}
        {!res2.isLoading && (
          <AgGridComponent
            rowData={gridData}
            columnDefs={columnDef}
            onRowSelectionChangedHandler={(arg) => {
              setIsEditMode(true);
            }}
            pagination={true}
            paginationPageSize={10}
            rowMode
          />
        )}
      </Box>
    </div>
  );
};

export default LMReport;
