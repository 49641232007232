import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const BarChart = ({ xdata, ydata, showD }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = echarts.init(chartRef.current);

    // Extract x-axis and y-axis data from the provided data

    let xaxisData = xdata || [];
    let yAxisData = ydata || [];

    const option = {
      grid: {
        left:  showD ? 10 : 0,
        right: showD ? 10 : 0,
        top:   showD ? 10 : 0,
        bottom: showD ? 10 : 0,
        containLabel: showD? true: false,
      },
      tooltip: {
        trigger: showD ? "axis" : "",
        axisPointer: {
          type: "shadow",
        },
      },
      xAxis: {
        type: "category",
        data: xaxisData,
        axisLabel: {
          show: true,
          interval: 0,
          textStyle: {
            color: "black",
            fontSize: 10,
          },
        },
        show: showD ? true : false, // Hide x-axis
      },
      yAxis: {
        type: "value",
        splitLine: {
          show: showD ? true : false,
        },
        show: showD ? true : false,
      },
      series: [
        {
          type: "bar",
          data: yAxisData,
          barCategoryGap: showD ? "30%" : "20%",
        },
      ],
    };

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, []);

  if (showD) {
    return <div ref={chartRef} style={{ width: "100%", height: "500px" }} />;
  }
  return (
    <div
      ref={chartRef}
      style={{
        width: "95%",
        height: "30px",
        marginTop: "8px",
        marginRight: "20px",
      }}
    />
  );
};

export default BarChart;
