import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const destinationSource = createSlice({
  name: "destinationSource",
  initialState,
  reducers: {
    updateDestinationSource: (state, action) => {
      state.tableData = action.payload;
    },
    updateTableRow: (state, action) => {
      const updatedRow = action.payload;
      const index = state.tableData.data.findIndex( (data) => data?._id === updatedRow?._id);  
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.tableData.data[index][key] = updatedRow[key];
      });
    },
    updateConfigInTableRow: (state, action) => {
      const updatedRow = action.payload;
      const index = state.tableData.data.findIndex((data) => data?.name === updatedRow?.name);
      if (index !== -1) {
        state.tableData.data[index]["config"] = updatedRow?.config;
        state.tableData.data[index]["mappedHeader"] = updatedRow?.config;
      }
    },
    updateMapHeadersInTableRow: (state, action) => {
      const updatedRow = action.payload;
      const index = state.tableData.data.findIndex(
        (data) => data?.sourceId === updatedRow?.sourceId
      );
      state.tableData.data[index]["mappedHeader"] = updatedRow?.mappedHeader;
    },
    addTableRow: (state, action) => {
      state.tableData.data.unshift(action.payload); 
    },
    removeTableRow: (state, action) => {
      state.tableData = state.tableData.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});

export const {
  updateDestinationSource,
  updateTableRow,
  updateConfigInTableRow,
  updateMapHeadersInTableRow,
  addTableRow,
  removeTableRow,
} = destinationSource.actions;
export default destinationSource.reducer;
