import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export default function BoxComponent() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '15%', alignContent: 'center' }}>
      <CircularProgress disableShrink />
    </Box>
  );
}
