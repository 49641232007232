import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const dataCountDetail = createSlice({
  name: "dataCountDetail",
  initialState,
  reducers: {
    updateDataCountDetailData: (state, action) => {
      state.tableData = action.payload;
    },
  },
});

export const { updateDataCountDetailData} = dataCountDetail.actions;
export default dataCountDetail.reducer;
