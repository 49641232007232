import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const channelList = createSlice({
  name: "channelList",
  initialState,
  reducers: {
    updateChannelListTableData: (state, action) => {
      state.tableData = action.payload;
    },
  },
});

export const { updateChannelListTableData } = channelList.actions;
export default channelList.reducer;
