import React, { useState, useEffect } from "react";
import AgGridComponent from "@components/common/AgGridComponent";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useDispatch } from "react-redux";
import { baseURL } from "@configs/envConfig";
import { Controller, useForm } from "react-hook-form";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import GlobalDropdown from "@components/common/GlobalDropedown";
import GlobalInput from "@components/common/GlobalInput";

import GlobalConfirmationPopup from "../../components/common/GlobalConfirmationPopup";
import CircularProgressLoading from "@components/loaders/CircularProgressLoading";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../services/apiQueryServices";
import {
  addTableRow,
  updateTableRow,
  updateConfigInTableRow,
} from "../../store/ExportDataModule/DestinationSourceSlice";
import { useSelector } from "react-redux";
import { addCopyConfigListQuery } from "../../store/ExportDataModule/DestinationSourceSlice/AddCopyConfigSlice";

const exportTypes = [
  { value: "live", label: "Live Data" },
  { value: "bulk", label: "Bulk Data" },
];

const AddEditDestinationSourceConfig = (props) => {
  const { setOpen, isOpen, defaultValues, loadData, isEditMode } = props;
  const [defaultValuesSchema, setDefaultValuesSchema] = useState({});
  const [copyConfigValue, setCopyConfigValue] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [message, setMessage] = useState(false);
  const dispatch = useDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [dialogRowSelected, setDialogRowSelected] = useState([]);
  const [dialogColumnSelected, setDialogColumnSelected] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCopyValue, setIsCopyValue] = useState(false);

  const CopyConfigListData = useSelector(
    (state) => state?.copyconfigList?.data
  );

  const copyConfigListQuery = useGetApiCallQuery({
    endPoint: "dl/export/data-source-config/copy-data",
    method: "GET",
  });

  const [create, createdData] = useApiCallMutation();
  const [update, updatedData] = useApiCallMutation();
  const [findone, findoneData] = useApiCallMutation();
  const [testQuery, testQueryData] = useApiCallMutation();

  useEffect(() => {
    if (copyConfigListQuery.isSuccess) {
      copyConfigListQuery.refetch();
      dispatch(
        addCopyConfigListQuery([...copyConfigListQuery?.data?.data].reverse())
      );
    }
  }, [copyConfigListQuery.data]);

  const dataDelayList = [
    { label: "No", value: "0" },
    { label: "1 Hours", value: "1" },
    { label: "6 Hours", value: "6" },
    { label: "12 Hours", value: "12" },
    { label: "24 Hrs (1 Day)", value: "24" },
    { label: "48 Hrs (2 Day)", value: "48" },
    { label: "72 Hrs (3 Day)", value: "72" },
    { label: "96 Hrs (4 Day)", value: "96" },
    { label: "120 Hrs (5 Day)", value: "120" },
    { label: "144 Hrs (6 Day)", value: "144" },
    { label: "240 Hrs (10 Day)", value: "240" },
    { label: "360 Hrs (15 Day)", value: "360" },
  ];

  const dataKeyList = [
    { label: "email", value: "email" },
    { label: "phone", value: "phone" },
  ];

  const recordImportDateList = [
    { label: "actual", value: "actual" },
    { label: "current", value: "current" },
  ];

  const emailValidityOptions = [
    { label: "Only Valid", value: "onlyValid" },
    { label: "Remove Bad", value: "removeBad" },
  ];

  const exportTypeList = [
    { label: "Live Data", value: "live" },
    { label: "Bulk Data", value: "bulk" },
  ];
  const totalHours = [
    { label: "00", value: 0 },
    { label: "01", value: 1 },
    { label: "02", value: 2 },
    { label: "03", value: 3 },
    { label: "04", value: 4 },
    { label: "05", value: 5 },
    { label: "06", value: 6 },
    { label: "07", value: 7 },
    { label: "08", value: 8 },
    { label: "09", value: 9 },
    { label: "10", value: 10 },
    { label: "11", value: 11 },
    { label: "12", value: 12 },
    { label: "13", value: 13 },
    { label: "14", value: 14 },
    { label: "15", value: 15 },
    { label: "16", value: 16 },
    { label: "17", value: 17 },
    { label: "18", value: 18 },
    { label: "19", value: 19 },
    { label: "20", value: 20 },
    { label: "21", value: 21 },
    { label: "22", value: 22 },
    { label: "23", value: 23 },
  ];

  useEffect(() => {
    setDefaultValuesSchema(defaultValues);
    // if (defaultValuesSchema.config) {

    setValue("name", defaultValuesSchema?.name);
    setValue(
      "sourceId",
      defaultValues?.sourceId ? defaultValues?.sourceId : ""
    );
    if (!defaultValuesSchema?.config) {
      setValue("id", defaultValues?.id);
    }
    // }
  }, [defaultValuesSchema]);

  useEffect(() => {
    if (defaultValues?.config) {
      const data = {
        sourceId: defaultValues?.sourceId,
      };
      findone({
        endPoint: `dl/export/data-source-config/find-one`,
        method: "POST",
        data: { data },
      });
    }
  }, []);

  const initialValues = {
    name: "",
    sourceId: "",
    // refreshDuration: "",
    // dataSendCount: "",
    // dataSendHourlyCount: "",
    dataDelay: "",
    dataKey: "",
    recordImportDate: "",
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
    exportType: "",
    dataSourceId: "",
    conditionA: "",
    conditionB: "",
    description: "",
    emailValidity: "",
    bulkDataType: "livedata", // id: "",
    exportMinDay: null,
    exportMaxDay: null,
    exportFromTime: 0,
    exportToTime: 0,
  };

  const validationSchema = Yup.object({
    // name: Yup.string().required("This field is required"),
    // sourceId: Yup.string().required("This field is required"),
    refreshDuration: Yup.mixed()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .test(
        "is-less-than-365",
        "Value must be greater than 0 and less than 365",
        (value) => value <= 365
      )
      .required("This field is required"),
    dataSendCount: Yup.mixed()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .test(
        "is-less-than-360",
        "Value must be greater than 0 and less than 100000",
        (value) => value <= 100000
      )
      .required("This field is required"),
    dataSendHourlyCount: Yup.mixed()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .test(
        "is-less-than-360",
        "Value must be greater than 0 and less than 20000",
        (value) => value <= 20000
      )
      .required("This field is required"),
    dataDelay: Yup.mixed()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .required("This field is required"),
    emailValidity: Yup.mixed()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .required("This field is required"),
    dataKey: Yup.string().required("This field is required"),
    recordImportDate: Yup.string().required("This field is required"),
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: "",
    exportType: Yup.string().required("This field is required"),
    dataSourceId: Yup.string().required("This field is required"),
    conditionA: "",
    conditionB: "",
    description: Yup.string(),
    exportMinDay: Yup.number()
      .typeError("This field is required")
      .min(0)
      .max(89)
      .test("is-required", "This Field is required", function (value) {
        const { bulkDataType } = this.parent;
        if (bulkDataType === "legacydata" && !value) {
          return false;
        } else {
          return true;
        }
      })
      .nullable(),
    exportMaxDay: Yup.number()
      .typeError("This field is required")
      .min(1)
      .max(90)
      .test(
        "is-greater",
        "Export Max Day must be greater than Export Min Day",
        function (value) {
          const { exportMinDay } = this.parent;
          if (value === null) {
            return true;
          } else {
            return exportMinDay < value;
          }
        }
      )
      .test("is-required", "This Field is required", function (value) {
        const { bulkDataType } = this.parent;
        if (bulkDataType === "legacydata" && !value) {
          return false;
        } else {
          return true;
        }
      })
      .nullable(),
    exportToTime: Yup.number().test(
      "isgeaterTime",
      "Export To Time should not be equal to Export From Time except both have zero value",
      function (value) {
        const { exportFromTime } = this.parent;
        if (exportFromTime === value && (exportFromTime !== 0 || value !== 0)) {
          return false;
        } else {
          return true;
        }
      }
    ),
  });

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isValid, isDirty },
    getValues,
    setValue,
    watch,
    setError,
    clearErrors,
    resetField,
    getFieldState,
    trigger,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const conditionA = watch("conditionA");
  const conditionB = watch("conditionB");
  const exportType = watch("exportType");
  const bulkDataType = watch("bulkDataType");
  const exportMaxDay = watch("exportMaxDay");
  const exportMinDay = watch("exportMinDay");

  useEffect(() => {
    if (exportType && exportType === "live") {
      setValue("bulkDataType", "");
      resetField("exportMaxDay");
      resetField("exportMinDay");
      clearErrors("exportMaxDay");
      clearErrors("exportMinDay");
    } else if (exportType && exportType === "bulk") {
      setValue(
        "bulkDataType",
        findoneData?.data?.data?.exportMinDay !== null
          ? "legacydata"
          : "livedata"
      );
    }
  }, [exportType]);
  useEffect(() => {
    if (bulkDataType === "livedata") {
      resetField("exportMaxDay");
      resetField("exportMinDay");
      clearErrors("exportMaxDay");
      clearErrors("exportMinDay");
    }
  }, [bulkDataType]);

  const setFormValue = (data) => {
    setValue("name", defaultValues?.name, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("sourceId", defaultValues?.sourceId, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("refreshDuration", data?.refreshDuration, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("dataSendCount", data?.dataSendCount, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("dataSendHourlyCount", data?.dataSendHourlyCount, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
    setValue("dataDelay", data?.dataDelay, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("dataKey", data?.dataKey, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("recordImportDate", data?.recordImportDate, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue(
      "emailValidity",
      data?.emailValidity ? data?.emailValidity : null,
      {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      }
    );
    setValue("monday", data?.monday, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("tuesday", data?.tuesday, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("wednesday", data?.wednesday, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("thursday", data?.thursday, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("friday", data?.friday, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("saturday", data?.saturday, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("sunday", data?.sunday, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("exportFromTime", data?.exportFromTime, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("exportToTime", data?.exportToTime, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("exportType", data?.exportType, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("bulkDataType", data?.exportMinDay ? "legacydata" : "livedata");
    setValue("exportMinDay", data?.exportMinDay, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("exportMaxDay", data?.exportMaxDay, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("dataSourceId", data?.dataSourceId, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("conditionA", data?.conditionA ? data?.conditionA : "", {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("conditionB", data?.conditionB ? data?.conditionB : "", {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("description", data?.description, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const successCallback = (response) => {
    const status = response?.status;

    setOpen(false);
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "success",
        message: status.value,
      })
    );
  };

  useEffect(() => {
    if (createdData.isSuccess && createdData.data !== null) {
      successCallback(createdData.data);
      dispatch(
        updateConfigInTableRow({
          name: createdData.data?.data?.name,
          config: true,
        })
      );
    }
  }, [createdData]);
  useEffect(() => {
    if (updatedData.isSuccess && updatedData.data !== null) {
      successCallback(updatedData.data);
    }
  }, [updatedData]);

  useEffect(() => {
    if (findoneData.isSuccess && findoneData.data !== null) {
      setFormValue(findoneData?.data.data);
      setIsCopyValue(true);
    } else {
      setIsCopyValue(false);
    }
  }, [findoneData, findoneData]);

  useEffect(() => {
    if (
      testQueryData.isSuccess &&
      testQueryData?.data?.data !== null &&
      !testQueryData.isError &&
      testQueryData?.data?.error == ""
    ) {
      setDialogRowSelected(testQueryData.data.data.data);
    } else {
      setDialogRowSelected([]);
    }
  }, [testQueryData]);

  //***********FORM SUBMISSION ********//
  const onFormSubmit = (formData) => {
    const data = { ...formData };
    data.copyHeaderSourceId = copyConfigValue?.value
      ? copyConfigValue?.value
      : "";

    if (!defaultValuesSchema?.config) {
      create({
        endPoint: `dl/export/data-source-config/create`,
        method: "POST",
        data: { data },
      });
    } else {
      update({
        endPoint: `dl/export/data-source-config/update/${defaultValuesSchema?.sourceId}`,
        method: "PUT",
        data: { data },
      });
    }
  };

  const handleConfirm = () => {
    const data = {
      sourceId: copyConfigValue?.value,
    };
    findone({
      endPoint: `dl/export/data-source-config/find-one`,
      method: "POST",
      data: { data },
    });
    setShowConfirmation(false);
    setMessage("");
  };
  const handleCancel = () => {
    setIsCopyValue(false);
    setShowConfirmation(false);
    setMessage("");
  };

  const handleTestQuery = async () => {
    const values = getValues();
    testQuery({
      endPoint: `dl/export/test-query`,
      method: "POST",
      data: { data: values },
    });
  };

  useEffect(() => {
    if (dialogRowSelected && dialogRowSelected.length) {
      const keys = Object.keys(dialogRowSelected[0]);
      const column = keys?.map((item, index) => {
        return { field: item, filter: true };
      });
      setDialogColumnSelected(column);
    }
  }, [dialogRowSelected]);

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "e" ||
      event.key === "E" ||
      event.key === "+" ||
      event.key === "-" ||
      event.key === "."
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      <Drawer anchor={"right"} open={isOpen} onClose={() => setOpen(false)}>
        <Box
          sx={{
            width: "80vw",
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Box marginBottom={"15px"}>
            <Typography component="h5" variant="h5" fontWeight={600}>
              {(defaultValuesSchema?.config &&
                "Update Destination Source Config") ||
                "Add Destination Source Config"}
              <span
                style={{
                  fontWeight: "500",
                  marginLeft: "4px",
                }}
              >
                {defaultValuesSchema?.name
                  ? `- (${defaultValuesSchema?.name})`
                  : ""}
              </span>
            </Typography>
          </Box>
          <Divider
            sx={{
              mb: "20px",
            }}
          />

          <GlobalConfirmationPopup
            open={showConfirmation}
            message={message}
            onConfirm={handleConfirm}
            onClose={() => {
              handleCancel();
            }}
            isRemark={false}
          />

          <Box component={"form"} onSubmit={handleSubmit}>
            <Grid item xs={12}>
              <Autocomplete
                value={
                  isCopyValue &&
                  copyConfigValue &&
                  Object.keys(copyConfigValue).length
                    ? copyConfigValue
                    : null
                }
                onChange={(event, newValue) => {
                  if (newValue) {
                    setShowConfirmation(true);
                    setMessage("Are you sure you want to proceed?");
                    setCopyConfigValue(newValue);
                  }
                }}
                options={CopyConfigListData}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Copy Config From" />
                )}
              />
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={4}></Grid>
              <Grid item xs={12}>
                <Typography component="h5" variant="h6">
                  Configuration Details
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <GlobalInput
                  required
                  fullWidth
                  size="small"
                  control={control}
                  type="number"
                  name="refreshDuration"
                  label="Log/Repeat Duration"
                  error={!!errors.refreshDuration}
                  helperText={errors.refreshDuration?.message}
                />
              </Grid>
              <Grid item xs={4}>
                <GlobalInput
                  required
                  fullWidth
                  size="small"
                  control={control}
                  name="dataSendCount"
                  label="Daily Data Cap"
                  error={!!errors.dataSendCount}
                  helperText={errors.dataSendCount?.message}
                />
              </Grid>
              <Grid item xs={4}>
                <GlobalInput
                  required
                  fullWidth
                  size="small"
                  control={control}
                  name="dataSendHourlyCount"
                  label="Hourly Data Cap"
                  error={!!errors.dataSendHourlyCount}
                  helperText={errors.dataSendHourlyCount?.message}
                />
              </Grid>
              <Grid item xs={4}>
                <GlobalDropdown
                  options={dataDelayList}
                  control={control}
                  size="small"
                  name="dataDelay"
                  label="Data Delay"
                  error={!!errors.dataDelay}
                  helperText={errors.dataDelay?.message}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <GlobalDropdown
                  options={dataKeyList}
                  control={control}
                  size="small"
                  name="dataKey"
                  label="Data Key(Feed Type)"
                  error={!!errors.dataKey}
                  helperText={errors.dataKey?.message}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <GlobalDropdown
                  options={recordImportDateList}
                  control={control}
                  size="small"
                  name="recordImportDate"
                  label="Record Import Data Value"
                  error={!!errors.recordImportDate}
                  helperText={errors.recordImportDate?.message}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <GlobalDropdown
                  options={emailValidityOptions}
                  control={control}
                  size="small"
                  name="emailValidity"
                  label="Email Validity"
                  error={!!errors.emailValidity}
                  helperText={errors.emailValidity?.message}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Typography component="h5" variant="h6">
                  Select Days for data sending
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box display={"flex"} gap={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...register("monday")}
                        checked={watch("monday")}
                        error={!!errors.monday}
                        helperText={errors.monday?.message}
                      />
                    }
                    label={"Monday"}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...register("tuesday")}
                        checked={watch("tuesday")}
                        error={!!errors.tuesday}
                        helperText={errors.tuesday?.message}
                      />
                    }
                    label={"Tuesday"}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        {...register("wednesday")}
                        checked={watch("wednesday")}
                        error={!!errors.wednesday}
                        helperText={errors.wednesday?.message}
                      />
                    }
                    label={"wednesday"}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...register("thursday")}
                        checked={watch("thursday")}
                        error={!!errors.thursday}
                        helperText={errors.thursday?.message}
                      />
                    }
                    label={"Thursday"}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        {...register("friday")}
                        checked={watch("friday")}
                        error={!!errors.friday}
                        helperText={errors.friday?.message}
                      />
                    }
                    label={"Friday"}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...register("saturday")}
                        checked={watch("saturday")}
                        error={!!errors.saturday}
                        helperText={errors.saturday?.message}
                      />
                    }
                    label={"Saturday"}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...register("sunday")}
                        checked={watch("sunday")}
                        error={!!errors.sunday}
                        helperText={errors.sunday?.message}
                      />
                    }
                    label={"Sunday"}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <GlobalDropdown
                  options={totalHours}
                  control={control}
                  size="small"
                  name="exportFromTime"
                  label="Export From Time"
                  error={!!errors.exportFromTime}
                  helperText={errors.exportFromTime?.message}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <GlobalDropdown
                  options={totalHours}
                  control={control}
                  size="small"
                  name="exportToTime"
                  label="Export To Time"
                  error={!!errors.exportToTime}
                  helperText={errors.exportToTime?.message}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Typography component="h5" variant="h6">
                  Data Export Setting
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <GlobalDropdown
                  options={exportTypes}
                  control={control}
                  size="small"
                  name="exportType"
                  label="Export Type"
                  error={!!errors.exportType}
                  helperText={errors.exportType?.message}
                  required
                />
              </Grid>
              {watch("exportType") === "bulk" && (
                <Grid item xs={4}>
                  <Box display={"flex"} gap={4}>
                    <FormControl>
                      {/* <FormLabel id="demo-row-radio-buttons-group-label">
                        Bulk Data Type *
                      </FormLabel> */}
                      <Controller
                        name="bulkDataType"
                        control={control}
                        defaultValue="" // Set default value here if needed
                        render={({ field }) => (
                          <RadioGroup
                            row
                            {...field}
                            aria-label="Bulk Data Type"
                            aria-labelledby="demo-row-radio-buttons-group-label"
                          >
                            <FormControlLabel
                              value="livedata"
                              control={<Radio />}
                              label="Live Data"
                            />
                            <FormControlLabel
                              value="legacydata"
                              control={<Radio />}
                              label="Legacy Data"
                            />
                          </RadioGroup>
                        )}
                      />
                    </FormControl>
                  </Box>
                </Grid>
              )}

              {watch("exportType") === "bulk" &&
                watch("bulkDataType") === "legacydata" && (
                  <>
                    <Grid item xs={2}>
                      <GlobalInput
                        required
                        fullWidth
                        size="small"
                        onKeyDown={handleKeyDown}
                        control={control}
                        type="number"
                        name="exportMinDay"
                        label="Export Min Day"
                        error={!!errors.exportMinDay}
                        helperText={errors.exportMinDay?.message}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <GlobalInput
                        required
                        fullWidth
                        size="small"
                        control={control}
                        type="number"
                        name="exportMaxDay"
                        onKeyDown={handleKeyDown}
                        label="Export Max Day"
                        error={
                          !!errors.exportMaxDay ||
                          getFieldState("exportMaxDay").error
                        }
                        helperText={errors.exportMaxDay?.message}
                      />
                    </Grid>
                  </>
                )}
              {/* <Grid item xs={4}>
                {watch("exportType") === "bulkdata" && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <DatePicker
                        label="Minimum Date"
                        {...register("exportMinDay")}
                        // defaultValue={dayjs().get("date")}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                )}
              </Grid>
              <Grid item xs={4}>
                {watch("exportType") === "bulkdata" && (
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker", "DatePicker"]}>
                        <DatePicker
                          label="Maximum Date"
                          {...register("exportMaxDay")}
                          // defaultValue={dayjs().get("date")}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </>
                )}
              </Grid> */}

              <Grid item xs={12}>
                <GlobalInput
                  required
                  fullWidth
                  size="small"
                  control={control}
                  name="dataSourceId"
                  label="Data Source ID"
                  error={!!errors.dataSourceId}
                  helperText={errors.dataSourceId?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <div
                  style={{
                    marginBottom: "8px",
                  }}
                >
                  <small>
                    <b>Note:</b> Add where condition only for fields as
                    (email_address, leadurldomain, source_id, ip_address,
                    timezone, recordimport_date)
                  </small>
                </div>
                <GlobalInput
                  fullWidth
                  multiline
                  rows={4}
                  size="small"
                  control={control}
                  name="conditionA"
                  label="Apply 'WHERE' clauses 1"
                  error={!!errors.conditionA}
                  helperText={errors.conditionA?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    marginBottom: "8px",
                  }}
                >
                  <small>
                    <b>Note:</b> Add where condition only for columns like
                    (email_address, first_name, last_name, gender, dob, age,
                    city, state, state_code, zip_code, credit_score, employer,
                    job_title, income_type, monthly_income, own_home,
                    loan_reason, applied_amount, emailoversightstatus,
                    leadisverifiedeo, phone_cell, phone_type, phone_carrier,
                    phone_carrier_parent, phone_validity, phone_cleaned_on,
                    phone_blacklisted, leadurldomain, datasourceid, ip_address,
                    recordimportdate, timezone, createdate, importdate,
                    recordimportcount, datediff)
                  </small>
                </div>
                <GlobalInput
                  fullWidth
                  multiline
                  rows={4}
                  size="small"
                  control={control}
                  name="conditionB"
                  label="Apply 'WHERE' clauses 2"
                  error={!!errors.conditionB}
                  helperText={errors.conditionB?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalInput
                  fullWidth
                  multiline
                  rows={4}
                  size="small"
                  control={control}
                  name="description"
                  label="Description"
                  error={!!errors.description}
                  helperText={errors.description?.message}
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              width: "100%",
              pb: "15px",
              pt: "15px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleTestQuery();
                    setIsDialogOpen(true);
                  }}
                  // disabled={
                  //   isValid
                  //     ? !(conditionA.length !== 0 || conditionB.length !== 0)
                  //     : true
                  // }
                  style={{
                    width: "100%",
                  }}
                >
                  Test Query
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="outlined"
                  style={{ width: "100%" }}
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  type="submit"
                  variant="outlined"
                  style={{
                    color: "#ff831f",
                    width: "100%",
                    borderColor: "#ff831f",
                    opacity:
                      createdData.isLoading || updatedData.isLoading ? 0.8 : 1,
                    pointerEvents:
                      createdData.isLoading || updatedData.isLoading
                        ? "none"
                        : "auto",
                    cursor:
                      createdData.isLoading || updatedData.isLoading
                        ? "not-allowed"
                        : "pointer",
                  }}
                  onClick={handleSubmit(onFormSubmit)}
                >
                  {createdData.isLoading || updatedData.isLoading
                    ? "Wait..."
                    : defaultValuesSchema?.config
                    ? "Update"
                    : "Add "}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={isDialogOpen}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          maxWidth={"xd"}
          fullWidth={true}
        >
          <DialogTitle id="responsive-dialog-title">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div status>Data Information</div>
              <div
                style={{
                  marginRight: "16px",
                }}
              >
                Data Count: {dialogRowSelected.length}
              </div>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {testQueryData?.isLoading && <CircularProgressLoading />}
              {!testQueryData?.isLoading && (
                <AgGridComponent
                  rowData={dialogRowSelected || []}
                  columnDefs={dialogColumnSelected || []}
                  onRowSelectionChangedHandler={(arg) => console.log(arg)}
                  pagination={false}
                  paginationPageSize={10}
                  search={false}
                  rowMode
                />
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Cancel
            </Button>
            {/* <Button onClick={handleClose} autoFocus>
              Agree
            </Button> */}
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default AddEditDestinationSourceConfig;
