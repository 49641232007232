import React, { useEffect } from "react";
import { useGetApiCallQuery } from "../../../../services/apiQueryServices";
import { Box } from "@mui/material";
import AgGridComponent from "../../../../components/common/AgGridComponent";
import CircularProgressLoading from "../../../../components/loaders/CircularProgressLoading";
const SharingOutReport = () => {
  const { data, refetch, isError, isFetching, isLoading, isSuccess } =
    useGetApiCallQuery({
      endPoint: "dl/export/sharing-out-report",
      method: "GET",
    });
  useEffect(() => {
    refetch();
  }, []);
  return (
    <>
      {isLoading && <CircularProgressLoading />}
      {!isLoading && (
        <Box
          sx={{
            pt: 3,
          }}
        >
          <AgGridComponent
            columnDefs={data?.data?.headers}
            rowData={data?.data?.data}
            pagination={true}
            floatingFilter={false}
            paginationPageSize={10}
            onRowSelectionChangedHandler={(arg) => console.log(arg)}
            rowMode
          />
        </Box>
      )}
    </>
  );
};

export default SharingOutReport;
