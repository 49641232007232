import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import DatePickerComponent from "../../../../../../components/common/GlobalDatePicker";
import { axiosInstance } from "@services/axiosInstance";
import { baseURL } from "@configs/envConfig";
import AgGridComponent from "../../../../../../components/common/AgGridComponent";
import CircularProgressLoading from "@components/loaders/CircularProgressLoading";
import { FormatAlignJustify } from "@mui/icons-material";
import CommonFunctions from "../../../../../../utils/CommonFunctions";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import { useDispatch } from "react-redux";
import GlobalDropdown from "@components/common/GlobalDropedown";
import DownloadButton from "../../../../../../components/common/DownloadButton";
import { useSelector } from "react-redux";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../../../services/apiQueryServices";
import { addCountSourceOwner } from "../../../../../../store/ExportDataModule/ReportsSlice/CountReportSlice/CountReportSourceOwnerSlice";
import { updateTableRow } from "../../../../../../store/ExportDataModule/ReportsSlice/CountReportSlice";

const staticColumns = [
  { headerName: "Owner", field: "ownerName", filter: true },
  { headerName: "Source Id", field: "sourceId", filter: true },
  { headerName: "Channel Name", field: "description", filter: true },
  { headerName: "Feed Type", field: "dataKey", filter: true },
  { headerName: "Sent Count", field: "sentCount", filter: true },
  { headerName: "Remark", field: "remark", filter: true },
  // { headerName: "Delivered Count", field: "dest_id", filter: true },
];

const ExportCountReport = () => {
  const [columnDef, setColumnDef] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [overallData, setOverallData] = useState({});
  const [sourceOwnerList, setSourceOwnerList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataKeys, setDataKeys] = useState([]);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      sourceOwner: "",
      fromDate: dayjs().subtract(1, "day"),
      toDate: dayjs(),
    },
    mode: "all",
  });

  const CountSourceOwnerList = useSelector(
    (state) => state?.countSourceOwner?.data
  );

  const CountSourceOwnerQuery = useGetApiCallQuery({
    endPoint: "dl/export/source-owner/list-items",
    method: "GET",
  });

  useEffect(() => {
    if (CountSourceOwnerQuery.isSuccess) {
      CountSourceOwnerQuery.refetch();
      const list = [...CountSourceOwnerQuery?.data?.data].reverse();
      list.unshift({ label: "All", value: "All" });
      dispatch(addCountSourceOwner(list));
    }
  }, [CountSourceOwnerQuery.data]);

  const [apiCall, res] = useApiCallMutation();

  useEffect(() => {
    if (
      res.isSuccess &&
      res?.data?.data !== null &&
      res?.data?.data !== undefined
    ) {
      if (res.data.error) {
        setOverallData([]);
      } else {
        setOverallData(res?.data?.data);
      }
    } else {
      setOverallData({});
    }
  }, [res]);

  useEffect(() => {
    if (Object.entries(overallData)?.length) {
      setDataKeys(Object.keys(overallData));
    } else {
      setDataKeys([]);
    }
  }, [overallData]);

  const onFormSubmit = (formData) => {
    const data = {
      fromDate: dayjs(formData.fromDate.$d).format("YYYY-MM-DD"),
      toDate: dayjs(formData.toDate.$d).format("YYYY-MM-DD"),
      sourceOwner:
        formData?.sourceOwner === "All" || formData?.sourceOwner === null
          ? ""
          : formData?.sourceOwner,
    };

    apiCall({
      endPoint: `dl/export/source-owner/count-report`,
      method: "POST",
      data: { data },
    });

    setGridData([]);
  };

  const csvDownload = () => {
    setIsLoading(true);

    let data = [];

    for (let key of Object.keys(overallData)) {
      for (let row of overallData[key]?.channels) {
        data.push({
          OwnerName: row?.ownerName,
          sourceId: row?.sourceId,
          ChannelName: row?.description,
          Feedtype: row?.dataKey,
          Sentcount: row?.sentCount,
        });
      }
    }
    CommonFunctions.downloadFile(data);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };
  return (
    <div className="ag-theme-alpine" style={{ height: "calc(100vh - 235px)" }}>
      <Card>
        <Box
          sx={{
            padding: "20px 30px",
            borderBottom: "1px solid #E0E0E0",
          }}
        >
          <Box
            component={"form"}
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Grid container spacing={4} display={"flex"}>
              <Grid item xs={2}>
                <DatePickerComponent
                  name="fromDate"
                  label="Date From"
                  control={control}
                  minDate={dayjs("2000-01-01")}
                  maxDate={watch("toDate")}
                />
              </Grid>
              <Grid item xs={2}>
                <DatePickerComponent
                  name="toDate"
                  label="Date To"
                  control={control}
                  minDate={watch("fromDate")}
                  maxDate={dayjs()}
                />
              </Grid>
              <Grid item xs={2}>
                <GlobalDropdown
                  options={CountSourceOwnerList}
                  size="small"
                  control={control}
                  name="sourceOwner"
                  label="Source Owner"
                  error={!!errors.sourceOwner}
                  helperText={errors.sourceOwner?.message}
                  required
                />
              </Grid>
              <Grid item xs={6} display={"flex"} justifyContent={"end"}>
                <Button
                  variant="outlined"
                  type="submit"
                  onClick={handleSubmit(onFormSubmit)}
                  // disabled={watch("sourceOwner").length ? false : true}
                  sx={{
                    maxHeight: "2.5rem",
                    mr: "10px",
                  }}
                >
                  Generate Report
                </Button>
                {/* <Button
                    variant="outlined"
                    onClick={csvDownload}
                    disabled={Object.entries(overallData).length ? false : true}
                    sx={{
                      background: Object.entries(overallData).length
                        ? "#4ac24b"
                        : "transparent",
                      color: "#fefefe",
                      maxHeight: "2.5rem",
                      "&:hover": {
                        background: "#1aaa26",
                      },
                    }}
                  >
                    Download CSV
                  </Button> */}
                <DownloadButton
                  onclick={csvDownload}
                  disabled={Object.entries(overallData).length}
                >
                  Download CSV
                </DownloadButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
      {res.isLoading && <CircularProgressLoading />}

      {!res.isLoading && dataKeys.length === 0 && (
        <Box
        // sx={{
        //   pt: 3,
        // }}
        >
          <AgGridComponent
            columnDefs={columnDef}
            rowData={gridData}
            domLayout="autoHeight"
            pagination={false}
            floatingFilter={true}
            paginationPageSize={10}
            onRowSelectionChangedHandler={(arg) => console.log(arg)}
            rowMode
          />
        </Box>
      )}

      {!isLoading &&
        dataKeys.length !== 0 &&
        dataKeys.map((key, index) => (
          <>
            <Box
              sx={{
                pt: 3,
              }}
            >
              <Card
                sx={{
                  mb: "10px",
                }}
              >
                <Box
                  container
                  sx={{
                    padding: "20px 30px",
                    borderBottom: "1px solid #E0E0E0",
                    display: "flex",
                    // justifyContent: "space-between",
                  }}
                >
                  <Box item xs={4}>
                    <div style={{ fontSize: "0.9rem" }}>
                      Owner Name : <b>{overallData[key]?.ownerName}</b>
                    </div>
                  </Box>
                  <Box item xs={4} ml={6}>
                    <div style={{ fontSize: "0.9rem" }}>
                      Total Count: <b>{overallData[key]?.totalCount}</b>
                    </div>
                  </Box>
                </Box>
              </Card>
              <AgGridComponent
                key={key}
                search={false}
                columnDefs={staticColumns}
                rowData={overallData[key]?.channels}
                domLayout="autoHeight"
                height={"100%"}
                pagination={false}
                floatingFilter={false}
                paginationPageSize={10}
                onRowSelectionChangedHandler={(arg) => console.log(arg)}
              />
            </Box>
            <Divider
              sx={{
                mb: "20px",
                mt: "20px",
              }}
            />
          </>
        ))}
    </div>
  );
};

export default ExportCountReport;
