import axios from "axios";
import Cookie from "universal-cookie";
import { baseURL } from "@configs/envConfig";
import { useDispatch } from "react-redux";
import JwtService from "@services/jwtService";
import { setUser } from "@store/auth/userSlice";

const cookie = new Cookie();

export const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const userInfo = (data) => {
  const user = JSON.parse(localStorage.getItem("userInfo"));
  if (data) {
    const result = {
      ...data,
      user: {
        id: user?.id,
        name: user?.name,
      },
    };
    return result;
  }
};

axiosInstance.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user?.token?.access_token;
    const data = userInfo(config?.data);
    config.data = data;
    if (accessToken != undefined || accessToken != null) {
      config.headers["Authorization"] = "Bearer " + accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (!error.response) {
      alert(
        "A server/network error occurred. " +
          "Looks like CORS might be the problem. " +
          "Sorry about this - we will get it fixed shortly."
      );
      return Promise.reject(error);
    }

    if (
      error.response.status === 401 &&
      originalRequest.url === `${baseURL}/core/refresh-token`
    ) {
      cookie.remove("token", { path: "/" });
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login/";
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      const refreshToken = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))?.token.refresh_token
        : null;
      if (refreshToken) {
        try {
          const refreshResponse = await axios.post(
            `${baseURL}/core/refresh-token`,
            {
              data: {
                clientName: "datalabs",
                refreshToken: refreshToken,
              },
            }
          );
          const token = { ["token"]: refreshResponse.data.data };
          localStorage.setItem("user", JSON.stringify(token));
          const newToken = refreshResponse.data.data.access_token;

          axiosInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${newToken}`;

          error.config.headers["Authorization"] = "Bearer " + `${newToken}`;
          return axiosInstance.request(error.config);
        } catch (refreshError) {
          JwtService.setSession(null);
          window.location.href = "/";
          return Promise.reject(refreshError);
        }
      } else {
        JwtService.setSession(null);
        window.location.href = "/";
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
