import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],

};

const headerData = createSlice({
  name: "headerData",
  initialState,
  reducers: {
    updateHeaderList: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { updateHeaderList } = headerData.actions;
export default headerData.reducer;
