import React from "react";
import PropTypes from "prop-types";
import { Box, Tab, Tabs, Typography } from "@mui/material";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabComponent = ({ tabData, onChange }) => {
  const [value, setValue] = React.useState(0);
  const handleChangeValue = (val) => {
    onChange(val + 1);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleChangeValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            pl: "5px",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {tabData?.map((tab, index) => (
              <Tab key={index} label={tab?.tabname} {...a11yProps(index)} />
            ))}
          </Tabs>
        </Box>
        <Box
          sx={{
            pr: "5px",
          }}
        >
          {tabData?.map(
            (models, index) =>
              value === index && (
                <div key={index}>
                  {models?.model?.map((model, index) => (
                    <div key={index}>{model}</div>
                  ))}
                </div>
              )
          )}
        </Box>
      </Box>
      {tabData?.map((data, index) => (
        <TabPanel key={index} value={value} index={index}>
          {data?.data}
        </TabPanel>
      ))}
    </Box>
  );
};

export default TabComponent;
