// sourceOwnerNameSlice.js
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  data: [],
};

const sourceOwnerNameSlice = createSlice({

  name: "sourceOwnerName",
  initialState,
  reducers: {    
    updateSourceOwnerName: (state, action) => {    
      state.data = action.payload;
    },
  },
});

export const { updateSourceOwnerName } = sourceOwnerNameSlice.actions;
export default sourceOwnerNameSlice.reducer;
