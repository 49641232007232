import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AgGridComponent from "../../../../../../components/common/AgGridComponent";
import AgGridColumnChart from "../../../../../../components/common/AgGridGroupColoumChart";
import CircularProgressLoading from "@components/loaders/CircularProgressLoading";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../../../services/apiQueryServices";

const ImportFilesTableData = () => {
  const [open, setOpen] = React.useState(false);
  const [gridRowData, setGridRowData] = useState([]);
  const [columnDef, setColumnDef] = useState([]);
  const [columnList, setColumnList] = useState([]);
  const [countReport, setCountReport] = useState("");
  const [reportData, setReportData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [coloumSelect, setColoumSelect] = useState([]);
  const [count, setCount] = useState(100);
  const [title, setTitle] = useState("");

  const { tablename } = useParams();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  //  calling gridData api

  const [GridDataApi, GridDataApiData] = useApiCallMutation();
  // const GridDataApi = useGetApiCallQuery({
  //   endPoint: `dl/file-source/import-file-table/${tablename}/${
  //     count ? `${count}` : "100"
  //   }`,
  //   method: "GET",
  //   headers: {},
  // });

  useEffect(() => {
    GridDataApi({
      endPoint: `dl/file-source/import-file-table/${tablename}/${
        count ? `${count}` : "100"
      }`,
      method: "GET",
    });
  }, [count, tablename]);

  useEffect(() => {
    getData();
  }, [GridDataApiData]);
  const getData = async () => {
    if (GridDataApiData?.isSuccess && GridDataApiData?.data?.data !== null) {
      setGridRowData([...GridDataApiData?.data?.data].reverse());
    }
  };

  useEffect(() => {
    if (gridRowData && gridRowData.length) {
      const keys = Object.keys(gridRowData[0]);
      const column = keys?.map((item, index) => {
        return {
          headerName: item,
          field: item,
          filter: true,
        };
      });
      setColumnDef(column);
      const coloumList = keys
        .map((col) => {
          if (col === "filename" || col === "created" || col === "remark") {
            return null;
          }
          return { label: col, value: col };
        })
        .filter(Boolean);
      if (!columnList?.length) {
        setColumnList(coloumList);
      }
    }
  }, [gridRowData]);

  const countList = [
    { label: "100", value: 100 },
    { label: "200", value: 200 },
    { label: "500", value: 500 },
  ];
  const reportsList = [
    { label: "Count Report", value: "countReport" },
    { label: "Email Domain", value: "emailDomain" },
    { label: "Group By Count", value: "groupByCount" },
  ];

  //api call for import files
  const [reportApi, res] = useApiCallMutation();
  useEffect(() => {
    if (res?.isSuccess) {
      setReportData(res?.data?.data);
      setOpen(true);
    }
  }, [res]);

  const getReportData = async (data) => {
    reportApi({
      endPoint: `dl/file-source/import-file/count-report`,
      method: "POST",
      data: { data },
    });
  };

  useEffect(() => {
    if (reportData.length) {
      if (Object.keys(reportData[0]).length === 3) {
        setSeriesData([
          { type: "column", xKey: "field", yKey: "cnt" },
          { type: "column", xKey: "field", yKey: "uniq_cnt" },
        ]);
      } else {
        setSeriesData([{ type: "column", xKey: "field", yKey: "cnt" }]);
      }
    }
  }, [reportData]);

  const handleClick = () => {
    let data = {
      table: tablename,
    };
    if (countReport === "countReport") {
      data.cols = coloumSelect.map((item) => item.value);
      setTitle("Initial Count and Unique-Count Report");
    } else if (countReport === "emailDomain") {
      data.emailField = coloumSelect[0].value;
      setTitle("Email Domain Count Report");
    } else if (countReport === "groupByCount") {
      data.field = coloumSelect[0].value;
      setTitle("Group By Count Report");
    }
    getReportData(data);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // if (GridDataApiData?.isLoading) return <CircularProgressLoading />;
  return (
    <Box
      sx={{
        padding: "20px",
      }}
    >
      {/* <Card> */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={"center"}
        alignContent={"center"}
        sx={{
          width: "100%",
          padding: "20px 30px",
          borderBottom: "1px solid #E0E0E0",
          [theme.breakpoints.down("lg")]: {
            flexDirection: "column",
          },
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          sx={{
            width: "50%",
            [theme.breakpoints.down("md")]: {
              width: "100%",
              mb: 2,
            },
            [theme.breakpoints.down("lg")]: {
              mb: 2,
              width: "100%",
            },
          }}
        >
          <Typography sx={{ mr: 4 }}>
            Pre - Text : <b>{tablename}</b>{" "}
          </Typography>
          <Typography sx={{ mr: 4 }}>
            Total Data Count :{" "}
            <span style={{ color: "#2065D1" }}>{gridRowData?.length}</span>
          </Typography>
          {/* </Box>
        <Box display={"flex"} alignItems={"center"}  sx={{}}> */}
          <Typography sx={{ mr: 1 }}>Data Limit : </Typography>
          <Autocomplete
            disablePortal
            size="small"
            id="combo-box-demo"
            defaultValue={100}
            fullWidth
            options={countList}
            onChange={(e, val) => setCount(val?.value)}
            sx={{ width: 100, mr: 2 }}
            renderInput={(params) => <TextField {...params} label="Count" />}
          />
          {/* <Button
            onClick={getData}
            sx={{ mr: 1, whiteSpace: "nowrap" }}
            variant="contained"
            disabled={!count}
          >
            Fetch Data
          </Button> */}
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
          sx={{
            width: "50%",
            [theme.breakpoints.down("md")]: {
              width: "100%",
              mb: 2,
            },
            [theme.breakpoints.down("lg")]: {
              width: "100%",
              mb: 2,
            },
          }}
        >
          <Autocomplete
            multiple
            disablePortal
            filterSelectedOptions
            size="small"
            id="combo-box-demo"
            options={columnList}
            onChange={(e, val) => {
              setColoumSelect(val);
            }}
            sx={{ width: 400, mr: 1 }}
            renderInput={(params) => (
              <TextField {...params} label="Select Column" />
            )}
          />
          <Autocomplete
            disablePortal
            size="small"
            id="combo-box-demo"
            options={reportsList}
            sx={{ width: 180, mr: 1 }}
            onChange={(e, val) => setCountReport(val?.value)}
            renderInput={(params) => (
              <TextField {...params} label="Report List" />
            )}
          />
          <Button
            onClick={handleClick}
            variant="contained"
            disabled={!(countReport && coloumSelect.length)}
            sx={{
              whiteSpace: "nowrap",
            }}
          >
            Generate Report
          </Button>
        </Box>
      </Box>
      {/* </Card> */}

      <Box
        sx={{
          pt: 3,
        }}
      >
        {GridDataApiData?.isLoading && <CircularProgressLoading />}
        {!GridDataApiData?.isLoading && gridRowData && columnDef && (
          <AgGridComponent
            rowData={gridRowData}
            columnDefs={columnDef}
            onRowSelectionChangedHandler={(arg) => console.log(arg)}
            pagination={true}
            paginationPageSize={10}
          />
        )}
      </Box>
      <Box>
        <div>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"md"}
            fullWidth={true}
          >
            <DialogTitle id="responsive-dialog-title">
              {countReport === "countReport" && "Count Report"}
              {countReport === "emailDomain" && "Email Domain"}
              {countReport === "groupByCount" && "Group By Count"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <AgGridColumnChart
                  data={reportData}
                  series={seriesData}
                  title={title}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                Cancel
              </Button>
              {/* <Button onClick={handleClose} autoFocus>
              Agree
            </Button> */}
            </DialogActions>
          </Dialog>
        </div>
      </Box>
    </Box>
  );
};

export default ImportFilesTableData;
