import React from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import DestinationOwner from "./components/DestinationOwner";
import DestinationSource from "./components/DestinationSource";
import FeedExecutionList from "./components/FeedExecutionList";
import ScriptRunningReport from "./components/ScriptRunningReport";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ChannelManagement = () => {
  const [tabindex, setTabindex] = React.useState(1);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const actionButtons = () => {
  //   return (
  //     <span>
  //       <>
  //         <Icon path={mdiPencil} onClick={handleButtonClick} size={1} />
  //         <Icon path={mdiCog} size={1} onClick={() => alert(`ahdkjashdkj`)} />
  //       </>
  //     </span>
  //   );
  // };
  // const keys = Object.keys(Tdata1.data[0]);
  // const coloum = keys.map((item, index) => {
  //   return { field: item, filter: true };
  // });
  // coloum.push({ field: "Action", cellRenderer: actionButtons });
  // const tabData = [
  //   {
  //     tabname: "Destination Owner",
  //     data: <AgGridComponent rowData={Tdata1.data} columnDefs={coloum} />,
  //     model: [
  //       <AddEditNewDataSource
  //         name={"Add Destination Owner"}
  //       ></AddEditNewDataSource>,
  //     ],
  //   },
  //   {
  //     tabname: "Destination Source",
  //     data: <AgGridComponent rowData={Tdata1.data} columnDefs={coloum} />,
  //     model: [<AddEditDestinationSource name={"Add Destination Source"} />],
  //   },
  //   {
  //     tabname: "Feed Execution List",
  //     data: <AgGridComponent rowData={Tdata1.data} columnDefs={coloum} />,
  //     model: [
  //       <AddEditDestinationSourceConfig
  //         name={"Add Feed To Execution List"}
  //       ></AddEditDestinationSourceConfig>,
  //     ],
  //   },
  //   {
  //     tabname: "Script Running Report",
  //     data: <AgGridComponent rowData={Tdata1.data} columnDefs={coloum} />,
  //     model: [],
  //   },
  // ];
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Destination Owner" />
            <Tab label="Destination Source" />
            <Tab label="Feed Execution List" />
            {/* <Tab label="Script Running Report" /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <DestinationOwner></DestinationOwner>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DestinationSource></DestinationSource>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <FeedExecutionList></FeedExecutionList>
        </TabPanel>
        {/* <TabPanel value={value} index={3}>
          <ScriptRunningReport></ScriptRunningReport>
        </TabPanel> */}
      </Box>

      {/* <TabComponent tabData={tabData}></TabComponent>
      <AddEditDestinationSourceConfig
        ref={childRef}
        name={"Add Feed To Execution List"}
      ></AddEditDestinationSourceConfig> */}
    </>
  );
};
export default ChannelManagement;
