import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],

};

const dataSourceIdData = createSlice({
  name: "dataSourceIdData",
  initialState,
  reducers: {
    updateDataSourceIdData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { updateDataSourceIdData } = dataSourceIdData.actions;
export default dataSourceIdData.reducer;
