import React from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import CountReport from "./component/CountReport/index";
import DayCountReport from "./component/DayCountReport";
import ImportSourceReport from "./component/ImportSourceReport";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Reports = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Count Report" />
            <Tab label="Day Count Report" />
            <Tab label="Import Source Report" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <CountReport />
        </TabPanel>
       
        <TabPanel value={value} index={1}>
          <DayCountReport />
        </TabPanel>
         <TabPanel value={value} index={2}>
          <ImportSourceReport />
        </TabPanel>
      </Box>
      {/* <TabComponent
        tabData={tabData}
        onChange={handlePageChange}
      ></TabComponent>
      <NewSourceOwner defaultValues={rowSelected} ref={childRef}></NewSourceOwner>
      <AddNewSource ref={childRef2} /> */}
    </>
  );
};

export default Reports;
