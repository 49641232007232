import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const dataSourceDetails = createSlice({
  name: "dataSourceDetails",
  initialState,
  reducers: {
    updateDataSourceDetailsTableData: (state, action) => {
      state.tableData = action.payload;
    },
    updateTableRow: (state, action) => {
      const updatedRow = action.payload;
      const index = state.tableData.data.findIndex((data) => {
        return data?._id === updatedRow?._id;
      });
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.tableData.data[index][key] = updatedRow[key];
      });
    },
    addTableRow: (state, action) => {
      state.tableData.data.unshift(action.payload);
    },
    removeTableRow: (state, action) => {
      state.tableData = state.tableData.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});

export const {
  updateDataSourceDetailsTableData,
  updateTableRow,
  addTableRow,
  removeTableRow,
} = dataSourceDetails.actions;
export default dataSourceDetails.reducer;
