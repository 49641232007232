import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const LMReport = createSlice({
  name: "LMReport",
  initialState,
  reducers: {
    addCountReportList: (state, action) => {
      state.data = action.payload;
    },
    updateTableRow: (state, action) => {
      const updatedRow = action.payload;
      const index = state.tableData.data.findIndex(
        (data) => data?.id === updatedRow?.id
      );
      state.tableData.data[index] = updatedRow;
    },
  },
});

export const { addLMReportList,updateTableRow } = LMReport.actions;
export default LMReport.reducer;
