import { Box, Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Icon from "@mdi/react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { mdiCloseCircleOutline } from "@mdi/js";
const CardList = ({ cards, onCardPositionChange, onRemoveCard }) => {
  const [cardList, setCardList] = useState([]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;

    // Get the previous and current positions
    const previousPosition = source.index;
    const currentPosition = destination.index;

    // Reorder the cards in the state
    const updatedCards = Array.from(cardList);
    const [movedCard] = updatedCards.splice(source.index, 1);
    updatedCards.splice(destination.index, 0, movedCard);
    setCardList(updatedCards);

    // Pass the currentPosition to the parent component
    if (typeof onCardPositionChange === "function") {
      onCardPositionChange(result);
    }
  };
  useEffect(() => {
    if (cards.length) {
      setCardList(cards);
    }
  }, [cards]);
  const crossHandle = (index) => {
    const found = cardList.filter((res) => res.id !== index.id);
    setCardList(found);
    onRemoveCard(found);
  };
  // useEffect(() => {
  //   if (cardList.length > 0) {
  //     onRemoveCard(cardList);
  //   }
  // }, [cardList]);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="cardList" direction="horizontal">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ display: "flex" }}
          >
            {cardList.map((card, index) => (
              <Draggable key={card.title} draggableId={card.id} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{ ...provided.draggableProps.style }}
                  >
                    <Card
                      sx={{
                        width: "5.8rem",
                        margin: "2px",
                      }}
                    >
                      <Box
                        sx={{
                          background: "#162b81",
                          color: "#fefefe",
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <div>{index + 1}</div>
                        <div>
                          <Icon
                            path={mdiCloseCircleOutline}
                            title="Remove"
                            onClick={() => crossHandle(card)}
                            size={"20px"}
                            style={{
                              color: "#fefefe",
                              cursor: "pointer",
                              float: "left",
                            }}
                          />
                        </div>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          padding: "20px",
                        }}
                      >
                        {" "}
                        {card.title}
                      </Box>
                    </Card>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default CardList;
