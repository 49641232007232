import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const copyconfigList = createSlice({
  name: "copyconfigList",
  initialState,
  reducers: {
    addCopyConfigListQuery: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { addCopyConfigListQuery } = copyconfigList.actions;
export default copyconfigList.reducer;