import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const ScriptRunningReport = createSlice({
  name: "ScriptRunningReport",
  initialState,
  reducers: {
    addScriptrunningReportList: (state, action) => {
      state.tableData = action.payload;
    },
  },
});

export const { addScriptrunningReportList } = ScriptRunningReport.actions;
export default ScriptRunningReport.reducer;
