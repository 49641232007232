import { combineReducers } from "@reduxjs/toolkit";
import user from "./auth/userSlice";
import config from "./config/configSlice";
import dataSorceOwner from "./DataSourceModule/dataSouceOwnerSlice";
import dataHeader from "./DataSourceModule/DataheadersSlice/index";
import dataSourceDetails from "./DataSourceModule/DataSourceDetailsSlice/index";
import dataSourceOwnerDetails from "./DataSourceModule/DataSourceOwnerSlice/index";
import countReportList from "./DataSourceModule/Reports/countReportSlice";
import regionList from "./DataSourceModule/DataSourceOwnerSlice/regionListSlice";
import sourceOwnerList from "./DataSourceModule/DataSourceOwnerSlice/sourceOwnerListSlice";
import { apiQueryServices } from "@services/apiQueryServices";
import  sourceOwnerNameSlice from "./DataSourceModule/DataSourceOwnerNameSlice"
//export Reducers
import channelList from "./ExportDataModule/ChannelListSlice";
import destinationOwner from "./ExportDataModule/DestinationOwnerSlice";
import destinationSource from "./ExportDataModule/DestinationSourceSlice";
import feedExecutionList from "./ExportDataModule/FeedExecutionListSlice";
import dataCountDetail from "./ExportDataModule/ChannelListSlice/DataCountDetailSlice";
import exportCountReportList from "./ExportDataModule/ReportsSlice/CountReportSlice";
import ownerList from "./ExportDataModule/DestinationSourceSlice/AddEditDestinationSourceSlice"
import feedOwnerList from './ExportDataModule/FeedExecutionListSlice/AddFeedExecutionSlice'
import copyconfigList from "./ExportDataModule/DestinationSourceSlice/AddCopyConfigSlice";
import systemFieldList from "./ExportDataModule/DestinationSourceSlice/AddEditMapHeadersSlice";
import countSourceOwner from "./ExportDataModule/ReportsSlice/CountReportSlice/CountReportSourceOwnerSlice"
import LMReport from "./ExportDataModule/ReportsSlice/LMReportSlice"
import ScriptRunningReport from "./ExportDataModule/ReportsSlice/ScriptRunnigReportSlice"
import feedList from "./ExportDataModule/FeedExecutionListSlice/FeedListSlice"
//Data Store Reducers
import preTextIdList from "./DataStoreModule/PreTextIdSlice"
import sourceIdList from "./DataStoreModule/SourceIdSlice"
import reportDataStoreList from "./DataStoreModule/ReportDataSlice"
//Import Data Reducers
import mapHeaderTableData from "./MapAndImportModule/DataTableSlice"
import dataSourceIdData from "./MapAndImportModule/DataSourceIdSlice"
import headerData from "./MapAndImportModule/HeaderDataSlice"
import rawData from "./MapAndImportModule/RawDataSlice"
//File source reducer
import availableFileGridData from './FileSourceModule/AvailableFilesModule/GridDataSlice'
import importPreTextList from "./FileSourceModule/ImportedFilesModule/ImportPreTextSlice"
import importedDataTablesList from "./FileSourceModule/ImportedDataTableModule/TableGridSlice"
import importedDataFileTablesList from "./FileSourceModule/ImportedDataTableModule/TableGridRowSlice"
const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    user,
    config,
    dataSorceOwner,
    dataSourceOwnerDetails,
    dataHeader,
    dataSourceDetails,
    regionList,
    countReportList,
    sourceOwnerList,
    channelList,
    destinationOwner,
    destinationSource,
    feedExecutionList,
    dataCountDetail,
    exportCountReportList,
    ownerList,
    feedOwnerList,
    copyconfigList,
    systemFieldList,
    exportCountReportList,
    countSourceOwner,
    LMReport,
    ScriptRunningReport,
    feedList,
    preTextIdList,
    sourceIdList,
    mapHeaderTableData,
    dataSourceIdData,
    headerData,
    rawData,
    availableFileGridData,
    importPreTextList,
    importedDataTablesList,
    importedDataFileTablesList,
    reportDataStoreList,
    sourceOwnerNameSlice,
    [apiQueryServices.reducerPath]: apiQueryServices.reducer,
    ...asyncReducers,
  });

  /*
	Reset the redux store when user logged out
	 */
  if (action.type === "user/userLoggedOut") {
    // state = undefined;
  }

  return combinedReducer(state, action);
};

export default createReducer;
