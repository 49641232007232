import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import DatePickerComponent from "../../../../../../components/common/GlobalDatePicker";
import AgGridComponent from "../../../../../../components/common/AgGridComponent";
import CircularProgressLoading from "@components/loaders/CircularProgressLoading";
import CommonFunctions from "../../../../../../utils/CommonFunctions";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import { useDispatch, useSelector } from "react-redux";
import DownloadButton from "../../../../../../components/common/DownloadButton";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../../../services/apiQueryServices";
import { addCountRepportList } from "../../../../../../store/DataSourceModule/Reports/countReportSlice";
import { updateSourceOwnerName } from "../../../../../../store/DataSourceModule/DataSourceOwnerNameSlice";

const CountReport = () => {
  const [columnDef, setColumnDef] = useState([]);
  const [gridData, setGridData] = useState(null);
  const [coloumSelect, setcoloumSelect] = useState([]);
  const [isDisplayGrid, setIsDisplayGrid] = useState(false);
  const [totalData, setTotalData] = useState({});
  const [dataKeys, setDataKeys] = useState([]);
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedSourceOwner, setSelectedSourceOwnerOptions] = useState([]);

  const sourceIdList = useSelector((state) => state.countReportList?.data);
  const sourceOwnerData = useSelector(
    (state) => state.sourceOwnerNameSlice?.data
  );

  const [ImportSourceListItems, ImportSourceListItemsOptions] =
    useApiCallMutation();

  const getSourceOwnerApi = useGetApiCallQuery({
    endPoint: "dl/import/source-owner/list-items",
    method: "GET",
    header: {},
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      source_id: [],
      source_owner_name: "",
      fromDate: dayjs().subtract(1, "day"),
      toDate: dayjs(),
    },
    mode: "all",
  });

  const ownerIdData = watch("source_owner_name");

  useEffect(() => {
    getSourceOwnerApi.refetch();
    if (getSourceOwnerApi.isSuccess && getSourceOwnerApi.data?.data !== null) {
      dispatch(updateSourceOwnerName(getSourceOwnerApi?.data?.data));
    }
  }, [getSourceOwnerApi?.data, getSourceOwnerApi?.isSuccess]);

  useEffect(() => {
    const data = {
      ownerId: ownerIdData?.value ? ownerIdData?.value : "",
    };
    ImportSourceListItems({
      endPoint: `dl/map-and-import/source/list-items`,
      method: "POST",
      data: { data },
    });
  }, [ownerIdData]);

  useEffect(() => {
    dispatchData();
  }, [ImportSourceListItemsOptions]);

  const dispatchData = () => {
    if (ImportSourceListItemsOptions?.isSuccess) {
      dispatch(addCountRepportList(ImportSourceListItemsOptions?.data?.data));
    }
  };

  const [apiCall, res] = useApiCallMutation();

  useEffect(() => {
    if (res.isSuccess) {
      if (Object.entries(res.data).length) {
        setTotalData(res.data.data.output);
        setcoloumSelect(res.data.data?.headers);
      } else if (res.data.length === 0) {
        setTotalData({});
        dispatch(
          snackbarMessageUpdate({
            open: true,
            color: "warning",
            message: "No Data Found",
          })
        );
      }
      setIsDisplayGrid(true);
    } else {
      setIsDisplayGrid(true);
    }
  }, [res]);

 
  const onFormSubmit = (formData) => {
    const data = {
      fromDate: dayjs(formData.fromDate.$d).format("YYYY-MM-DD"),
      toDate: dayjs(formData.toDate.$d).format("YYYY-MM-DD"),
      source_id: selectedOptions.map((item) => item.value),
      ownerId: ownerIdData?.value ? ownerIdData?.value : "",
    };
    apiCall({
      endPoint: `dl/import/report/count`,
      method: "POST",
      data: { data },
    });
  };

  useEffect(() => {
    if (Object.entries(totalData).length) {
      setDataKeys(Object.keys(totalData));
    } else {
      setDataKeys([]);
    }
  }, [totalData]);

  useEffect(() => {
    if (gridData) {
      if (gridData.length) {
        const keys = Object.keys(gridData[0]);
        const column = keys?.map((item, index) => {
          return { field: item, filter: true };
        });
        setColumnDef(column);
      }
    }
  }, [gridData]);

  const csvDownload = () => {
    let data = [];

    for (let key of Object.keys(totalData)) {
      for (let row of totalData[key]?.list) {
        data.push({
          Data_Source_Name: row?.source_name,
          Source_Owner: row?.source_owner,
          Posted_Records: row?.posted_records,
          Records_Imported: row?.success_records,
          Rejected_Non_Dupes_records: row?.rejected_non_dupe,
          Rejected_Dupes_records: row?.dupe_records,
          Total_Dupes_Records: row?.total_dupes,
          New_Records: row?.unique_records,
        });
      }
    }
    CommonFunctions.downloadFile(data);
  };

  return (
    <div className="ag-theme-alpine" style={{ height: "calc(100vh - 235px)" }}>
      <Card>
        <Box
          sx={{
            padding: "20px 30px",
            borderBottom: "1px solid #E0E0E0",
          }}
        >
          <Box
            component={"form"}
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Grid container spacing={4} display={"flex"}>
              <Grid item xs={2}>
                <DatePickerComponent
                  name="fromDate"
                  label="From Date"
                  control={control}
                  minDate={dayjs("2000-01-01")}
                  maxDate={watch("toDate")}
                />
              </Grid>
              <Grid item xs={2}>
                <DatePickerComponent
                  name="toDate"
                  label="Date To"
                  control={control}
                  minDate={watch("fromDate")}
                  maxDate={dayjs()}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name="source_owner_name"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => {
                    const handleChange = (event, selectedOps, reason) => {
                      setSelectedOptions([]);
                      return field.onChange(selectedOps);
                    };
                    return (
                      <Autocomplete
                        options={sourceOwnerData || []}
                        size="small"
                        control={control}
                        defaultValue={[]}
                        onChange={handleChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            label="Source Owner"
                            error={!!errors.source_owner_name}
                            helperText={errors.source_owner_name?.message}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name="source_id"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => {
                    const allSelected =
                      sourceIdList.length === selectedOptions.length;
                    const handleToggleOption = (selectedOps) =>
                      setSelectedOptions(selectedOps);
                    const handleClearOptions = () => setSelectedOptions([]);
                    const handleSelectAll = (isSelected) => {
                      if (isSelected) {
                        setSelectedOptions(sourceIdList);
                      } else {
                        handleClearOptions();
                      }
                    };
                    const handleToggleSelectAll = () => {
                      handleSelectAll(!allSelected);
                    };
                    const handleChange = (event, selectedOps, reason) => {
                      if (
                        reason === "selectOption" ||
                        reason === "removeOption"
                      ) {
                        if (
                          selectedOps.find(
                            (option) => option.label === "Select All"
                          )
                        ) {
                          handleToggleSelectAll();
                          const result = sourceIdList.filter(
                            (el) => el.label !== "Select All"
                          );
                          return field.onChange(result);
                        } else {
                          handleToggleOption(selectedOps);
                          return field.onChange(selectedOps);
                        }
                      } else if (reason === "clear") {
                        handleClearOptions();
                        handleToggleOption(selectedOps);
                        return field.onChange(selectedOps);
                      }
                    };
                    return (
                      <Autocomplete
                        multiple
                        limitTags={1}
                        includeInputInList
                        size="small"
                        id="tags-outlined"
                        value={selectedOptions}
                        disableCloseOnSelect
                        options={sourceIdList}
                        getOptionLabel={(option) => option?.label}
                        filterOptions={(options, params) => {
                          return [
                            { label: "Select All", value: "" },
                            ...options.filter((option) =>
                              option.label
                                .toLowerCase()
                                .includes(params.inputValue.toLowerCase())
                            ),
                          ];
                        }}
                        onChange={handleChange}
                        sx={{
                          width: "100%",
                          mr: 1,
                          "& .MuiAutocomplete-inputRoot": {
                            flexWrap: "nowrap !important",
                            overflow: "hidden",
                          },
                          "& .MuiAutocomplete-endAdornment": {
                            right: "0px !important",
                            background: "#FFFFFF",
                            overflow: "hidden",
                          },
                        }}
                        renderOption={(props, option, { selected }) => {
                          const selectAllProps =
                            option.label === "Select All"
                              ? { checked: allSelected }
                              : {};
                          return (
                            <li {...props}>
                              <Checkbox
                                checked={selected}
                                {...selectAllProps}
                                style={{ marginRight: 8 }}
                              />
                              {option.label}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            label="Data Source List"
                            error={!!errors.source_id}
                            helperText={errors.source_id?.message}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4} display={"flex"} justifyContent={"end"}>
                <Button
                  variant="outlined"
                  type="submit"
                  onClick={handleSubmit(onFormSubmit)}
                  disabled={res.isLoading}
                  sx={{
                    maxHeight: "2.5rem",
                    mr: "10px",
                  }}
                >
                  Generate Report
                </Button>
                <DownloadButton
                  onclick={csvDownload}
                  disabled={Object.entries(totalData).length}
                >
                  Download CSV
                </DownloadButton>
                {/* <Button
                    variant="outlined"
                    onClick={csvDownload}
                    disabled={!Object.entries(totalData).length}
                    sx={{
                      background: Object.entries(totalData).length
                        ? "#4ac24b"
                        : "transparent",
                      color: "#fefefe",
                      maxHeight: "2.5rem",
                      "&:hover": {
                        background: "#1aaa26",
                      },
                    }}
                  >
                    Download CSV
                  </Button> */}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
      {res.isLoading && <CircularProgressLoading />}
      {!res.isLoading &&
        dataKeys.map((key, index) => (
          <>
            <Box
              sx={{
                pt: 3,
              }}
            >
              <Card
                sx={{
                  mb: "10px",
                }}
              >
                <Box
                  container
                  sx={{
                    padding: "20px 30px",
                    borderBottom: "1px solid #E0E0E0",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box item xs={4}>
                    <div style={{ fontSize: "0.9rem" }}>
                      Owner Name :{" "}
                      <b>{totalData[key]?.list[0]?.source_owner}</b>
                    </div>
                  </Box>
                  <Box
                    item
                    xs={8}
                    sx={{
                      float: "left",
                    }}
                  >
                    {/* <div>Details</div> */}
                    <div style={{ fontSize: "0.9rem" }}>
                      Total Records:{" "}
                      <b>
                        {totalData[key]?.sOwner_total_posted_records || "0"}
                      </b>{" "}
                      | Records Imported:{" "}
                      <b>
                        {totalData[key]?.sOwner_total_success_records || "0"}
                      </b>{" "}
                      | Rejected(Non-Dupes):{" "}
                      <b>
                        {totalData[key]?.sOwner_total_rejected_non_dupe || "0"}
                      </b>{" "}
                      | Rejected(Dupes):{" "}
                      <b>{totalData[key]?.sOwner_total_dupe_records || "0"}</b>{" "}
                      | Total(Dupes):{" "}
                      <b>{totalData[key]?.sOwner_total_total_dupes || "0"}</b>|
                      New Records:{" "}
                      <b>
                        {totalData[key]?.sOwner_total_unique_records || "0"}
                      </b>
                    </div>
                  </Box>
                </Box>
              </Card>
              <AgGridComponent
                key={key}
                columnDefs={coloumSelect}
                search={false}
                rowData={totalData[key]?.list}
                domLayout="autoHeight"
                height={"100%"}
                pagination={false}
                floatingFilter={false}
                paginationPageSize={10}
                onRowSelectionChangedHandler={(arg) => console.log(arg)}
                rowMode
              />
            </Box>
            <Divider
              sx={{
                mb: "20px",
                mt: "20px",
              }}
            />
          </>
        ))}
    </div>
  );
};

export default CountReport;
