import React from "react";
import PageHeader from "../../components/ui/pageHeader/PageHeader";
import { Outlet } from "react-router";

const pageHeaderContent = {
  title: "File Source",
  description: "List of File Source and File Headers",
  navButtons: [
    { name: "Available Files", route: "/fileSource/available-files" },
    { name: "Imported Files", route: "/fileSource/imported-files" },
    { name: "Imported Data Tables", route: "/fileSource/imported-data-tables" },
  ],
};
const FileSource = () => {
  return (
    <>
      <PageHeader
        title={pageHeaderContent.title}
        description={pageHeaderContent?.description}
        navButtons={pageHeaderContent?.navButtons}
      />
      <Outlet />
    </>
  );
};

export default FileSource;
