import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const regionList = createSlice({
  name: "regionList",
  initialState,
  reducers: {
    addRegionList: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { addRegionList } = regionList.actions;
export default regionList.reducer;
