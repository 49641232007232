import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const sourceOwnerList = createSlice({
  name: "sourceOwnerList",
  initialState,
  reducers: {
    addSourceOwnerList: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { addSourceOwnerList } = sourceOwnerList.actions;
export default sourceOwnerList.reducer;
