import { Box, Button, Card, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AgGridComponent from "../../../../components/common/AgGridComponent";
import CircularProgressLoading from "../../../../components/loaders/CircularProgressLoading";
import { useDispatch } from "react-redux";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import { updateAvailableFileGridData } from "../../../../store/FileSourceModule/AvailableFilesModule/GridDataSlice";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../services/apiQueryServices";
const AvailableFiles = () => {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState("");
  const [rowData, setRowData] = useState([]);
  const [error, setError] = useState(false);

  const [columnDef, setColumnDef] = useState([]);
  const [selectedCheckBoxRows, setselectedCheckBoxRows] = useState(0);

  const dispatch = useDispatch();

  //Fetching Data from Store
  const GridData = useSelector((state) => state.availableFileGridData.data);

  //calling gridData api
  const GetGridDataApi = useGetApiCallQuery({
    endPoint: "dl/file-source/list-items",
    method: "GET",
    headers: {},
  });

  useEffect(() => {
    GetGridDataApi.refetch();
  }, []);

  useEffect(() => {
    if (GetGridDataApi?.isSuccess && GetGridDataApi?.data?.data !== null) {
      const dataArray = [...GetGridDataApi?.data?.data];
      dataArray.reverse();
      dispatch(updateAvailableFileGridData(dataArray));
    }
  }, [GetGridDataApi]);

  useEffect(() => {
    if (GridData && GridData.length) {
      const keys = Object.keys(GridData[0]);
      const column = keys?.map((item, index) => {
        return { field: item, filter: true };
      });

      column.unshift({
        headerCheckboxSelection: false,
        checkboxSelection: true,
      });

      setColumnDef(column);
    }
  }, [GridData]);

  //api call for import files
  const [importApi, res] = useApiCallMutation();
  useEffect(() => {
    if (res?.isSuccess) {
      successCallback(res?.data);
      GetGridDataApi.refetch();
    }
    setselectedCheckBoxRows(0);
    setInputValue("");
    setRowData([]);
  }, [res]);

  const handleClick = () => {
    const data = {
      preText: inputValue,
      filesList: rowData,
    };

    feedToData(data);
  };

  const feedToData = async (data) => {
    importApi({
      endPoint: `dl/file-source/feed-to-db`,
      method: "POST",
      data: { data },
    });
  };

  const successCallback = (response) => {
    const status = response?.status;
    if (status) {
      dispatch(
        snackbarMessageUpdate({
          open: true,
          color: "success",
          message: status?.value,
        })
      );
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);
    setError(inputValue.startsWith(" "));
  };

  if (GetGridDataApi?.isFetching || res?.isLoading)
    return <CircularProgressLoading />;
  return (
    <Box
      sx={{
        padding: "20px",
      }}
    >
      <Card
        sx={{
          padding: "10px",
          marginBottom: "10px",
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box
            sx={{
              display: "flex",

              [theme.breakpoints.down("lg")]: {
                flexDirection: "column",
              },
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    [theme.breakpoints.down("lg")]: {
                      fontSize: "13px",
                    },
                    // [theme.breakpoints.down("lg")]: {
                    //   fontSize: "13px",
                    // },
                  }}
                >
                  Selected Rows Count :
                </Typography>

                <Typography
                  sx={{
                    color: "#2065D1",
                    ml: 1,
                    [theme.breakpoints.down("lg")]: {
                      fontSize: "13px",
                    },
                  }}
                >
                  {" "}
                  {selectedCheckBoxRows}
                </Typography>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    ml: 4,
                    [theme.breakpoints.down("lg")]: {
                      fontSize: "13px",
                      ml: 1,
                    },
                  }}
                >
                  CSV Files Count :
                </Typography>
                <Typography
                  sx={{
                    color: "#2065D1",
                    ml: 1,
                    [theme.breakpoints.down("lg")]: {
                      fontSize: "13px",
                    },
                  }}
                >
                  {" "}
                  {GridData?.filter((obj) => obj?.extension === "csv").length ||
                    "0"}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    ml: 4,
                    [theme.breakpoints.down("lg")]: {
                      fontSize: "13px",
                      ml: 0,
                    },
                  }}
                >
                  Other Files Count :
                </Typography>

                <Typography
                  sx={{
                    color: "#2065D1",
                    ml: 1,
                    [theme.breakpoints.down("lg")]: {
                      fontSize: "13px",
                    },
                  }}
                >
                  {" "}
                  {GridData?.filter(
                    (obj) =>
                      obj?.extension !== ".zip" &&
                      obj?.extension !== ".rar" &&
                      obj.extension !== ".csv"
                  ).length || "0"}
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    ml: 4,
                    [theme.breakpoints.down("lg")]: {
                      fontSize: "13px",
                    },
                  }}
                >
                  Compressed Files(.zip/.rar) :
                </Typography>

                <Typography
                  sx={{
                    color: "#2065D1",
                    ml: 1,
                    [theme.breakpoints.down("lg")]: {
                      fontSize: "13px",
                    },
                  }}
                >
                  {" "}
                  {GridData?.filter(
                    (obj) =>
                      obj?.extension === ".zip" || obj?.extension === ".rar"
                  ).length || "0"}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              id="outlined-basic"
              label="Table pre-Text"
              variant="outlined"
              size="small"
              sx={{ paddingRight: "25px" }}
              onChange={handleChange}
              error={error}
              helperText={error ? "No starting space allowed" : ""}
            />
            <Button
              onClick={handleClick}
              disabled={
                !(inputValue.trimStart() && Object.keys(rowData).length > 0)
              }
              variant="contained"
            >
              Import Files
            </Button>
          </Box>
        </Box>
      </Card>

      <AgGridComponent
        rowData={GridData}
        columnDefs={columnDef}
        setselectedRows={(arg) => setselectedCheckBoxRows(arg)}
        onRowSelectionChangedHandler={(arg) => setRowData(arg)}
        pagination={true}
        paginationPageSize={10}
      />
    </Box>
  );
};

export default AvailableFiles;
