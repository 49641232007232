import axios from "axios";
import jwtServiceConfig from "./jwtServiceConfig";
import { logoutURL } from "@configs/envConfig";
import { baseURL, idpURL } from "../../configs/envConfig";
import { axiosInstance } from "../axiosInstance";

class JwtService {
  init() {
    this.setInterceptors();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.emit("onAutoLogout", "Invalid access_token");
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  getClientsToken = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(jwtServiceConfig.accessToken, {
          data: {
            access_token: this.getAccessToken(),
          },
        })
        .then((response) => {
          if (response.data.user) {
            this.setSession(response.data.access_token);
            resolve(response.data.user);
          } else {
            this.logout();
            reject(new Error("Failed to login with token."));
          }
        })
        .catch((error) => {
          this.logout();
          reject(new Error("Failed to login with token."));
        });
    });
  };

  callbackgetTokenManagerService = (code) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.getToken, {
          data: {
            redirectUri: "http://127.0.0.1:3000/callback",
            clientName: "datalabs",
            code: code,
          },
        })
        .then((response) => {
          if (response.status.code === 200) {
            resolve(response.data);
          } else {
            this.logout();
            reject(new Error("Failed to login with token."));
          }
        })
        .catch((error) => {
          this.logout();
          reject(new Error("Failed to login with token."));
        });
    });
  };

  updateUserData = (user) => {
    return axios.post(jwtServiceConfig.updateUser, {
      user,
    });
  };

  setSession = (user) => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("userInfo", JSON.stringify(user?.user));
      localStorage.setItem("userPermission", JSON.stringify(user.user));
    } else {
      localStorage.removeItem("user");
      localStorage.removeItem("userPermission");
      delete axiosInstance.defaults.headers.common["Authorization"];
    }
  };

  logout = () => {
    const data = {
      clientName: "datalabs",
    };

    const headers = {
      Authorization: JSON.parse(localStorage.getItem("user"))?.token
        ?.access_token
        ? "Bearer " +
          JSON.parse(localStorage.getItem("user"))?.token?.access_token
        : null,
      "Content-Type": "application/json",
      accept: "application/json",
    };
    axios
      .post(
        `${baseURL}/secured/introspect`,
        {
          data,
        },
        { headers }
      )
      .then((result) => {
        if (result.data.data.active) {
          const id_token = this.getIdToken();
          window.location.href = `${idpURL}/connect/logout?post_logout_redirect_uri=${logoutURL}&id_token_hint=${id_token}`;
        } else {
          this.setSession(null);
          window.location.href = "/login";
        }
      })
      .catch((error) => {
        this.setSession(null);
        window.location.href = "/login";
      });
  };

  isAuthTokenValid = (exp) => {
    if (!exp) {
      return false;
    }
    const currentTime = Date.now() / 1000;
    if (exp < currentTime) {
      console.warn("access token expired");
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem("jwt_access_token");
  };

  getIdToken = () => {
    return JSON.parse(localStorage.getItem("user"))?.token.id_token;
  };
}

const instance = new JwtService();

export default instance;
