import { AgChartsReact } from "ag-charts-react";
import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";

const AgGridColumnChart = ({ data, series, title, footnote }) => {
  const [options, setOptions] = useState(null);

  useEffect(() => {
    setOptions({
      autoSize: true,
      data: data,
      theme: {
        overrides: {
          column: {
            legend: {
              position: "bottom",
            },
            series: {
              strokeWidth: 0,
              highlightStyle: {
                item: {
                  strokeWidth: 1,
                },
              },
            },
          },
        },
      },
      title: {
        text: title,
        fontSize: 18,
        spacing: 25,
      },
      footnote: {
        text: footnote,
      },
      series: series,
      axes: [
        {
          type: "category",
          position: "bottom",
        },
        {
          type: "number",
          position: "left",
          // label: {
          //   formatter: ({ value }) => `${value / 1000}M`,
          // },
        },
      ],
      overlays: {
        noData: {
          text: "No Data To Display",
        },
      },
    });
  }, [data, series, title, footnote]);

  return (
    <>{options && options.data.length && <AgChartsReact options={options} />}</>
  );
};

export default AgGridColumnChart;
