import React, { useRef, useState, useEffect } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import ImportToExportReport from "../Import-to-Export-Report";
import SourceDuplicateReport from "../Source-Duplicate-Report";
import DomainWiseCountReport from "../Domai-wise_count_report";
import SharingOutReport from "../sharing-out_report";
import ImportDataAnalysis from "../import-data-analysis";
import LMReport from "../../../ExportData/components/ExportReports/component/LMReport";

const CustomReport = () => {
  const [value, setValue] = useState(0);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", padding: "10px" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Import Daily Analysis" />
          <Tab label="Import To Export" />
          <Tab label="LM Report" />
          <Tab label="Sharing Out Report" />
          <Tab label="Import / Export Trend" />
          <Tab label="Data Matrix" />
          <Tab label="Data Simulator" />
          <Tab label="Source Wise Count" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ImportDataAnalysis />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ImportToExportReport />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <LMReport />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <SharingOutReport />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Box
          pt={2}
          sx={{
            width: "100%  ",
            height: "100vh",
          }}
        >
          <iframe
            width={"100%"}
            height={"100%"}
            scrolling="no"
            frameBorder="0"
            src="https://reports.whatthestats.com/baec3e5e-5457-4d80-81c2-b0e0df129b74/c6a35833-9505-4108-acc1-75bf1adf64aa"
          ></iframe>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Box
          pt={2}
          sx={{
            width: "100%  ",
            height: "100vh",
          }}
        >
          <iframe
            id="myIframe"
            width={"100%"}
            height={"100%"}
            scrolling="no"
            frameBorder="0"
            src="https://reports.whatthestats.com/2419c74b-df98-4224-9284-2c037c2c4d11/c32783bc-2f6f-4051-af23-654b6162dfa8/all/0/0"
          ></iframe>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Box
          pt={2}
          sx={{
            width: "100%  ",
            height: "100vh",
          }}
        >
          <iframe
            width={"100%"}
            height={"100%"}
            scrolling="no"
            frameBorder="0"
            src="https://reports.whatthestats.com/2419c74b-df98-4224-9284-2c037c2c4d11/979db918-4335-4b72-a596-906602768a45/all/0/0"
          ></iframe>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={7}>
        <Box
          pt={2}
          sx={{
            width: "100%  ",
            height: "100vh",
          }}
        >
          <iframe
            width={"100%"}
            height={"100%"}
            scrolling="no"
            frameBorder="0"
            src="https://reports.whatthestats.com/baec3e5e-5457-4d80-81c2-b0e0df129b74/1e93b7c9-b450-4b5d-a485-b54e85c70bd3/all/0/0"
          ></iframe>
        </Box>
      </TabPanel>
    </Box>
  );
};

export default CustomReport;
