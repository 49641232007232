import React from "react";
import PageHeader from "../../components/ui/pageHeader/PageHeader";
import { Outlet } from "react-router";

const pageHeaderContent = {
  title: "Data Store",
  description: "List of Data Store and Source Wise Count",
  navButtons: [
    // { name: "Data Store", route: "/data-store/data-store-list" },
    // { name: "Source Wise Count", route: "/data-store/source-wise-count" },
  ],
};

const DataStore = () => {
  return (
    <>
      <PageHeader
        title={pageHeaderContent.title}
        description={pageHeaderContent?.description}
        navButtons={pageHeaderContent?.navButtons}
      />
      <Outlet />
    </>
  );
};

export default DataStore;
