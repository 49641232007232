import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import LoginIcon from "@mui/icons-material/Login";
import { Link, useNavigate } from "react-router-dom";
import { baseURL, callBackURL, idpURL } from "@configs/envConfig";

const theme = createTheme();
const CentralOIDC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user"))?.token?.access_token) {
      navigate(-1);
    }
  }, []);
  const [data, setData] = useState({});
  const getClientsHandler = async () => {
    const data = {
      data: {},
    };
    await axios.post(`${baseURL}/core/get-clients`, data).then((response) => {
      const rdata = response.data;
      if (rdata.status.code === 200) {
        const appIs = rdata.data?.find((item) => item.clientId === "datalabs");
        if (appIs) {
          setData(appIs);
        } else {
          alert("App is not there !");
        }
      }
    });
  };
  const getAuthorizationCode = () => {
    return `${idpURL}/oauth2/authorize?response_type=code&client_id=datalabs&scope=openid&redirect_uri=${callBackURL}`;
  };
  useEffect(() => {
    getClientsHandler();
  }, []);
  useEffect(() => {}, [data]);

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh", width: "100vw" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url("images/savedLoginPage.jpg")`,
            backgroundColor: "white",
            backgroundSize: "80%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
        <Grid
          sx={{ paddingTop: "16%" }}
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={1}
          square
        >
          <Box
            sx={{
              my: -20,
              mx: 24,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "100px",
            }}
          >
            <img
              src="https://t3.ftcdn.net/jpg/03/18/92/30/360_F_318923048_286E3RussArGz193YXQpIXYVJ4blRntK.jpg"
              alt=""
            />
            <Typography
              component="h1"
              variant="h5"
              sx={{
                color: "##808080",
                fontSize: "3rem",
                whiteSpace: "nowrap",
              }}
            >
              Easy and secure <br />
              access to your <br />
              content
            </Typography>
            <Typography
              component="h6"
              variant="h6"
              sx={{
                ml: -1,
                color: "#BABABA",
                whiteSpace: "nowrap",
              }}
            >
              See what you can do with Datalabs .
            </Typography>
            <Box sx={{ mt: 2, ml: -28 }}>
              <Button
                componet={Link}
                href={getAuthorizationCode()}
                target="blank"
                fullWidth
                variant="contained"
                sx={{ mt: 1, mb: 1, backgroundColor: "#0060E6", color: "#fff" }}
              >
                login
                <LoginIcon sx={{ ml: 1.5 }} />
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default CentralOIDC;
