import Papa from "papaparse";
class CommonFunctions {
  init() {}

  getDateYMD = (dt) => {
    var date = new Date(dt);
    let YY = date.getFullYear();
    let MM = date.getMonth() + 1;
    MM = MM < 10 ? `0${MM}` : MM;
    let DD = date.getDate();
    DD = DD < 10 ? `0${DD}` : DD;

    return `${YY}-${MM}-${DD}`;
  };

  // JSON to CSV
  ConvertToCSV = (objArray, headerList) => {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "SrNo,";

    for (let index in headerList) {
      row += headerList[index] + ",";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = i + 1 + "";

      for (let index in headerList) {
        let head = headerList[index];

        line += "," + array[i][head];
      }
      str += line + "\r\n";
    }
    return str;
  };

  // Download CSV
  downloadFile = (data, filename = "report") => {
    filename = `${filename}_${this.getDateYMD(new Date().toString())}`;
    let csvData = this.ConvertToCSV(data, Object.keys(data[0]));
    let blob = new Blob(["\ufeff" + csvData], {
      type: "text/csv;charset=utf-8;",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  };

  handleModifiedData = (data, keys) => {
    const resultData = data.map((item, index) => {
      const resultItem = {};
      keys.forEach((key) => {
        if (typeof item[key] === "object") {
          if (Array.isArray(item[key])) {
            resultItem[key] = JSON.stringify(item[key]);
          } else {
            resultItem[key] = [item[key]];
          }
        } else if (item.hasOwnProperty(key)) {
          resultItem[key] = item[key];
        }
      });
      return resultItem;
    });

    return resultData;
  };
  // Download CSV
  downloadTableData = (data, keys, filename = "report") => {
    filename = `${filename}_${this.getDateYMD(new Date().toString())}`;
    const mdata = this.handleModifiedData(data, keys);
    const csv = Papa.unparse(mdata);
    // let csvData = this.ConvertToCSV(data, keys);
    let blob = new Blob(["\ufeff" + csv], {
      type: "text/csv;charset=utf-8;",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  };
}

const instance = new CommonFunctions();

export default instance;
