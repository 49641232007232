import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


const initialState = {
  progressLoader: false,
  snackbarMessage: {
    open: false,
    color: 'info',
    message: '',
    vertical: 'top',
    horizontal: 'right',
  }
}

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    configStateUpdate: (state, action) => {
      state = {...state,...action.payload}
    },

    snackbarMessageUpdate: (state, action) => {
      state.snackbarMessage = {...state.snackbarMessage, ...action.payload} 
    },
  },
  extraReducers: {
  },
});

export const { configStateUpdate, snackbarMessageUpdate } = configSlice.actions;


export default configSlice.reducer;
