import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],

};

const importedDataTablesList = createSlice({
  name: "importedDataTablesList",
  initialState,
  reducers: {
    updateImportedDataTablesList: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { updateImportedDataTablesList } = importedDataTablesList.actions;
export default importedDataTablesList.reducer;
