import React, { useState, useEffect } from "react";
import AgGridComponent from "../../../../components/common/AgGridComponent";
import CircularProgressLoading from "../../../../components/loaders/CircularProgressLoading";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import { useDispatch } from "react-redux";
import { Box, Button, Card, Grid } from "@mui/material";
import { axiosInstance } from "@services/axiosInstance";
import { baseURL } from "@configs/envConfig";
import DatePickerComponent from "../../../../components/common/GlobalDatePicker";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";

import { useApiCallMutation } from "../../../../services/apiQueryServices";

const ImportToExportReport = () => {
  const [columnDef, setColumnDef] = useState([]);
  const [gridData, setGridData] = useState(null);
  const [coloum, setColoum] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const dispatch = useDispatch();

  const [apiCallMutation] = useApiCallMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      sourceOwner: "",
      fromDate: dayjs().subtract(1, "day"),
      toDate: dayjs(),
    },
    mode: "all",
  });

  const successCallback = (response) => {
    const status = response.data.status;
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "success",
        message: status.value,
      })
    );
  };
  const errorCallback = (error) => {
    setIsLoading(false);
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "error",
        message: error.response.data.status.value
          ? error.response.data.status.value
          : error?.message,
      })
    );
  };

  useEffect(() => {
    if (gridData && gridData.length) {
      const keys = Object.keys(gridData[0]);
      const column = keys?.map((item, index) => {
        return { field: item, filter: true };
      });

      setColumnDef(column);
    }
  }, [gridData]);

  const onFormSubmit = async (formData) => {
    setIsLoading(true);
    const data = {
      fromDate: dayjs(formData.fromDate.$d).format("YYYY-MM-DD"),
      toDate: dayjs(formData.toDate.$d).format("YYYY-MM-DD"),
    };

    // axiosInstance
    //   .post(`${baseURL}/dl/report/import-to-export`, { data })
    //   .then((result) => {
    //     if (result?.data?.data) {
    //       setGridData(result?.data?.data);
    //     } else {
    //       dispatch(
    //         snackbarMessageUpdate({
    //           open: true,
    //           color: "warning",
    //           message: "No Data Found",
    //         })
    //       );
    //     }
    //     setIsLoading(false);
    //   })
    //   .catch(errorCallback);

    try {
      const result = await apiCallMutation({
        endPoint: `${baseURL}/dl/report/import-to-export`,
        method: "POST",
        data: { data },
      });
      // debugger;
      if (result?.data?.data) {
        setGridData(result?.data?.data);
      } else {
        dispatch(
          snackbarMessageUpdate({
            open: true,
            color: "warning",
            message: "No Data Found",
          })
        );
      }
      setIsLoading(false);
    } catch (error) {
      errorCallback();
    }
  };

  if (isLoading) return <CircularProgressLoading />;

  return (
    <div className="ag-theme-alpine" style={{ height: "calc(100vh - 235px)" }}>
      <Card>
        <Box
          sx={{
            padding: "20px 30px",
            borderBottom: "1px solid #E0E0E0",
          }}
        >
          <Box
            component={"form"}
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Grid container spacing={4} display={"flex"}>
              <Grid item xs={2}>
                <DatePickerComponent
                  name="fromDate"
                  label="From Date"
                  control={control}
                  minDate={dayjs("2000-01-01")}
                  maxDate={watch("toDate")}
                />
              </Grid>
              <Grid item xs={2}>
                <DatePickerComponent
                  name="toDate"
                  label="Date To"
                  control={control}
                  minDate={watch("fromDate")}
                  maxDate={dayjs()}
                />
              </Grid>
              <Grid item xs={8} display={"flex"} justifyContent={"end"}>
                <Button
                  variant="outlined"
                  type="submit"
                  onClick={handleSubmit(onFormSubmit)}
                  // disabled={watch("sourceOwner").length ? false : true}
                  sx={{
                    maxHeight: "2.5rem",
                    mr: "10px",
                  }}
                >
                  Generate Report
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
      <Box
        sx={{
          padding: "20px",
        }}
      >
        <AgGridComponent
          rowData={gridData}
          columnDefs={columnDef}
          onRowSelectionChangedHandler={(arg) => {
            setIsEditMode(true);
          }}
          pagination={true}
          paginationPageSize={10}
          rowMode
        />
        ;
      </Box>
    </div>
  );
};

export default ImportToExportReport;
