import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const countSourceOwner = createSlice({
  name: "countSourceOwner",
  initialState,
  reducers: {
    addCountSourceOwner: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { addCountSourceOwner } = countSourceOwner.actions;
export default countSourceOwner.reducer;