import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Checkbox,
} from "@mui/material";
import { useForm } from "react-hook-form";
import GlobalDropdown from "@components/common/GlobalDropedown";
import DatePickerComponent from "../../../../components/common/GlobalDatePicker";
import dayjs from "dayjs";
import { axiosInstance } from "@services/axiosInstance";
import { baseURL } from "@configs/envConfig";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AgGridComponent from "../../../../components/common/AgGridComponent";
import AgGridColumnChart from "../../../../components/common/AgGridGroupColoumChart";
import CircularProgressLoading from "@components/loaders/CircularProgressLoading";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import { useDispatch } from "react-redux";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../services/apiQueryServices";
import { useSelector } from "react-redux";
import { updateSourceIdList } from "../../../../store/DataStoreModule/SourceIdSlice";
import { updatePreTextIdList } from "../../../../store/DataStoreModule/PreTextIdSlice";
import { updateReportDataStoreList } from "../../../../store/DataStoreModule/ReportDataSlice";

const filterList = [
  { label: "Source Id", value: "source_id" },
  { label: "Pre-Text", value: "prefix" },
];

const reportsList = [
  { label: "Count Report", value: "countReport" },
  { label: "Email Domain", value: "emailDomain" },
  { label: "Group By Count", value: "groupByCount" },
];

const validationSchema = Yup.object().shape({
  filter: Yup.string().required("This Field is required"),
  prefix: Yup.string().required("This Field is required"),
});

const DataStorePage = () => {
  const [columnDef, setColumnDef] = useState([]);
  const [gridData, setGridData] = useState(null);
  const [isErrorHeaders, setIsErroHeaders] = useState(false);
  // const [sourceIdList, setSourceIdList] = useState([]);
  // const [preTextList, setPreTextList] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [countReport, setCountReport] = useState("");
  const [coloumSelect, setcoloumSelect] = useState([]);
  const [columnList, setColumnList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [isGridDisplay, setIsGridDisplay] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [dialogRowSelected, setDialogRowSelected] = useState({});
  const [dialogColumnSelected, setDialogColumnSelected] = useState({});
  const [open, setOpen] = React.useState(false);
  const [seriesData, setSeriesData] = useState([]);
  const dispatch = useDispatch();
  //Autocomplete AllSelect
  const [selectedOption, setSelectedOption] = useState([]);
  const allSelected = columnList.length === selectedOption.length;
  const handleToggleOption = (selectedOps) => setSelectedOption(selectedOps);
  const handleClearOptions = () => setSelectedOption([]);
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedOption(columnList);
    } else {
      handleClearOptions();
    }
  };
  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };
  //useform
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      filter: "",
      prefix: "",
      dateFilter: true,
      fromDate: dayjs().subtract(1, "day"),
      toDate: dayjs(),
    },
    resolver: yupResolver(validationSchema),
    mode: "all",
  });
  //Fetching Data from Store
  const SourceIdList = useSelector((state) => state.sourceIdList.data);
  const PreTextList = useSelector((state) => state.preTextIdList.data);
  // const ReportList = useSelector((state) => state.reportDataStoreList.data);

  //Calling Apis
  const { data, refetch, isError, isFetching, isSuccess } = useGetApiCallQuery({
    endPoint: "dl/map-and-import/source/list-items",
    method: "POST",
    data: { data: { ownerId: "" } },
  });
  const getPreTextApi = useGetApiCallQuery({
    endPoint: "dl/data-store/pre-text/list-items",
    method: "GET",
    headers: {},
  });
  useEffect(() => {
    refetch();
    if (isSuccess && data?.data !== null) {
      dispatch(updateSourceIdList([...data?.data]));
    }
  }, [data, isSuccess]);

  useEffect(() => {
    getPreTextApi?.refetch();
    if (getPreTextApi?.isSuccess && getPreTextApi?.data?.data !== null) {
      dispatch(updatePreTextIdList([...getPreTextApi?.data?.data]));
    }
  }, [getPreTextApi?.data, getPreTextApi?.isSuccess]);

  //get data api call
  const [submitApiCall, response] = useApiCallMutation();

  useEffect(() => {
    if (response?.isSuccess && response?.data?.data) {
      setGridData([...response?.data?.data?.data].reverse());
      setTotalCount(response?.data?.data?.count);

      setIsGridDisplay(true);
    } else {
      setGridData([]);
      setTotalCount("");
    }
  }, [response?.isSuccess, response?.data]);

  const onFormSubmit = async (formData) => {
    setIsLoading(true);
    let data = {};
    if (formData.filter == "source_id") {
      data = {
        filter: formData?.filter,
        source_id: formData?.prefix,
        dateFilter: true,
        fromDate: dayjs(formData?.fromDate.$d).format("YYYY-MM-DD"),
        toDate: dayjs(formData?.toDate.$d).format("YYYY-MM-DD"),
      };
    } else if (formData?.filter == "prefix") {
      data = {
        filter: formData?.filter,
        prefix: formData?.prefix,
        dateFilter: true,
        fromDate: dayjs(formData?.fromDate.$d).format("YYYY-MM-DD"),
        toDate: dayjs(formData?.toDate.$d).format("YYYY-MM-DD"),
      };
    }

    setFilterData(data);

    submitApiCall({
      endPoint: `dl/data-store/filter-data/details`,
      method: "POST",
      data: { data },
    });
  };

  useEffect(() => {
    if (gridData && gridData.length) {
      const keys = Object.keys(gridData[0]);
      const column = keys?.map((item, index) => {
        return { field: item, filter: true };
      });
      setColumnDef(column);
      const coloumList = keys
        .map((col) => {
          if (col === "created" || col === "remark") {
            return null;
          }
          return { label: col, value: col };
        })
        .filter(Boolean);
      setColumnList(coloumList);
    }
  }, [gridData]);

  //generatereport api call
  const [reportApiCall, res] = useApiCallMutation();

  useEffect(() => {
    if (res?.isSuccess && res.data?.data?.data !== null) {
      // dispatch(updateReportDataStoreList(res.data?.data?.data));
      setReportData(res?.data?.data.data);
      setCountReport("");
      setcoloumSelect([]);
    }
  }, [res]);

  const generateReport = async () => {
    let data = {
      filter: filterData,
    };
    if (countReport === "countReport") {
      data.cols = coloumSelect.map((item) => item.value);
      setTitle("Initial Count and Unique-Count Report");
    } else if (countReport === "emailDomain") {
      data.emailField = coloumSelect[0].value;
      setTitle("Email Domain Count Report");
    } else if (countReport === "groupByCount") {
      data.field = coloumSelect[0].value;
      setTitle("Group By Count Report");
    }
    reportApiCall({
      endPoint: `dl/data-store/filter-data/count-report`,
      method: "POST",
      data: { data },
    });
    // await axiosInstance
    //   .post(`${baseURL}/dl/data-store/filter-data/count-report`, { data })
    //   .then((res) => {
    //     setIsLoading(false);
    //     if (res?.data?.data) {
    //       setReportData(res?.data?.data.data);
    //     }
    //   })
    //   .catch((error) => {
    //     errorCallback(error);
    //     setCountReport("");
    //     setcoloumSelect([]);
    //     setIsLoading(false);
    //   });
  };

  const handleClose = () => {
    setOpen(false);
    setCountReport("");
    setcoloumSelect([]);
  };

  useEffect(() => {
    if (reportData.length) {
      if (reportData[0]["email_domain"]) {
        setSeriesData([{ type: "column", xKey: "email_domain", yKey: "cnt" }]);
      } else {
        if (Object.keys(reportData[0]).length === 3) {
          setSeriesData([
            { type: "column", xKey: "field", yKey: "cnt" },
            { type: "column", xKey: "field", yKey: "uniq_cnt" },
          ]);
        } else {
          setSeriesData([{ type: "column", xKey: "field", yKey: "cnt" }]);
        }
      }
      setOpen(true);
    }
  }, [reportData]);

  const resetDataStore = () => {
    reset();
    setColumnDef([]);
    setGridData(null);
    setTotalCount("");
    setCountReport("");
    setcoloumSelect([]);
    setColumnList([]);
    setFilterData([]);
    setIsGridDisplay(false);
    setTitle("");
    setIsLoading(false);
    setReportData([]);
    setDialogRowSelected({});
    setDialogColumnSelected({});
    setOpen(false);
    setSeriesData([]);
  };

  const successCallback = (response) => {
    const status = response.data.status;
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "success",
        message: status.value,
      })
    );
  };
  const errorCallback = (error) => {
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "error",
        message: error.response.data.status.value,
      })
    );
  };

  return (
    <>
      <div
        className="ag-theme-alpine"
        style={{ height: "calc(100vh - 235px)" }}
      >
        <Box sx={{ padding: "10px" }}>
          <Card>
            <Box
              sx={{
                padding: "20px 15px",
                borderBottom: "1px solid #E0E0E0",
              }}
            >
              <Box
                component={"form"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <GlobalDropdown
                      options={filterList}
                      control={control}
                      name="filter"
                      label="Select Pre-text"
                      error={!!errors.filter}
                      helperText={errors.filter?.message}
                      required
                      size="small"
                    />
                  </Grid>
                  {watch("filter") !== "" && (
                    <Grid item xs={2}>
                      <GlobalDropdown
                        options={
                          watch("filter") === "source_id"
                            ? SourceIdList
                            : PreTextList
                        }
                        control={control}
                        name="prefix"
                        label={
                          watch("filter") === "source_id"
                            ? "Select Source Id"
                            : "Select Pre Text"
                        }
                        error={!!errors.prefix}
                        helperText={errors.prefix?.message}
                        required
                        size="small"
                      />
                    </Grid>
                  )}
                  <Grid item xs={2}>
                    <DatePickerComponent
                      name="fromDate"
                      label="From Date"
                      control={control}
                      minDate={dayjs("2000-01-01")}
                      maxDate={watch("toDate")}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <DatePickerComponent
                      name="toDate"
                      label="Date To"
                      control={control}
                      minDate={watch("fromDate")}
                      maxDate={dayjs()}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      sx={{
                        float: "left",
                        height:"39px",
                      }}
                      variant="outlined"
                      type="submit"
                      onClick={handleSubmit(onFormSubmit)}
                      // disabled={watch("preText") ? false : true}
                    >
                      Get Data
                    </Button>

                    <Button
                      variant="outlined"
                      type="submit"
                      sx={{
                        color: "#f59e0b",
                        marginLeft: "10px",
                        float: "left",
                        height:"39px"
                      }}
                      onClick={resetDataStore}
                      // disabled={watch("preText") ? false : true}
                    >
                      Clear Filters
                    </Button>
                  </Grid>
                  {/* <Grid item xs={2}></Grid> */}
                </Grid>
              </Box>
            </Box>
          </Card>

          {(response?.isLoading || res?.isLoading) && (
            <CircularProgressLoading />
          )}
          {isGridDisplay && !response?.isLoading && !res?.isLoading && (
            <Box
              sx={{
                pt: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Total Count: {totalCount}
                    </Typography>
                  </Grid>
                  {totalCount != "0" && (
                    <Grid
                      item
                      xs={6}
                      sx={{ textAlign: "right", display: "flex" }}
                    >
                      {/* <Autocomplete
                        multiple
                        disablePortal
                        size="small"
                        id="combo-box-demo"
                        options={columnList}
                        value={coloumSelect}
                        onChange={(e, val) => setcoloumSelect(val)}
                        sx={{ width: 400, mr: 1 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Select Column" />
                        )}
                      /> */}
                      <Box></Box>

                      <Autocomplete
                        multiple
                        options={columnList}
                        limitTags={2}
                        includeInputInList
                        size="small"
                        value={coloumSelect}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.label}
                        onChange={(e, val) => {
                          if (val?.length > 5) {
                            setIsErroHeaders(true);
                          } else {
                            setIsErroHeaders(false);
                          }
                          setcoloumSelect(val);
                        }}
                        sx={{
                          width: "50%",
                          mr: 1,
                          "& .MuiAutocomplete-inputRoot": {
                            flexWrap: "nowrap !important",
                            overflow: "hidden",
                          },
                          "& .MuiAutocomplete-endAdornment": {
                            right: "10px !important",
                            background: "#ffffff",
                            overflow: "hidden",
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={isErrorHeaders}
                            helperText={
                              isErrorHeaders
                                ? "You can only select five fields."
                                : ""
                            }
                            label="Headers"
                            // InputLabelProps={{ shrink: true }}
                            // placeholder="Favorites"
                          />
                        )}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              // icon={Icon}
                              // checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.label}
                          </li>
                        )}
                        // style={{ width: 500 }}
                      />
                      {/* <Autocomplete
                        multiple
                        options={columnList}
                        limitTags={2}
                        size="small"
                        // value={selectedOption}
                        value={coloumSelect}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.label}
                        onChange={(e, val, reason) => {
                          if (val.length > 0) {
                            if (val[0].label === "Select All") {
                              setcoloumSelect([...columnList]);
                            } else {
                              setcoloumSelect(val);
                            }
                          } else {
                            setcoloumSelect([]);
                          }

                          if (
                            reason === "selectOption" ||
                            reason === "removeOption"
                          ) {
                            if (
                              val.find(
                                (option) => option.label === "Select All"
                              )
                            ) {
                              handleToggleSelectAll();
                            } else {
                              handleToggleOption && handleToggleOption(val);
                            }
                          } else if (reason === "clear") {
                            handleClearOptions && handleClearOptions();
                          }
                        }}
                        filterOptions={(options, params) => {
                          return [
                            { label: "Select All", value: "" },
                            ...options,
                          ];
                        }}
                        sx={{
                          width: "50%",
                          mr: 1,
                          "& .MuiAutocomplete-inputRoot": {
                            flexWrap: "nowrap !important",
                            overflow: "hidden",
                          },
                          "& .MuiAutocomplete-endAdornment": {
                            right: "0px !important",
                            background: "#ffffff",
                          },
                          // "& .MuiAutocomplete-popper": {
                          //   maxHeight: "20px", // Adjust the maximum height as needed
                          // },
                        }}
                        renderOption={(props, option, { selected }) => {
                          const selectAll =
                            option.label === "Select All"
                              ? { checked: allSelected }
                              : {};
                          return (
                            <li {...props}>
                              <Checkbox
                                checked={selected}
                                {...selectAll}
                                style={{ marginRight: 8 }}
                              />
                              {option.label}
                            </li>
                          );
                        }}
                        // style={{ width: 500 }}
                        renderInput={(params) => (
                          <TextField
                            //  height={30}
                            {...params}
                            label="Headers"
                            // placeholder="Favorites"
                          />
                          // </div>
                        )}
                      /> */}
                      <Autocomplete
                        disablePortal
                        size="small"
                        id="combo-box-demo"
                        value={countReport}
                        options={reportsList}
                        sx={{ width: 180, mr: 1 }}
                        onChange={(e, val) => setCountReport(val?.value)}
                        renderInput={(params) => (
                          <TextField {...params} label="Report List" />
                        )}
                      />
                      <Button
                        onClick={generateReport}
                        variant="contained"
                        disabled={
                          !isErrorHeaders && countReport && coloumSelect.length
                            ? false
                            : true
                        }
                        sx={{
                          whiteSpace: "nowrap",
                          maxHeight: "2.5rem",
                        }}
                      >
                        Generate Report
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>

              <AgGridComponent
                columnDefs={columnDef}
                rowData={gridData}
                pagination={true}
                paginationPageSize={10}
                onRowSelectionChangedHandler={(arg) => console.log(arg)}
                rowMode
              />
            </Box>
          )}

          <div>
            <Dialog
              fullScreen={fullScreen}
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
              maxWidth={"md"}
              fullWidth={true}
            >
              <DialogTitle id="responsive-dialog-title">
                {countReport === "countReport" && "Count Report"}
                {countReport === "emailDomain" && "Email Domain"}
                {countReport === "groupByCount" && "Group By Count"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <AgGridColumnChart
                    data={reportData}
                    series={seriesData}
                    title={title}
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                {/* <Button onClick={handleClose} autoFocus>
              Agree
            </Button> */}
              </DialogActions>
            </Dialog>
          </div>
        </Box>
      </div>
    </>
  );
};

export default DataStorePage;
