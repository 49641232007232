import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router";
// import { useHistory } from 'react-router-dom';


const NavCard = (props) => {
  //const history = useHistory();

  const navigate = useNavigate();
  const handleNavigation = (route) => {
    if (props?.title === "Raise an issue" && props?.route) {
      window.open('https://coda.io/form/Tech-Support-Ticket_dNkR-7Dz_g2', "_blank");
    } else if (route) {
      navigate(route);
    }
  };

  const CardSX = {
    display: "flex",
    justifyContent: "space-around",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.08)",
    cursor: 'pointer',
    padding:'2px 0',
    "&:hover": {
      border: "1px solid #b8c7ff",
      boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.20)",
      padding:0,
    },
  }
  
  return (
   
    <Card
      sx={CardSX}
      elevation={1}
      onClick={() => handleNavigation(props?.route)}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography
            component="div"
            variant="h5"
            sx={{ fontWeight: 500, fontSize: "20px", color: "#181B20" }}
          >
            {props?.title}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {props?.content}
          </Typography>
        </CardContent>
        <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}></Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
        <CardMedia
          component="img"
          sx={{ width: 50 }}
          image={props?.icon}
          alt="Live from space album cover"
        />
      </Box>
    </Card>
  );
};

export default NavCard;
