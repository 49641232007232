import React, { useCallback, useEffect, useState } from "react";
import AgGridComponent from "../../../../../../components/common/AgGridComponent";
import { baseURL } from "@configs/envConfig";
import Icon from "@mdi/react";
import {
  mdiPencil,
  mdiDelete,
  mdiCog,
  mdiSetCenter,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiFile,
  mdiPoll,
  mdiClose,
} from "@mdi/js";
import {
  Box,
  Button,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CircularProgressLoading from "../../../../../../components/loaders/CircularProgressLoading";
import NewSourceOwner from "../../../../../../Models/AddNewSourceOwner";
import AddEditDestinationSource from "../../../../../../Models/addDestinationSourceDetails/AddEditDestinationSource";
import AddEditDestinationSourceConfig from "../../../../../../Models/addDestinationSourceConfig/AddEditDestinationSourceConfig";
import { useDispatch } from "react-redux";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import AddDestSourceMappedHeaders from "../../../../../../Models/AddDestinationSourceMappedHeaders";
import GlobalConfirmationPopup from "../../../../../../components/common/GlobalConfirmationPopup";
import { axiosInstance } from "@services/axiosInstance";
import AddButton from "../../../../../../components/common/AddButton";
import { useSelector } from "react-redux";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../../../services/apiQueryServices";
import {
  updateDestinationSource,
  updateTableRow,
} from "../../../../../../store/ExportDataModule/DestinationSourceSlice";

import BarChart from "../../../../../../components/BarChart/BarChart";

const DestinationSource = () => {
  const [rowSelected, setRowSelected] = useState({});
  const [columnDef, setColumnDef] = useState([]);
  const [openConfig, setOpenConfig] = useState(false);
  const [OpenMapHeaders, setOpenMapHeaders] = useState(false);
  const [open, setOpen] = useState(false);
  const [gridData, setGridData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isInactive, setIsInactive] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const dispatch = useDispatch();
  const [apiCallMutation] = useApiCallMutation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [dialogOpen, setDialogOpen] = useState(false);
  const [bardata, setbardata] = useState();
  const [userId, setUserId] = useState();

  const TableData = useSelector((state) => state.destinationSource?.tableData);

  const { data, refetch, isError, isFetching, isLoading, isSuccess } =
    useGetApiCallQuery({
      endPoint: "dl/export/data-source/details",
      method: "POST",
      data: {
        query: {},
        options: {
          page: 1,
          limit: 10,
          pagination: false,
          options: {},
        },
      },
      headers: {},
    });

  const [apiCall1, res2] = useApiCallMutation();
  const [apiCall3, res3] = useApiCallMutation();

  useEffect(() => {
    refetch();
  }, [openConfig]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (res2.isSuccess) {
      successCallback(res2?.data);
      dispatch(updateTableRow(res2?.data?.data));
    }
    if (res3.isSuccess) {
      successCallback(res3?.data);
      dispatch(updateTableRow(res3?.data?.data));
    }
  }, [res2, res3]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    dispatchData();
  }, [data, isFetching, isSuccess]);

  const dispatchData = useCallback(() => {
    if (isSuccess) {
      const tableData = {
        data: [...data?.data?.data].reverse(),
        headers: [...data?.data?.headers],
      };

      tableData.headers.splice(18, 0, {
        field: "Last 7 Days",
        pinned: "right",
        cellRenderer: (params) => {
          const totalxData = params.data?.miniGraph?.series[0]?.data || []; 
          if (datacal(totalxData)) {
            return (
              <div
                onClick={() => barClick(params.data)}
                style={{
                  marginLeft: "10px",
                  fontStyle: "italic",
                  color: "#f57d10",
                }}
              >
                No data in last 7 Days{" "}
              </div>
            );
          }
          return (
            <div onClick={() => barClick(params.data)}>
              <BarChart
                xdata={params.data.miniGraph.xaxis || []}
                ydata={params.data.miniGraph.series[0].data || []}
                showD={false}
              />
            </div>
          );
        },
      });

      // tableData.headers.splice(19, 0, {
      //   field: "Last 30 Days",
      //   cellRenderer: actionBarchart,
      // });

      dispatch(updateDestinationSource(tableData));
    }
  }, [isSuccess, data]);

  // const actionBarchart = (params) => {
  //   return (
  //     <div style={{ textAlign: "center", marginTop: "0.3rem" }}>
  //       <Icon
  //         path={mdiPoll}
  //         title="API Doc"

  //         size={1}
  //         style={{
  //           color: "#1976d2",
  //           cursor: "pointer",
  //         }}
  //       />
  //     </div>
  //   );
  // };
  function datacal(data) {
    let add = 0;
    for (let i = 0; i < data.length; i++) {
      add = add + data[i];
    }
    if (add == 0) return true;
    return false;
  }

  const barClick = async (params) => {
    const id = params.sourceId;
    setUserId(id);

    const source_id = {
      data: {
        sourceId: id,
      },
    };

    try {
      const response = await apiCallMutation({
        endPoint: "dl/export/graph/last-30-days-report",
        method: "POST",
        data: source_id,
      });

      if (response.data) {
        setbardata(response.data.data);
      }
    } catch (error) {
      setbardata();
      console.log(error);
    }
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setbardata();
  };

  // console.log(TableData.headers);

  const successCallback = (response) => {
    const status = response?.status;
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "success",
        message: status.value,
      })
    );
  };

  const errorCallback = (error) => {
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "error",
        message: error.response.data.status.value,
      })
    );
  };

  const headers = {
    "Content-Type": "application/json", // Example header
    Authorization: `Bearer ${
      JSON.parse(localStorage.getItem("user"))?.token.access_token
    }`, // Example header
  };

  const handleFeedExecutionStatus = (params) => {
    return (
      <div style={{ textAlign: "center", marginTop: "0.3rem" }}>
        {params.data.feedExecutionStatus && (
          <Icon
            path={mdiCheckCircleOutline}
            size={1}
            style={{
              color: "Green",
              cursor: "pointer",
            }}
          />
        )}
        {!params.data.feedExecutionStatus && (
          <Icon
            path={mdiCloseCircleOutline}
            size={1}
            style={{
              color: "red",
              cursor: "pointer",
            }}
          />
        )}
      </div>
    );
  };

  const handleActions = (data, action) => {
    switch (action) {
      case "EDIT":
        setRowSelected(data);
        setIsEditMode(true);
        setOpen(true);
        break;
      case "ACTIVE":
        setRowSelected(data);
        if (data.isActive) {
          setIsInactive(true);
          setPopupTitle("Are you sure you want to Inactive?");
          setShowConfirmation(true);
        } else if (!data.isActive) {
          setIsActive(true);
          setPopupTitle("Are you sure you want to Active?");
          setShowConfirmation(true);
        }
        break;
      case "DELETE":
        setRowSelected(data);
        setIsDelete(true);
        setPopupTitle("Are you sure you want to Delete?");
        setShowConfirmation(true);
        break;
      case "CONFIG":
        setRowSelected(data);
        setIsEditMode(true);
        setOpenConfig(true);
        break;
      case "MAPHEADER":
        setRowSelected(data);
        setIsEditMode(true);
        setOpenMapHeaders(true);
        break;
      default: {
      }
    }
  };

  const openForm = () => {
    setOpen(true);
    setIsEditMode(false);
    setRowSelected({});
  };

  const handleConfirm = (remark) => {
    if (isActive) {
      setIsActive(false);
      const data = {
        remark: remark,
        isActive: true,
      };
      apiCall1({
        endPoint: `dl/export/data-source/active-inactive/${rowSelected?._id}`,
        method: "PUT",
        data: { data },
      });
    } else if (isInactive) {
      setIsInactive(false);
      const data = {
        remark: remark,
        isActive: false,
      };
      apiCall1({
        endPoint: `dl/export/data-source/active-inactive/${rowSelected?._id}`,
        method: "PUT",
        data: { data },
      });
    } else if (isDelete) {
      setIsDelete(false);
      const data = {
        data: {
          remark: remark,
        },
      };
      apiCall3({
        endPoint: `dl/export/data-source/delete/${rowSelected?.sourceId}`,  
        method: "DELETE",
        data: { data },
        headers: { headers },
      });
    }
    setShowConfirmation(false);
  };
  const handleCancel = (data) => {
    setShowConfirmation(false);
  };

  if (isLoading) return <CircularProgressLoading />;
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 140,
          right: 16,
        }}
      >
        <AddButton onclick={openForm}>Add New Destination Source </AddButton>
      </Box>
      {open && (
        <AddEditDestinationSource
          defaultValues={rowSelected}
          isOpen={open}
          setOpen={setOpen}
          loadData={refetch}
          isEditMode={isEditMode}
        ></AddEditDestinationSource>
      )}
      {openConfig && (
        <AddEditDestinationSourceConfig
          defaultValues={rowSelected}
          isOpen={openConfig}
          setOpen={setOpenConfig}
          loadData={refetch}
          isEditMode={isEditMode}
        />
      )}
      {OpenMapHeaders && (
        <AddDestSourceMappedHeaders
          defaultValues={rowSelected}
          isOpen={OpenMapHeaders}
          setOpen={setOpenMapHeaders}
          // loadData={loadData}
          isEditMode={isEditMode}
        />
      )}
      <GlobalConfirmationPopup
        open={showConfirmation}
        title={popupTitle}
        // message="Are you sure you want to proceed?"
        onConfirm={handleConfirm}
        onClose={handleCancel}
      />

      <Dialog
        fullScreen={fullScreen}
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="responsive-dialog-title"
        maxWidth={"xl"}
        fullWidth={true}
      >
        <DialogTitle id="responsive-dialog-title">
          Last 30 Days Data of {userId}
          <Icon
            path={mdiClose}
            title="Close"
            onClick={() => handleCloseDialog()}
            size={1}
            style={{
              color: "#000",
              cursor: "pointer",
              marginLeft: "80%",
            }}
          />
        </DialogTitle>
        <DialogContent>
          <BarChart
            xdata={bardata?.xaxis || []}
            ydata={bardata?.series[0].data || []}
            showD={true}
          />
        </DialogContent>
      </Dialog>

      <AgGridComponent
        rowData={TableData?.data || []}
        columnDefs={TableData?.headers || []}
        onRowSelectionChangedHandler={(arg) => {
          setIsEditMode(true);
        }}
        actions={["EDIT", "ACTIVE", "DELETE", "CONFIG", "MAPHEADER"]}
        performAction={handleActions}
        pagination={true}
        paginationPageSize={10}
        rowMode
      />
    </>
  );
};

export default DestinationSource;
