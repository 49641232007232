import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Grid,
  Autocomplete,
  Card,
  Box,
} from "@mui/material";
import { mdiPencil, mdiCog } from "@mdi/js";
import Icon from "@mdi/react";
import AgGridComponent from "../../../../components/common/AgGridComponent";
import { Tdata1 } from "@store/static-data/TableData";
import { styled } from "@mui/system";
import { baseURL } from "@configs/envConfig";
import CircularProgressLoading from "@components/loaders/CircularProgressLoading";
import { axiosInstance } from "@services/axiosInstance";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import { useDispatch } from "react-redux";
import GlobalConfirmationPopup from "../../../../components/common/GlobalConfirmationPopup";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../services/apiQueryServices";
import {
  removeData,
  updateRawData,
} from "../../../../store/MapAndImportModule/RawDataSlice";
import { useSelector } from "react-redux";

const ImportOrResetMappedRawDataTables = () => {
  const [columnDef, setColumnDef] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [data, setdata] = useState([]);
  const [dataTable, setDataTable] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isImport, setIsImport] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (gridData.length) {
      const keys = Object.keys(gridData[0]);
      const column = keys?.map((item, index) => {
        return { field: item, filter: true };
      });
      setColumnDef(column);
    }
  }, [gridData]);

  //store datas
  const RawData = useSelector((state) => state.rawData?.data);

  //calling data api
  const getRawData = useGetApiCallQuery({
    endPoint: "dl/map-and-import/table/list-items/1",
    method: "GET",
    headers: {},
  });
  useEffect(() => {
    getRawData.refetch();
  }, []);

  useEffect(() => {
    if (getRawData?.isSuccess && getRawData.data?.data !== null) {
      dispatch(updateRawData([...getRawData.data?.data].reverse()));
    }
  }, [getRawData]);

  //calling gridData Api
  const [getGridApiCall, gridResponse] = useApiCallMutation();

  useEffect(() => {
    getRawData.refetch();
    if (gridResponse.isSuccess && gridResponse?.data?.data) {
      setGridData(gridResponse?.data?.data);
    }
  }, [gridResponse.isSuccess]);

  const fetchDataDetails = async () => {
    getGridApiCall({
      endPoint: `dl/map-and-import/mapped-header/${dataTable?.value}`,
      method: "GET",
    });
  };

  //calling gridData Api
  const [isImportApiCall, importResponse] = useApiCallMutation();
  const [resetApiCall, resetResponse] = useApiCallMutation();

  useEffect(() => {
    if (importResponse?.isSuccess || resetResponse?.isSuccess) {
      dispatch(removeData(dataTable?.value));
      successCallback(importResponse?.data);
      resetPage();
      setDataTable({});
    }
  }, [importResponse, resetResponse]);

  const resetHandler = (arg) => {
    if (arg === "ResetDataHeader") {
      setIsReset(true);
      setIsImport(false);
      resetDataHeaders();
    } else if (arg === "ImportData") {
      setIsImport(true);
      setIsReset(false);
      importDataHandler();
    }
  };
  const resetDataHeaders = () => {
    setPopupTitle("Are you sure you want to Reset data Headers?");
    setShowConfirmation(true);
  };
  const importDataHandler = () => {
    setPopupTitle("Are you sure you want to Import Data?");
    setShowConfirmation(true);
  };

  const handleConfirm = (remark) => {
    setIsPageLoading(true);
    if (isActive) {
      setIsActive(false);
    }
    if (isImport) {
      isImportApiCall({
        endPoint: `dl/map-and-import/import-header/${dataTable?.value}`,
        method: "GET",
      });
    } else if (isReset) {
      resetApiCall({
        endPoint: `dl/map-and-import/reset-mapped-header/${dataTable?.value}`,
        method: "GET",
      });
    }
  };

  const handleCancel = (data) => {
    setShowConfirmation(false);
  };

  const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.primary.main,
  }));
  const GroupItems = styled("ul")({
    padding: 0,
  });
  const actionButtons = () => {
    return (
      <span>
        <>
          <Icon path={mdiPencil} onClick={() => alert(`uuuuuu`)} size={1} />
          <Icon path={mdiCog} size={1} onClick={() => alert(`ahdkjashdkj`)} />
        </>
      </span>
    );
  };
  const keys = Object.keys(Tdata1.data[0]);
  const coloum = keys.map((item, index) => {
    return { field: item, filter: true };
  });
  coloum.push({ field: "Action", cellRenderer: actionButtons });

  const successCallback = (response) => {
    if (response?.status) {
      const status = response?.status;
      dispatch(
        snackbarMessageUpdate({
          open: true,
          color: "success",
          message: status?.value,
        })
      );
    }
  };

  const resetPage = () => {
    setColumnDef([]);
    setGridData([]);
    setShowConfirmation(false);
    setPopupTitle("");
    setIsActive(false);
    setIsImport(false);
    setIsReset(false);
  };

  if (
    gridResponse?.isLoading ||
    importResponse?.status === "pending" ||
    resetResponse?.status === "pending"
  )
    return <CircularProgressLoading />;
  return (
    <div className="ag-theme-alpine" style={{ height: "calc(100vh - 235px)" }}>
      <Box sx={{ padding: "20px" }}>
        <Card>
          <Box
            sx={{
              padding: "20px 30px",
              borderBottom: "1px solid #E0E0E0",
            }}
          >
            <Box
              component={"form"}
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={8}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Autocomplete
                    id="grouped-demo"
                    options={RawData || []}
                    value={dataTable || {}}
                    groupBy={(option) => option.tablePrefix}
                    getOptionLabel={(option) =>
                      Object.keys(option).length > 0 ? option.value : ""
                    }
                    onChange={(e, val) => setDataTable(val)}
                    sx={{ width: "60vw" }}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Select Data Table" />
                    )}
                    renderGroup={(e) => (
                      <li key={e.key}>
                        <GroupHeader>
                          {<Typography variant="body2">{e.group}</Typography>}
                        </GroupHeader>
                        <GroupItems>
                          {
                            <Typography variant="body2">
                              {e.children}
                            </Typography>
                          }
                        </GroupItems>
                      </li>
                    )}
                  />
                  <Button
                    variant="outlined"
                    sx={{
                      ml: 1,
                      padding: "6px 45px ",
                      whiteSpace: "nowrap",
                    }}
                    onClick={fetchDataDetails}
                    disabled={!dataTable?.value}
                  >
                    Get Mapped Headers
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Card>
        <Box>
          {!gridResponse?.isLoading && gridData?.length > 0 && (
            <Box
              sx={{
                pt: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <Box
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  <div
                    style={{
                      marginTop: "0.8rem",
                    }}
                  >
                    Import or reset mapped raw data tables
                  </div>
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    type="submit"
                    onClick={() => resetHandler("ResetDataHeader")}
                    sx={{
                      ml: 1,
                      padding: "10px 45px ",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Reset Data Headers
                  </Button>
                  <Button
                    variant="outlined"
                    type="submit"
                    sx={{
                      ml: 1,
                      padding: "10px 45px ",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => resetHandler("ImportData")}
                    // onClick={importDataHandler}
                  >
                    Import Data
                  </Button>
                </Box>
              </Box>
              <GlobalConfirmationPopup
                open={showConfirmation}
                title={popupTitle}
                // message="Are you sure you want to proceed?"
                onConfirm={handleConfirm}
                onClose={handleCancel}
                isRemark={false}
              />
              <AgGridComponent
                rowData={gridData}
                columnDefs={columnDef}
                pagination={true}
                paginationPageSize={10}
                onRowSelectionChangedHandler={(arg) => console.log(arg)}
                rowMode
              />
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};
export default ImportOrResetMappedRawDataTables;
