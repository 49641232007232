import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],

};

const availableFileGridData = createSlice({
  name: "availableFileGridData",
  initialState,
  reducers: {
    updateAvailableFileGridData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { updateAvailableFileGridData } = availableFileGridData.actions;
export default availableFileGridData.reducer;
