import { useEffect, forwardRef } from "react";
import Fade from "@mui/material/Fade";
import Snackbar from "@mui/material/Snackbar";
import { useSelector, useDispatch } from "react-redux";
import { snackbarMessageUpdate } from "@store/config/configSlice";

import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={1} ref={ref} variant="filled" {...props} />;
});

export default function SnackbarCom() {
  const dispatch = useDispatch();
  const { snackbarMessage } = useSelector((state) => state.config);
  const { vertical, horizontal, open, message, color } = snackbarMessage;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      snackbarMessageUpdate({
        open: false,
        message: "",
      })
    );
  };

  return (
    <div>
      <Snackbar
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        key={vertical + horizontal}
        TransitionComponent={Fade}
      >
        <Alert severity={color}>{message}</Alert>
      </Snackbar>
    </div>
  );
}
