import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Drawer, Grid, Typography } from "@mui/material";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import { useDispatch, useSelector } from "react-redux";
import GlobalDropdown from "@components/common/GlobalDropedown";
import GlobalInput from "../../components/common/GlobalInput";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../services/apiQueryServices";
import {
  addTableRow,
  updateTableRow,
} from "../../store/ExportDataModule/DestinationSourceSlice";
import { addOwnerList } from "../../store/ExportDataModule/DestinationSourceSlice/AddEditDestinationSourceSlice";

const AddEditDestinationSource = (props) => {
  const { setOpen, isOpen, defaultValues, loadData, isEditMode } = props;
  const [defaultValuesSchema, setDefaultValuesSchema] = useState({});
  const dispatch = useDispatch();

  const OwnerListData = useSelector((state) => state?.ownerList?.data);

  const getOwnerList = useGetApiCallQuery({
    endPoint: "dl/export/source-owner/list-items",
    method: "GET",
  });

  useEffect(() => {
    if (getOwnerList.isSuccess) {
      getOwnerList.refetch();
      dispatch(addOwnerList([...getOwnerList?.data?.data].reverse()));
    }
  }, [getOwnerList.data]);

  const exportSourceList = [
    { label: "Data-Lab", value: "data-lab" },
    { label: "Data-Lake", value: "data-lake" },
  ];

  const desMethoslist = [
    { label: "POST", value: "POST" },
    { label: "GET", value: "GET" },
    { label: "FTP", value: "FTP" },
    { label: "FORMDATA", value: "FORMDATA" },
  ];

  useEffect(() => {
    setDefaultValuesSchema(defaultValues);
    if (isEditMode) {
      setFormValues();
    }
  }, [defaultValuesSchema]);
  const setFormValues = () => {
    setValue("name", defaultValuesSchema?.name, {
      shouldTouch: true,
      shouldDirty: true,
    });
    setValue("ownerId", defaultValuesSchema?.ownerId, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("exportSource", defaultValuesSchema?.exportSource, {
      shouldTouch: true,
      shouldDirty: true,
    });

    setValue("apiHeaders", defaultValuesSchema?.apiHeaders, {
      shouldTouch: true,
      shouldDirty: true,
    });
    setValue("description", defaultValuesSchema?.description, {
      shouldTouch: true,
      shouldDirty: true,
    });
    setValue("exportMethod", defaultValuesSchema?.exportMethod, {
      shouldTouch: true,
      shouldDirty: true,
    });
    if (defaultValues?.exportMethod === "FTP") {
      setValue("ftpAddress", defaultValuesSchema?.ftpAddress, {
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("ftpUsername", defaultValuesSchema?.ftpUsername, {
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("ftpPassword", defaultValuesSchema?.ftpPassword, {
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("ftpPort", defaultValuesSchema?.ftpPort, {
        shouldTouch: true,
        shouldDirty: true,
      });
    } else {
      setValue("destUrl", defaultValuesSchema?.destUrl, {
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))?.token.access_token}`, // Example header
  };

  const [apiCall, res] = useApiCallMutation();
  const [apiCall1, res1] = useApiCallMutation();
  const [urlListItems, resUrlListItems] = useApiCallMutation();

  useEffect(() => {
    if (res.isSuccess && res.data !== null) {
      let finalData = { ...res?.data?.data };
      finalData.ownerName = OwnerListData.find(
        (item) => item.value === finalData.ownerId
      )?.label;
      successCallback(res.data);
      dispatch(addTableRow(finalData));
    }
  }, [res]);

  useEffect(() => {
    if (res1.isSuccess && res1.data !== null) {
      let finalData = { ...res1?.data?.data };
      finalData.ownerName = OwnerListData.find(
        (item) => item.value === finalData.ownerId
      )?.label;
      successCallback(res1.data);
      dispatch(updateTableRow(finalData));
    }
  }, [res1]);

  // useEffect(() => {
  //   if (resUrlListItems.isSuccess && resUrlListItems.data !== null) {
  //     setValue("destUrl", resUrlListItems.data.data, {
  //       shouldTouch: true,
  //       shouldDirty: true,
  //     });
  //     // setDestURL(resUrlListItems.data.data);
  //   }
  // }, [resUrlListItems]);

  useEffect(() => {
    const { isSuccess, data } = resUrlListItems;
    const destUrl = isSuccess && data !== null ? data.data : '';

    setValue("destUrl", destUrl, {
      shouldTouch: true,
      shouldDirty: true,
    });
  }, [resUrlListItems]);

  const initialValues = {
    name: "",
    ownerId: "",
    exportSource: "",
    exportMethod: "POST",
    destUrl: "",
    ftpAddress: "",
    ftpUsername: "",
    ftpPassword: "",
    ftpPort: "",
    apiHeaders: "",
    description: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Source Name is required"),
    ownerId: Yup.string().required("Destination Source is required"),
    exportSource: Yup.string().required("Export Source is required"),
    exportMethod: Yup.string().required("Export Method is required"),
    destUrl: Yup.string().url("Destination URL must be a valid URL").nullable(),
    // ftpAddress: Yup.string().when("exportMethod", {
    //   is: "FTP",
    //   then: Yup.string().required("FTP Address is required"),
    //   otherwise: Yup.string(),
    // }),
    // ftpUsername: Yup.string().when("exportMethod", {
    //   is: "FTP",
    //   then: Yup.string().required("FTP Username is required"),
    //   otherwise: Yup.string(),
    // }),
    // ftpPassword: Yup.string().when("exportMethod", {
    //   is: "FTP",
    //   then: Yup.string().required("FTP Password is required"),
    //   otherwise: Yup.string(),
    // }),
    // ftpPort: Yup.string().when("exportMethod", {
    //   is: "FTP",
    //   then: Yup.string().required("FTP Port is required"),
    //   otherwise: Yup.string(),
    // }),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
  });

  const exportMethodValues = watch("exportMethod");
  const ownerIdValues = watch("ownerId");
  const destUrlValue = watch("destUrl");

  useEffect(() => {
    getSourceOwnerURL();
  }, [ownerIdValues]);
  const getSourceOwnerURL = () => {
    if (ownerIdValues && ownerIdValues !== defaultValuesSchema?.ownerId) {
      const data = {
        id: ownerIdValues,
      };
      urlListItems({
        endPoint: `dl/export/source-owner/url-list-items`,
        method: "POST",
        data: { data },
      });
    }
  };

  // useEffect(() => {
  //   setValue("destUrl", destURL, {
  //     shouldTouch: true,
  //     shouldDirty: true,
  //   });
  // }, [destURL]);

  useEffect(() => {
    handleExportMethodChange();
  }, [exportMethodValues]);

  const handleExportMethodChange = () => {
    if (exportMethodValues === "FTP") {
      setValue("destUrl", "", {
        shouldTouch: true,
        shouldDirty: true,
      });
    } else {
      // setValue("destUrl", destURL, {
      //   shouldTouch: true,
      //   shouldDirty: true,
      // });
      setValue("ftpAddress", "", {
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("ftpUsername", "", {
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("ftpPassword", "", {
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("ftpPort", "", {
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  };

  const successCallback = (response) => {
    const status = response?.status;

    setOpen(false);
    // loadData();
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "success",
        message: status.value,
      })
    );
  };

  const onFormSubmit = (data) => {
    if (!isEditMode) {
      apiCall({
        endPoint: `dl/export/data-source/create`,
        method: "POST",
        data: { data },
        headers: { headers },
      });
      // successCallback();    // if we unable to add new source
    } else {
      apiCall1({
        endPoint: `dl/export/data-source/update/${defaultValuesSchema?._id}`,
        method: "PUT",
        data: { data },
        headers: { headers },
      });
      // successCallback();
    }
  };

  return (
    <>
      <Drawer anchor={"right"} open={isOpen} onClose={() => setOpen(false)}>
        <Box
          sx={{
            width: "60vw",
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Box marginBottom={"15px"}>
            <Typography component="h5" variant="h5" fontWeight={600}>
              {(isEditMode && "Update Destination Source") ||
                "Add Destination Source"}
            </Typography>
          </Box>

          <Box component={"form"}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <GlobalInput
                  required
                  fullWidth
                  control={control}
                  size="small"
                  name="name"
                  label="Source Name"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <GlobalDropdown
                  options={OwnerListData}
                  control={control}
                  size="small"
                  name="ownerId"
                  label="Source Owner"
                  error={!!errors.location}
                  helperText={errors.location?.message}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <GlobalDropdown
                  options={exportSourceList}
                  control={control}
                  size="small"
                  name="exportSource"
                  label="Export Source"
                  error={!!errors.location}
                  helperText={errors.location?.message}
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <GlobalDropdown
                  options={desMethoslist}
                  control={control}
                  size="small"
                  name="exportMethod"
                  label="Export Method"
                  error={!!errors.location}
                  helperText={errors.location?.message}
                  required
                />
              </Grid>

              {watch("exportMethod") !== "FTP" && (
                <Grid item xs={12}>
                  <GlobalInput
                    control={control}
                    size="small"
                    fullWidth
                    label="Destination URL"
                    name="destUrl"
                    error={!!errors.destUrl}
                    helperText={errors.destUrl?.message}
                  />
                </Grid>
              )}
              {watch("exportMethod") === "FTP" && (
                <>
                  <Grid item xs={6}>
                    <GlobalInput
                      control={control}
                      size="small"
                      fullWidth
                      label="FTP Address"
                      name="ftpAddress"
                      error={!!errors.ftpAddress}
                      helperText={errors.ftpAddress?.message}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <GlobalInput
                      control={control}
                      size="small"
                      fullWidth
                      label="FTP Username"
                      name="ftpUsername"
                      error={!!errors.ftpUsername}
                      helperText={errors.ftpUsername?.message}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <GlobalInput
                      control={control}
                      size="small"
                      fullWidth
                      label="FTP Password"
                      name="ftpPassword"
                      error={!!errors.ftpPassword}
                      helperText={errors.ftpPassword?.message}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <GlobalInput
                      control={control}
                      size="small"
                      fullWidth
                      label="FTP Port"
                      name="ftpPort"
                      error={!!errors.ftpPort}
                      helperText={errors.ftpPort?.message}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <GlobalInput
                  control={control}
                  size="small"
                  fullWidth
                  label="API Headers"
                  multiline
                  rows={4}
                  name="apiHeaders"
                  error={!!errors.apiHeaders}
                  helperText={errors.apiHeaders?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <GlobalInput
                  control={control}
                  size="small"
                  fullWidth
                  label="Description"
                  multiline
                  rows={4}
                  name="description"
                  error={!!errors.description}
                  helperText={errors.description?.message}
                />
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              position: "absolute",
              bottom: "0",
              width: "97.3%",
              pb: "10px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  style={{ width: "100%" }}
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="outlined"
                  style={{
                    color: "#ff831f",
                    width: "100%",
                    borderColor: "#ff831f",
                    opacity: res.isLoading || res1.isLoading ? 0.8 : 1,
                    pointerEvents:
                      res.isLoading || res1.isLoading ? "none" : "auto",
                    cursor:
                      res.isLoading || res1.isLoading
                        ? "not-allowed"
                        : "pointer",
                  }}
                  onClick={handleSubmit(onFormSubmit)}
                >
                  {res.isLoading || res1.isLoading
                    ? "Wait..."
                    : isEditMode
                      ? "Update"
                      : "Add"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default AddEditDestinationSource;
