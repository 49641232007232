import { Box, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import PageHeader from "../../components/ui/pageHeader/PageHeader";
import { Outlet } from "react-router-dom"; 
import CircularProgressLoading from "../../components/loaders/CircularProgressLoading";
import ImportToExportReport from "./components/Import-to-Export-Report";
import SourceDuplicateReport from "./components/Source-Duplicate-Report";
import DomainWiseCountReport from "./components/Domai-wise_count_report";
import SharingOutReport from "./components/sharing-out_report";

const Report = () => {

  const pageHeaderContent = {
    title: "Report",
    description: "Insights from Processed Data  ",
    navButtons: [
     { name: "Import", route: "/report/import" }, 
     { name: "Export", route: "/report/export" },      
     { name: "Custom", route: "/report/custom-reports" },
     { name: "Error", route: "/report/error" }, 
     { name: "Analytics", route: "/report/analytics" }, 
    ],
  };

  return (
    <>
      <PageHeader
        title={pageHeaderContent.title}
        description={pageHeaderContent?.description}
        navButtons={pageHeaderContent?.navButtons}
      />

      <div>
        <Outlet />
      </div>
    </>
  );
};

export default Report;
