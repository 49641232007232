import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const dataSourceOwnerDetails = createSlice({
  name: "dataSourceOwnerDetails",
  initialState,
  reducers: {
    updateDataSOurceOwnerDetails: (state, action) => {
      state.tableData = action.payload;
    },
    updateTableRow: (state, action) => { 
      const updatedRow = action.payload;
      const index = state.tableData.data.findIndex(
        (data) => data?._id === updatedRow?._id
      );
      state.tableData.data[index] = updatedRow;
    },
    addTableRow: (state, action) => {
      state.tableData.data.unshift(action.payload);
    },
    removeTableRow: (state, action) => {
      state.tableData = state.tableData.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});

export const {
  updateDataSOurceOwnerDetails,
  updateTableRow,
  addTableRow,
  removeTableRow,
} = dataSourceOwnerDetails.actions;
export default dataSourceOwnerDetails.reducer;
