import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const feedList = createSlice({
  name: "feedList",
  initialState,
  reducers: {
    addFeedListQuery: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { addFeedListQuery } = feedList.actions;
export default feedList.reducer;