import React, { useEffect, useState } from "react";
import Icon from "@mdi/react";
import {
  mdiFile,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiClose,
} from "@mdi/js";
import { useDispatch, useSelector } from "react-redux";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import AgGridComponent from "@components/common/AgGridComponent";
import CircularProgressLoading from "@components/loaders/CircularProgressLoading";
import AddNewSource from "@Models/AddNewSource";
import {
  Box,
  Button,
  Switch,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import GlobalConfirmationPopup from "../../../../../../components/common/GlobalConfirmationPopup";
import AddButton from "../../../../../../components/common/AddButton";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../../../services/apiQueryServices";
import {
  updateDataSourceDetailsTableData,
  updateTableRow,
} from "../../../../../../store/DataSourceModule/DataSourceDetailsSlice";

import BarChart from "../../../../../../components/BarChart/BarChart";

const DataSourceDetails = () => {
  const [rowSelected, setRowSelected] = React.useState({});
  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isInactive, setIsInactive] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const dispatch = useDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [dialogOpen, setDialogOpen] = useState(false);
  const [bardata, setbardata] = useState();
  const [userId, setUserId] = useState();

  const { data, refetch, isLoading, isSuccess } = useGetApiCallQuery({
    endPoint: "dl/import/data-source/details",
    method: "POST",
    data: {
      query: {},
      options: {
        page: 1,
        limit: 10,
        pagination: false,
        options: {},
      },
    },
    headers: {},
  });

  const TableData = useSelector((state) => state.dataSourceDetails?.tableData);

  const [apiCall, res] = useApiCallMutation();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    dispatchData();
  }, [data, isLoading]);

  useEffect(() => {
    if (res.isSuccess && res.data.data !== null) {
      successCallback(res.data);
      dispatch(updateTableRow(res.data.data));
    }
  }, [res]);

  const dispatchData = () => {
    if (isSuccess) {
      const tableData = {
        data: [...data?.data?.data].reverse(),
        headers: [...data?.data?.headers],
      };
      tableData.headers.splice(1, 0, {
        field: "View Pdf",
        cellRenderer: actionButtonsPdf,
        headerComponentFramework: centerAlignedHeaderComponent,
      });
      tableData.headers.splice(7, 0, {
        field: "Connect To Lakehouse",
        cellRenderer: handleConnectToLakehouse,
        headerComponentFramework: centerAlignedHeaderComponent,
      });
      tableData.headers.splice(8, 0, {
        field: "Email Verification",
        cellRenderer: handleEmailVerification,
        headerComponentFramework: centerAlignedHeaderComponent,
      });
      tableData.headers.splice(10, 0, {
        field: "Bad Check",
        cellRenderer: handleBadCheck,
        headerComponentFramework: centerAlignedHeaderComponent,
      });
      tableData.headers.splice(12, 0, {
        field: "Dupe Check",
        cellRenderer: handleDupeCheck,
        headerComponentFramework: centerAlignedHeaderComponent,
      });

      tableData.headers.splice(19, 0, {
        field: "Last 7 Days",
        pinned: "right",
        cellRenderer: (params) => {
          const totalxData = params.data?.miniGraph?.series[0]?.data || [] ;
          if (datacal(totalxData)) {
            return (
              <div
                onClick={() => barClick(params.data)}
                style={{
                  marginLeft: "10px",
                  fontStyle: "italic",
                  color: "#f57d10",
                }}
              >
                No data in last 7 Days{" "}
              </div>
            );
          } else {
            return (
              <div onClick={() => barClick(params.data)}>
                <BarChart
                  xdata={params.data.miniGraph.xaxis}
                  ydata={params.data.miniGraph.series[0].data}
                  showD={false}
                />
              </div>
            );
          }
        },
        headerComponentFramework: centerAlignedHeaderComponent,
      });

      // tableData.headers.splice(20, 0, {
      //   field: "Last 30 Days",
      //   cellRenderer: actionBarchart,
      //   headerComponentFramework: centerAlignedHeaderComponent,
      // });

      dispatch(updateDataSourceDetailsTableData(tableData));
    }
  };

  function datacal(data) {
    let add = 0;
    for (let i = 0; i < data.length; i++) {
      add = add + data[i];
    }
    if (add == 0) return true;
    return false;
  }

  const [apiCallMutation] = useApiCallMutation();

  const barClick = async (params) => {
    const id = params.sourceId;
    setUserId(id);

    const source_id = {
      data: {
        sourceId: id,
      },
    };

    try {
      const response = await apiCallMutation({
        endPoint: "dl/import/graph/last-30-days-report",
        method: "POST",
        data: source_id,
      });
      if (response.data) {
        setbardata(response.data.data);
      }
    } catch (error) {
      setbardata();
      console.log(error);
    }
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setbardata();
  };

  // const actionBarchart = (params) => {
  //   return (
  //     <div style={{ textAlign: "center", marginTop: "0.3rem" }}>
  //       <Icon
  //         path={mdiPoll}
  //         title="Last 30 Days"
  //         onClick={() => barClick(params.data)}
  //         size={1}
  //         style={{
  //           color: "#1976d2",
  //           cursor: "pointer",
  //         }}
  //       />
  //     </div>
  //   );
  // };

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${
      JSON.parse(localStorage.getItem("user"))?.token.access_token
    }`,
  };

  const successCallback = (response) => {
    const status = response.status;
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "success",
        message: status.value,
      })
    );
  };

  const errorCallback = (error) => {
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "error",
        message: error.response.data.status.value,
      })
    );
  };

  const centerAlignedHeaderComponent = (props) => {
    return <div style={{ textAlign: "center" }}>{props.displayName}</div>;
  };

  const handleConnectToLakehouse = (params) => {
    return (
      <div style={{ textAlign: "center", marginTop: "0.3rem" }}>
        {params.data.connectToLakehouse && (
          <Icon
            path={mdiCheckCircleOutline}
            size={1}
            style={{
              color: "Green",
              cursor: "pointer",
            }}
          />
        )}
        {!params.data.connectToLakehouse && (
          <Icon
            path={mdiCloseCircleOutline}
            size={1}
            style={{
              color: "red",
              cursor: "pointer",
            }}
          />
        )}
      </div>
    );
  };
  const handleBadCheck = (params) => {
    return (
      <div style={{ textAlign: "center", marginTop: "0.3rem" }}>
        {params.data.badCheck && (
          <Icon
            path={mdiCheckCircleOutline}
            size={1}
            style={{
              color: "Green",
              cursor: "pointer",
            }}
          />
        )}
        {!params.data.badCheck && (
          <Icon
            path={mdiCloseCircleOutline}
            size={1}
            style={{
              color: "red",
              cursor: "pointer",
            }}
          />
        )}
      </div>
    );
  };
  const handleDupeCheck = (params) => {
    return (
      <div style={{ textAlign: "center", marginTop: "0.3rem" }}>
        {params.data.dupeCheck && (
          <Icon
            path={mdiCheckCircleOutline}
            size={1}
            style={{
              color: "Green",
              cursor: "pointer",
            }}
          />
        )}
        {!params.data.dupeCheck && (
          <Icon
            path={mdiCloseCircleOutline}
            size={1}
            style={{
              color: "red",
              cursor: "pointer",
            }}
          />
        )}
      </div>
    );
  };
  const handleEmailVerification = (params) => {
    // console.log(params.data);
    return (
      <div style={{ textAlign: "center", marginTop: "0.3rem" }}>
        {params.data.emailVerification && (
          <Icon
            path={mdiCheckCircleOutline}
            size={1}
            style={{
              color: "Green",
              cursor: "pointer",
            }}
          />
        )}
        {!params.data.emailVerification && (
          <Icon
            path={mdiCloseCircleOutline}
            size={1}
            style={{
              color: "red",
              cursor: "pointer",
            }}
          />
        )}
      </div>
    );
  };

  const actionButtonsPdf = (params) => {
    return (
      <div style={{ textAlign: "center", marginTop: "0.3rem" }}>
        <Icon
          path={mdiFile}
          title="API Doc"
          onClick={() => openPdfFile(params.data)}
          size={1}
          style={{
            color: "#1976d2",
            cursor: "pointer",
          }}
        />
      </div>
    );
  };

  const openPdfFile = (data) => {
    const pdfDoc = `<html>
      <head>
        <title>${data?.sourceId}-${data?.name}</title>
        <style>
              /* Material-UI Table Styles */
              .mui-table {
                  border-collapse: collapse;
                  width: 100%;
              }
  
              .mui-table th,
              .mui-table td {
                  border: 1px solid #ddd;
                  padding: 8px;
              }
  
              .mui-table th {
                  background-color: #f2f2f2;
              }
          </style>
      </head>
      <body>
      <div style="font-family:arial; background:#fff; width:100%;">
       <div style="background:#ff831f; color:#fff; padding:15px 1rem; font-size:1.5rem">
          <b>Data-Labs Import API Document</b>
       </div>
       <br/>
       <div style="margin:0 2rem;">
        <p>Use POST request to send data with a JSON body. Please find the details and example down below:</p>
        <br>
        <table class="mui-table">
                      <tr>
                          <th>Data Source ID:</th>
                          <td>${data?.sourceId}</td>
                      </tr>
                      <tr>
                          <th>API Url:</th>
                          <td>https://gateway.rapidlabs.io/dl/public/${data?.sourceId}/import</td>
                      </tr>
                      <tr>
                          <th>API Method:</th>
                          <td>POST</td>
                      </tr>
                      <tr>
                          <th>API Headers:</th>
                          <td>
                          <div><b>APIKEY</b>       :&nbsp ${data?.apiKey}</div>
                          <div><b>ACCESSTOKEN</b>  :&nbsp ${data?.accessKey}</div>
                          <div><b>Content-Type</b> :&nbsp application/json</div>

                          </td>
                      </tr>
                      <tr>
                          <th>Data Minimum Limit:</th>
                          <td>1 Record</td>
                      </tr>
                      <tr>
                          <th>Data Maximum Limit:</th>
                          <td>20 Records</td>
                      </tr>
                  </table>
  
         <h4>DATA FIELDS:</h4> 
         
         <table class="mui-table">
                  <tr style="background:#333;color:#fff;font-weight:bold;">
                      <th style="background:#333;color:#fff;font-weight:bold;">Field Name</th>
                      <th style="background:#333;color:#fff;font-weight:bold;">Required/Optional</th>
                      <th style="background:#333;color:#fff;font-weight:bold;">Type</th>
                  </tr>
                  <tr>
                      <td>emailaddress</td>
                      <td><b>Required*</b></td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>emaildomain</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>birthdate</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>cityname</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>creditscore</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>emailtld</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>employer</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>firstname</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>incometype</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>isactivemilitary</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>iscitizen</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>ishomeowner</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>isvehicleowner</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>lastname</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>leaddate</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>leadipaddress</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>leadurl</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>leadurldomain</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>loanamount</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>loanpurpose</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>mailaddress1</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>mailaddress2</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>maritalstatus</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>middlename</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>monthlyincome</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>occupation</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>phoneconsent</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>phonenumberhome</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>phonenumbermobile</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>phonenumberwork</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>postalzipcode</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>provincestatename</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>soldamount</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>ssn</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>subid</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>subid2</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>timezone</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>countryname</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>driverslicensenumber</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>driverslicensestate</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>payfrequency</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>directdeposit</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>useragent</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
                  <tr>
                      <td>leadid</td>
                      <td>Optional</td>
                      <td>String</td>
                  </tr>
              </table>
         <hr> 
         <h4>Data Example</h4> 
         <table class="mui-table">
          <tr style="background:#333;color:#fff;font-weight:bold;">
            <td width="180"><b>Data Source ID:</b></td>
            <td>12345</td>
          </tr>
          <tr>
            <td><b>API Url:</b></td>
            <td>https://datalabs.rapidlabs.io/api/12345/import</td>
          </tr>
          <tr>
            <td><b>API Method:</b></td>
            <td>POST</td>
          </tr>
          <tr>
            <td valign="top"><b>API Headers:</b></td>
            <td>
            <div>{"APIKEY": "XXXX","ACCESSTOKEN": "XXXXXXXXXX"}</div>
            </td>
          </tr>
          <tr>
            <td valign="top"><b>Data:</b></td>
            <td>
              <h4>Single Record/JSON</h4>
              {"emailaddress":"sample1@sample.com","firstname":"james","lastname":"smith"}<br>
              <h4>Multiple Records/Collection of JSON</h4>
              [{"emailaddress":"sample1@sample.com","firstname":"james","lastname":"smith","phonenumbermobile":"1234567891",...},{"emailaddress":"sample2@sample.com","firstname":"alex","lastname":"brown","phonenumbermobile":"1234567891",...},...]
            </td>
          </tr>
          <tr>
            <td valign="top"><b>Response:</b></td>
            <td>{"rows": 2, "success": 1, "dupe": 0, "bad": 1, "failed": 0, "success_records": ["sample1@sample.com"], "dupe_records": [], "bad_records": ["sample2@sample.com"], "failed_records": {}, "message": ""}</td>
          </tr>
        </table>
  
         <h4>Support:</h4> 
         <p>If you have any further question or if you require any assistance – We’re here to help you:</p>
         <p><b>Skype:</b> veerendra1114</p><p><b>Email:</b> veerendra@zappian.com</p>
         </div>
         </div>
         </body>
         </html>
      </html>`;
    setTimeout(() => {
      let printWindow = window.open("", "_blank");
      printWindow?.document.write(pdfDoc);
      printWindow?.document.close();
      printWindow?.print();
    }, 1000);
  };

  const openForm = () => {
    setOpen(true);
    setIsEditMode(false);
    setRowSelected({});
  };

  const handleConfirm = (remark) => {
    if (isActive) {
      setIsActive(false);
      const data = {
        remark: remark,
        isActive: true,
      };
      apiCall({
        endPoint: `dl/import/data-source/active-inactive/${rowSelected?._id}`,
        method: "PUT",
        data: { data },
      });
    } else if (isInactive) {
      setIsInactive(false);
      const data = {
        remark: remark,
        isActive: false,
      };
      apiCall({
        endPoint: `dl/import/data-source/active-inactive/${rowSelected?._id}`,
        method: "PUT",
        data: { data },
      });
    } else if (isDelete) {
      setIsDelete(false);
      const data = {
        data: {
          remark: remark,
        },
      };
      apiCall({
        endPoint: `dl/import/data-source/delete/${rowSelected?._id}`,
        method: "DELETE",
        data: { data },
      });
    }
    setShowConfirmation(false);
  };
  const handleCancel = (data) => {
    setShowConfirmation(false);
  };

  const handleActions = (data, action) => {
    switch (action) {
      case "EDIT":
        setRowSelected(data);
        setIsEditMode(true);
        setOpen(true);
        break;
      case "ACTIVE":
        setRowSelected(data);
        if (data.isActive) {
          setIsInactive(true);
          setPopupTitle("Are you sure you want to Inactive?");
          setShowConfirmation(true);
        } else if (!data.isActive) {
          setIsActive(true);
          setPopupTitle("Are you sure you want to Active?");
          setShowConfirmation(true);
        }
        break;
      case "DELETE":
        setRowSelected(data);
        setIsDelete(true);
        setPopupTitle("Are you sure you want to Delete?");
        setShowConfirmation(true);
        break;
      default: {
      }
    }
  };

  if (isLoading) return <CircularProgressLoading />;

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 140,
          right: 16,
        }}
      >
        <AddButton onclick={openForm}>Add New Source</AddButton>
      </Box>
      {open && (
        <AddNewSource
          defaultValues={rowSelected}
          isOpen={open}
          setOpen={setOpen}
          name={"Add Data Source Owners"}
          isEditMode={isEditMode}
        ></AddNewSource>
      )}
      <GlobalConfirmationPopup
        open={showConfirmation}
        title={popupTitle}
        onConfirm={handleConfirm}
        onClose={handleCancel}
      />

      <Dialog
        fullScreen={fullScreen}
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="responsive-dialog-title"
        maxWidth={"xl"}
        fullWidth={true}
      >
        <DialogTitle id="responsive-dialog-title">
          Last 30 Days Data of {userId}
          <Icon
            path={mdiClose}
            title="Close"
            onClick={() => handleCloseDialog()}
            size={1}
            style={{
              color: "#000",
              cursor: "pointer",
              marginLeft: "80%",
            }}
          />
        </DialogTitle>
        <DialogContent>
          <BarChart
            xdata={bardata?.xaxis || []}
            ydata={bardata?.series[0].data || []}
            showD={true}
          />
        </DialogContent>
      </Dialog>

      <AgGridComponent
        rowData={TableData?.data || []}
        columnDefs={TableData.headers || []}
        // columnDefs={header || []}
        actions={["EDIT", "ACTIVE", "DELETE"]}
        performAction={handleActions}
        onRowSelectionChangedHandler={(arg) => console.log(arg)}
        pagination={true}
        paginationPageSize={10}
        rowMode
      />
    </>
  );
};
export default DataSourceDetails;
