import React, { useRef } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import ExportCountReport from "./component/CountReport";
import { Padding } from "@mui/icons-material";
import LMReport from "./component/LMReport";
import ScriptRunningReport from "./component/ScriptRunningReport";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ExportReports = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%", Padding: "20px" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Count Report" />
            <Tab label="LM Report" />
            <Tab label="Script Running Report" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ExportCountReport />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <LMReport />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ScriptRunningReport />
        </TabPanel>
      </Box>
    </>
  );
};

export default ExportReports;
