import React from "react";
import PageHeader from "../../components/ui/pageHeader/PageHeader";
import { Outlet } from "react-router-dom";

const pageHeaderContent = {
  title: "Export Data",
  description: "Streamline Data Export Process",
  navButtons: [
    { name: "Channel List", route: "/exportData/channel-list" },
    { name: "Channel Management", route: "/exportData/channel-management" },
    // { name: "Reports", route: "/exportData/export-reports" },
  ],
};

const ExportData = () => { 
  return (
    <>
      <PageHeader 
        title={pageHeaderContent.title}
        description={pageHeaderContent?.description}
        navButtons={pageHeaderContent?.navButtons}
      />
      <Outlet />
    </>
  );
};

export default ExportData;
