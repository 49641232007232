const callBackURL = process.env.REACT_APP_DL_IDP_CALL_BACK_URL;
const baseURL = process.env.REACT_APP_GATEWAY_URL;
//  const baseURL = "http://192.168.1.68:5000";
const idpURL = process.env.REACT_APP_IDP_URL;
const logoutURL = process.env.REACT_APP_DL_POST_LOGOUT_REDIRECT_URI;

// const env = process.env.REACT_APP_DL_APPLICATION_ENV;

// if (env === "development") {
//   callBackURL = process.env.REACT_APP_DL_IDP_CALL_BACK_URL;
//   baseURL = process.env.REACT_APP_GATEWAY_URL;
//   idpURL = process.env.REACT_APP_IDP_URL;
//   logoutURL = process.env.REACT_APP_DL_POST_LOGOUT_REDIRECT_URI;
// } else {
//   if (env === "DEV") {
//
//     callBackURL = process.env.REACT_APP_DL_IDP_CALL_BACK_URL;
//     baseURL = process.env.REACT_APP_GATEWAY_URL;
//     idpURL = process.env.REACT_APP_IDP_URL;
//     logoutURL = process.env.REACT_APP_DL_POST_LOGOUT_REDIRECT_URI;
//   } else if (env === "QA") {
//     //change later
//     callBackURL = process.env.REACT_APP_DL_IDP_CALL_BACK_URL;
//     baseURL = process.env.REACT_APP_GATEWAY_URL;
//     idpURL = process.env.REACT_APP_IDP_URL;
//     logoutURL = process.env.REACT_APP_DL_POST_LOGOUT_REDIRECT_URI;
//   } else if (env === "PROD") {
//     //change later
//     callBackURL = process.env.REACT_APP_DL_IDP_CALL_BACK_URL;
//     baseURL = process.env.REACT_APP_GATEWAY_URL;
//     idpURL = process.env.REACT_APP_IDP_URL;
//     logoutURL = process.env.REACT_APP_DL_POST_LOGOUT_REDIRECT_URI;
//   } else {
//     //default
//     callBackURL = process.env.REACT_APP_DL_IDP_CALL_BACK_URL;
//     baseURL = process.env.REACT_APP_GATEWAY_URL;
//     idpURL = process.env.REACT_APP_IDP_URL;
//     logoutURL = process.env.REACT_APP_DL_POST_LOGOUT_REDIRECT_URI;
//   }
// }

export { baseURL, idpURL, callBackURL, logoutURL };
