import { Outlet } from 'react-router-dom';


const Min=()=> {
  return (
    <>
      <Outlet />
    </>
  );
}

export default Min;
