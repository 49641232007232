import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const feedOwnerList = createSlice({
  name: "feedOwnerList",
  initialState,
  reducers: {
    addFeedOwnerList: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { addFeedOwnerList } = feedOwnerList.actions;
export default feedOwnerList.reducer;
