export const Tdata1 = {
  product: {
    name: "RightFul Labs",
    version: "v1.0",
  },
  status: {
    code: 200,
    value: "Your request is successfully executed.",
  },
  data: [
    {
      name: "sanjay",
      sourceId: "adapter",
      ownerId: "646224f8f86c6a3b88cc0d78",
      location: "646224f8f86c6a3b88cc0d7a",
      dbName: "646224f8f86c6a3b88cc0d7b",
      connectToLakehouse: 557,
      category: "gold",
      trustFactor: 358,
      dupeReject: 427,
      dupeCheck: true,
      badCheck: true,
      emailVerification: true,
      createdBy: "Gorgeous",
      isActive: true,
      remark: "vortals",
      createdAt: "2023-05-17T06:50:46.116Z",
      updatedAt: "2023-05-17T06:50:46.116Z",
      isDeleted: false,
      id: "646479469f60a01feba64723",
    },
    {
      name: "rushabh",
      sourceId: "adapter",
      ownerId: "646224f8f86c6a3b88cc0d78",
      location: "646224f8f86c6a3b88cc0d7a",
      dbName: "646224f8f86c6a3b88cc0d7b",
      connectToLakehouse: 557,
      category: "gold",
      trustFactor: 358,
      dupeReject: 427,
      dupeCheck: true,
      badCheck: true,
      emailVerification: true,
      createdBy: "Gorgeous",
      isActive: true,
      remark: "vortals",
      createdAt: "2023-05-17T06:50:54.180Z",
      updatedAt: "2023-05-17T06:50:54.180Z",
      isDeleted: false,
      id: "6464794e9f60a01feba64726",
    },
    {
      name: "nikhil",
      sourceId: "adapter",
      ownerId: "646224f8f86c6a3b88cc0d78",
      location: "646224f8f86c6a3b88cc0d7a",
      dbName: "646224f8f86c6a3b88cc0d7b",
      connectToLakehouse: 557,
      category: "gold",
      trustFactor: 358,
      dupeReject: 427,
      dupeCheck: true,
      badCheck: true,
      emailVerification: true,
      createdBy: "Gorgeous",
      isActive: true,
      remark: "vortals",
      createdAt: "2023-05-17T06:50:58.721Z",
      updatedAt: "2023-05-17T06:50:58.721Z",
      isDeleted: false,
      id: "646479529f60a01feba64729",
    },
    {
      name: "anand",
      sourceId: "adapter",
      ownerId: "646224f8f86c6a3b88cc0d78",
      location: "646224f8f86c6a3b88cc0d7a",
      dbName: "646224f8f86c6a3b88cc0d7b",
      connectToLakehouse: 557,
      category: "gold",
      trustFactor: 358,
      dupeReject: 427,
      dupeCheck: true,
      badCheck: true,
      emailVerification: true,
      createdBy: "Gorgeous",
      isActive: true,
      remark: "vortals",
      createdAt: "2023-05-17T06:51:02.582Z",
      updatedAt: "2023-05-17T06:51:02.582Z",
      isDeleted: false,
      id: "646479569f60a01feba6472c",
    },
    {
      name: "harshit",
      sourceId: "adapter",
      ownerId: "646224f8f86c6a3b88cc0d78",
      location: "646224f8f86c6a3b88cc0d7a",
      dbName: "646224f8f86c6a3b88cc0d7b",
      connectToLakehouse: 557,
      category: "gold",
      trustFactor: 358,
      dupeReject: 427,
      dupeCheck: true,
      badCheck: true,
      emailVerification: true,
      createdBy: "Gorgeous",
      isActive: true,
      remark: "vortals",
      createdAt: "2023-05-17T06:51:07.227Z",
      updatedAt: "2023-05-17T06:51:07.227Z",
      isDeleted: false,
      id: "6464795b9f60a01feba6472f",
    },
    {
      name: "hitesh",
      sourceId: "adapter",
      ownerId: "646224f8f86c6a3b88cc0d78",
      location: "646224f8f86c6a3b88cc0d7a",
      dbName: "646224f8f86c6a3b88cc0d7b",
      connectToLakehouse: 557,
      category: "gold",
      trustFactor: 358,
      dupeReject: 427,
      dupeCheck: true,
      badCheck: true,
      emailVerification: true,
      createdBy: "Gorgeous",
      isActive: true,
      remark: "vortals",
      createdAt: "2023-05-17T06:51:16.991Z",
      updatedAt: "2023-05-17T06:51:16.991Z",
      isDeleted: false,
      id: "646479649f60a01feba64732",
    },
    {
      name: "shambhu",
      sourceId: "adapter",
      ownerId: "646224f8f86c6a3b88cc0d78",
      location: "646224f8f86c6a3b88cc0d7a",
      dbName: "646224f8f86c6a3b88cc0d7b",
      connectToLakehouse: 557,
      category: "gold",
      trustFactor: 358,
      dupeReject: 427,
      dupeCheck: true,
      badCheck: true,
      emailVerification: true,
      createdBy: "Gorgeous",
      isActive: true,
      remark: "vortals",
      createdAt: "2023-05-17T06:51:35.708Z",
      updatedAt: "2023-05-17T06:51:35.708Z",
      isDeleted: false,
      id: "646479779f60a01feba64736",
    },
    {
      name: "rashi",
      sourceId: "adapter",
      ownerId: "646224f8f86c6a3b88cc0d78",
      location: "646224f8f86c6a3b88cc0d7a",
      dbName: "646224f8f86c6a3b88cc0d7b",
      connectToLakehouse: 557,
      category: "gold",
      trustFactor: 358,
      dupeReject: 427,
      dupeCheck: true,
      badCheck: true,
      emailVerification: true,
      createdBy: "Gorgeous",
      isActive: true,
      remark: "vortals",
      createdAt: "2023-05-17T06:51:41.565Z",
      updatedAt: "2023-05-17T06:51:41.565Z",
      isDeleted: false,
      id: "6464797d9f60a01feba64739",
    },
    {
      name: "hemlata",
      sourceId: "adapter",
      ownerId: "646224f8f86c6a3b88cc0d78",
      location: "646224f8f86c6a3b88cc0d7a",
      dbName: "646224f8f86c6a3b88cc0d7b",
      connectToLakehouse: 557,
      category: "gold",
      trustFactor: 358,
      dupeReject: 427,
      dupeCheck: true,
      badCheck: true,
      emailVerification: true,
      createdBy: "Gorgeous",
      isActive: true,
      remark: "vortals",
      createdAt: "2023-05-17T06:51:45.567Z",
      updatedAt: "2023-05-17T06:51:45.567Z",
      isDeleted: false,
      id: "646479819f60a01feba6473c",
    },
    {
      name: "mahesh",
      sourceId: "adapter",
      ownerId: "646224f8f86c6a3b88cc0d78",
      location: "646224f8f86c6a3b88cc0d7a",
      dbName: "646224f8f86c6a3b88cc0d7b",
      connectToLakehouse: 557,
      category: "gold",
      trustFactor: 358,
      dupeReject: 427,
      dupeCheck: true,
      badCheck: true,
      emailVerification: true,
      createdBy: "Gorgeous",
      isActive: true,
      remark: "vortals",
      createdAt: "2023-05-17T06:52:04.950Z",
      updatedAt: "2023-05-17T06:52:04.950Z",
      isDeleted: false,
      id: "646479949f60a01feba6473f",
    },
  ],
  error: false,
};
