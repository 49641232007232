import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],

};

const importedDataFileTablesList = createSlice({
  name: "importedDataFileTablesList",
  initialState,
  reducers: {
    updateImportedDataFileTablesList: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { updateImportedDataFileTablesList } = importedDataFileTablesList.actions;
export default importedDataFileTablesList.reducer;
