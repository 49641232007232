import { TextField } from "@mui/material";
import React from "react";
import { useController } from "react-hook-form";

const GlobalInput = ({
  control,
  name,
  label,
  multiline,
  rows,
  onClick,
  onBlur,
  ...rest
}) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: "",
  });

  const handleInputClick = (event) => {
    if (onClick) {
      onClick(event);
    }
  };

  const handleInputBlur = (event) => {
    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <TextField
      sx={{
        width: "100%",
      }}
      label={label}
      error={!!error}
      multiline={multiline}
      rows={rows}
      helperText={error ? error.message : ""}
      inputRef={ref}
      onClick={handleInputClick}
      onBlur={handleInputBlur}
      {...inputProps}
      {...rest}
    />
  );
};

export default GlobalInput;
