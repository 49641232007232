import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],

};

const sourceIdList = createSlice({
  name: "sourceIdList",
  initialState,
  reducers: {
    updateSourceIdList: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { updateSourceIdList } = sourceIdList.actions;
export default sourceIdList.reducer;
