import React from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../services/axiosInstance";
import { useEffect } from "react";
import { baseURL } from "@configs/envConfig";

import { useApiCallMutation } from "../../../services/apiQueryServices";

const Logout = () => {
  const navigate = useNavigate();
  // const [logout, logoutdata] = useApiCallMutation();

  const getRevokToken = () => {
    const data = {
      clientName: "datalabs",
    };

    axiosInstance
      .post(`${baseURL}/secured/revoke-token`, { data })
      .then((res) => {
        if (res?.data?.data === "Token revoked successfully") {
          localStorage.clear();
        }
        window.location.href = "/";
      });

    // logout({
    //   endPoint: "/secured/revoke-token",
    //   method: "POST",
    //   data: { data },
    // });
  };
  useEffect(() => {
    getRevokToken();
  }, []);

  // useEffect(() => {
  //   debugger;
  //   if (logoutdata?.data?.data === "Token revoked successfully") {
  //     localStorage.clear();
  //   }
  //   window.location.href = "/";
  // }, [logoutdata]);

  return <div></div>;
};

export default Logout;
