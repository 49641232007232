import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableData: [],
};

const dataSorceOwner = createSlice({
  name: "dataSorceOwner",
  initialState,
  reducers: {
    updateTableData: (state, action) => {
      state.tableData = action.payload;
    },
    updateTableRow: (state, action) => {
      const { rowIndex, updatedRow } = action.payload;
      state.tableData[rowIndex] = updatedRow;
    },
    removeTableRow: (state, action) => {
      state.tableData = state.tableData.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});

export const { updateTableData, updateTableRow, removeTableRow } =
  dataSorceOwner.actions;
export default dataSorceOwner.reducer;
