import React, { useRef, useState, useEffect } from "react";
import DataSourceOwner from "@Models/addSourceOwner";
// import AddDataSource from "@Models/addSource";
import TabComponent from "@components/common/TabComponent";
import { mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
// import { mdiCog } from "@mdi/js";
import AgGridComponent from "@components/common/AgGridComponent";
// import { data?.data } from "@store/static-data/TableData";

// import { useDispatch } from "react-redux";
import { useGetFromApiCallQueryQuery } from "@services/apiQueryServices";
import apiEndPointsConfig from "@services/apiEndPointsConfig";
// import { configStateUpdate } from '@store/config/configSlice';
import { CircularProgressLoading } from "@components/loaders";
import AddNewSource from "@Models/AddNewSource";
import NewSourceOwner from "@Models/AddNewSourceOwner";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import DataSourceOwnerDetails from "./components/DataSourceOwnerDetails";
import DataSourceDetails from "./components/DataSourceDetails";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageDataSources = () => {
  const [tabindex, setTabindex] = React.useState(1);
  const [value, setValue] = React.useState(0);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const dispatch = useDispatch();

  // const { data, error, isLoading, isFetching, isSuccess } =
  //   useGetFromApiCallQueryQuery({
  //     method: "POST",
  //     headers: {},
  //     data: {
  //       query: {},
  //       options: {
  //         page: 0,
  //         limit: 0,
  //         pagination: false,
  //         options: {},
  //       },
  //     },
  //     endPoint:
  //       tabindex == 1
  //         ? apiEndPointsConfig.getImportDataSourceDetails
  //         : apiEndPointsConfig.getImportDataSourceOwnerDetails,
  //   });

  const childRef = useRef(null);
  const childRef2 = useRef(null);
  // const handlePageChange = (pagenumber) => {
  //   setTabindex(pagenumber);
  // };
  // const handleButtonClick = () => {
  //   if (childRef.current && tabindex === 1) {
  //     childRef.current.childFunction();
  //   }
  //   if (childRef2.current && tabindex === 2) {
  //     childRef2.current.childFunction();
  //   }
  // };

  // const HandleSubmitSlowApi = (formData) => {
  //   console.info(formData);
  //   const { data, error, isLoading, isFetching, isSuccess } =
  //     useGetFromApiCallQueryQuery({
  //       method: "POST",
  //       headers: {},
  //       data: formData,
  //       endPoint: apiEndPointsConfig.addDataSourceOwners,
  //     });
  //   if (isSuccess) {
  //     console.info(data);
  //   }
  // };

  // if (isLoading) return <CircularProgressLoading />;
  // if (error) return <h1>Error</h1>;

  // const actionButtons = () => {
  //   return (
  //     <span>
  //       <>
  //         <Icon path={mdiPencil} onClick={handleButtonClick} size={1} />
  //       </>
  //     </span>
  //   );
  // };
  // const keys = Object.keys(data?.data.data[0]);
  // const coloum = keys?.map((item, index) => {
  //   return { field: item, filter: true };
  // });
  // coloum.push({ field: "Action", cellRenderer: actionButtons });

  // const tabData = [
  //   {
  //     tabname: "Data Source Owners Details",
  //     data: <AgGridComponent rowData={data?.data.data} columnDefs={coloum} paginator={data?.data.paginator} onRowSelectionChangedHandler={(arg)=>setRowSelected(arg)} />,
  //     model: [<NewSourceOwner name={"Add Data Source Owners"}  />],
  //   },
  //   {
  //     tabname: "Data Source Details",
  //     data: <AgGridComponent rowData={data?.data.data} columnDefs={coloum} paginator={data?.data.paginator} onRowSelectionChangedHandler={(arg)=>setRowSelected(arg)} />,
  //     model: [<AddNewSource name={"Add Data Source"} defaultValues={rowSelected} />],
  //   },
  // ];
 
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Data Source Owner Details" />
            <Tab label="Data Source Details" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <DataSourceOwnerDetails />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DataSourceDetails />
        </TabPanel>
      </Box>
      {/* <TabComponent
        tabData={tabData}
        onChange={handlePageChange}
      ></TabComponent>
      <NewSourceOwner defaultValues={rowSelected} ref={childRef}></NewSourceOwner>
      <AddNewSource ref={childRef2} /> */}
    </>
  );
};
export default ManageDataSources;
