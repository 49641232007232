import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useController } from "react-hook-form";

// import "../GlobalDatePicker/globalDatePicker.css";
const customedatapicker = {
  "& .MuiInputBase-input": {
    height: "0.4375em!important",
  },
};

const DatePickerComponent = ({
  control,
  name,
  label,
  error,
  helperText,
  minDate,
  maxDate,
  ...rest
}) => {
  const {
    field: { ref, value, onChange },
    fieldState: { invalid },
  } = useController({
    name,
    control,
    defaultValue: "",
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* <DemoContainer components={["DatePicker", "DatePicker"]}> */}
      <DatePicker
        label={label}
        sx={{ ...customedatapicker }}
        {...control}
        name={name}
        value={value}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
      />
      {/* </DemoContainer> */}
    </LocalizationProvider>
  );
};

export default DatePickerComponent;
