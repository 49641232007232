import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";

const GlobalConfirmationPopup = ({
  open,
  title,
  onClose,
  onConfirm,
  isRemark,
  required,
  message,
}) => {
  const [remark, setRemark] = useState("");
  const [error, setError] = useState(false);

  const handleConfirm = () => {
    setRemark("");
    onConfirm(remark);
    onClose();
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setRemark(event.target.value);
    setError(inputValue.startsWith(" "));
  };

  const handleCancel = () => {
    setError(false);
    setRemark("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>{title}</DialogTitle>
      {isRemark && (
        <DialogContent>
          <TextField
            label="Remark"
            value={remark}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="normal"
            required={required}
            error={error}
            helperText={error ? "Starting space are not allowed" : ""}
          />
        </DialogContent>
      )}

      {message && (
        <DialogContent>
          <Typography component="h5" variant="h5" fontWeight={600}>
            {message}
          </Typography>
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          disabled={
            required || error
              ? remark.trimStart() && !remark.startsWith(" ")
                ? false
                : true
              : false
          }
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

GlobalConfirmationPopup.defaultProps = {
  isRemark: true,
};

export default GlobalConfirmationPopup;
