import React, {useEffect} from 'react'

const RaiseAnIssue = () => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      // 👇️ redirects to an external URL
      window.location.replace('https://coda.io/form/Tech-Support-Ticket_dNkR-7Dz_g2');
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  return <>Will redirect to https://coda.io/form/Tech-Support-Ticket_dNkR-7Dz_g2</>;

}

export default RaiseAnIssue
  