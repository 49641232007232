import React, { useEffect } from "react";
import { Button } from "@mui/material";

let userPermissions = JSON.parse(localStorage.getItem("userPermission"));
const AddButton = ({ onclick, children }) => {
  useEffect(() => {
    userPermissions = JSON.parse(localStorage.getItem("userPermission"));
  }, [children]);
  const onClick = () => {
    if (
      (typeof onclick !== "undefined" &&
        userPermissions?.permissions?.includes("Publish Content")) ||
      userPermissions?.roles.includes("admin")
    ) {
      onclick();
    }
  };
  return (
    <>
      <Button
        onClick={onClick}
        disabled={
          !(
            userPermissions?.permissions?.includes("Publish Content") ||
            userPermissions?.roles?.includes("admin")
          )
        }
        sx={{
          pointerEvents: "auto !important",
          cursor:
            userPermissions?.permissions?.includes("Publish Content") ||
            userPermissions?.roles?.includes("admin")
              ? "pointer"
              : "not-allowed !important",
        }}
      >
        {children}
      </Button>
    </>
  );
};

export default AddButton;
