import React, { useRef, useState, useEffect } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import ImportToExportReport from "../Import-to-Export-Report";
import SourceDuplicateReport from "../Source-Duplicate-Report";
import DomainWiseCountReport from "../Domai-wise_count_report";
import SharingOutReport from "../sharing-out_report";
import ImportDataAnalysis from "../import-data-analysis";
import CountReport from "../../../DataSources/components/Reports/component/CountReport";

const ImportReport = () => {
  const [value, setValue] = useState(0);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%", padding: "10px" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Import Count Report" />
          <Tab label="Domain Wise Import Count Report" />
          <Tab label="Source Domain Report" />
          <Tab label="Source Valid Report" />
          <Tab label="Import Checker Report" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <CountReport />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DomainWiseCountReport />
      </TabPanel>
      <TabPanel value={value} index={2}>
        {/* <ImportDataAnalysis /> */}
        <Box
          pt={2}
          sx={{
            width: "100%  ",
            height: "100vh",
          }}
        >
          <iframe
            width={"100%"}
            height={"100%"}
            scrolling="no"
            frameBorder="0"
            src="https://reports.whatthestats.com/7d5c35f9-daef-42dc-9b60-e50448855f5e/af6f712a-0a09-4b95-b3c9-ee731c8a5a63"
          ></iframe>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <SourceDuplicateReport />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Box
          pt={2}
          sx={{
            width: "100%  ",
            height: "100vh",
          }}
        >
          <iframe
            width={"100%"}
            height={"100%"}
            scrolling="no"
            frameBorder="0"
            src="https://reports.whatthestats.com/baec3e5e-5457-4d80-81c2-b0e0df129b74/e90d19a9-b4b6-46e3-a932-cde76f8b782d/all/0/0"
          ></iframe>
        </Box>
      </TabPanel>
    </Box>
  );
};
export default ImportReport;
