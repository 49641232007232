import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
const GlobalDropdown = ({ control, name, label, options, ...props }) => {
  const [selectedValue, setSelectedValue] = useState(null);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        console.log(field);
        const a = options?.find((res) => res.value === field?.value);
        setSelectedValue(a);

        return (
          <Autocomplete
            {...props}
            options={options}
            size="small"
            getOptionLabel={(option) => option?.label}
            value={selectedValue}
            onChange={(_, newValue) => {
              setSelectedValue(newValue);
              if (newValue !== null) {
                field.onChange(newValue?.value);
              } else {
                field.onChange(newValue);
              }
            }}
            // onBlur={() => {
            //   field.onBlur(newValue || null);
            // }}
            renderInput={(params) => (
              <TextField
                required={props?.required || false}
                size="small"
                {...params}
                label={label}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        );
      }}
    />
  );
};
export default GlobalDropdown;

// import React from "react";
// import { TextField, MenuItem } from "@mui/material";
// import { useController } from "react-hook-form";

// const GlobalDropdown = ({
//   control,
//   name,
//   label,
//   options,
//   error,
//   helperText,
//   disabledOptions,
//   onclick,
//   ...rest
// }) => {
//   const handleClick = () => {
//     if (onclick !== undefined) {
//       onclick();
//     }
//   };
//   const {
//     field: { ref, value, onChange },
//     fieldState: { invalid },
//     // formState: { errors },
//   } = useController({
//     name,
//     control,
//     defaultValue: "",
//   });

//   return (
//     <TextField
//       sx={{
//         width: "100%",
//         textAlign: "left",
//       }}
//       select
//       label={label}
//       value={value}
//       onChange={onChange}
//       onClick={handleClick}
//       inputRef={ref}
//       error={error || invalid}
//       helperText={error?.name?.message || helperText}
//       {...rest}
//     >
//       {options?.map((option) => (
//         <MenuItem
//           key={option?.value}
//           value={option?.value}
//           disabled={disabledOptions?.includes(option?.label)}
//         >
//           {option?.label}
//         </MenuItem>
//       ))}
//       {options.length <= 0 && (
//         <MenuItem value={""} disabled={true}>
//           No Option
//         </MenuItem>
//       )}
//     </TextField>
//   );
// };

// export default GlobalDropdown;
