import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { snackbarMessageUpdate } from "@store/config/configSlice";
import { useDispatch } from "react-redux";
import { baseURL } from "@configs/envConfig";
import GlobalInput from "@components/common/GlobalInput";
import { useApiCallMutation } from "../../services/apiQueryServices";
import {
  addTableRow,
  updateTableRow,
} from "../../store/DataSourceModule/DataSourceOwnerSlice";

const NewSourceOwner = (props) => {
  const { setOpen, isOpen, defaultValues, name, loadData, isEditMode } = props;
  const [defaultValuesSchema, setDefaultValuesSchema] = useState({});
  const dispatch = useDispatch();

  const [apiCall, res] = useApiCallMutation();
  const [apiCall1, res1] = useApiCallMutation();

  useEffect(() => {
    setDefaultValuesSchema(defaultValues);
    if (isEditMode) {
      setValue("name", defaultValuesSchema?.name, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue("remark", defaultValuesSchema?.remark);
    }
  }, [defaultValuesSchema]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This Field is required"),
    remark: Yup.string().max(250, "Remark must be at most 250 characters"),
  });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      remark: "",
    },
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  useEffect(() => {
    if (res.isSuccess && res.data !== null) {
      successCallback(res.data);
      dispatch(addTableRow(res.data.data));
    }
    if (res1.isSuccess && res1.data !== null) {
      successCallback(res1.data);
      dispatch(updateTableRow(res1.data.data));
    }
  }, [res, res1]);

  const headers = {
    "Content-Type": "application/json", // Example header
    Authorization: `Bearer ${
      JSON.parse(localStorage.getItem("user"))?.token.access_token
    }`, // Example header
  };

  const successCallback = (response) => {
    const status = response?.status;
    setOpen(false);
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "success",
        message: status?.value,
      })
    );
  };
  const errorCallback = (error) => {
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "error",
        message: error.response.data.status.value,
      })
    );
  };
  //***********FORM SUBMISSION ********//
  const onFormSubmit = (data) => {
    if (!isEditMode) {
      apiCall({
        endPoint: "dl/import/source-owner/create",
        method: "POST",
        data: { data },
      });
    } else {
      apiCall1({
        endPoint: `dl/import/source-owner/update/${defaultValuesSchema?._id}`,
        method: "PUT",
        data: { data },
        headers: { headers },
      });
    }
  };

  return (
    <>
      {/* <Button onClick={() => setState(true)}>{name}</Button> */}

      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={() => setOpen(false)}
        // onKeyDown={toggleDrawer("right", false)}
      >
        <Box
          sx={{
            width: "50vw",
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Box marginBottom={"15px"}>
            <Typography component="h5" variant="h5" fontWeight={600}>
              {(isEditMode && "Update New Source Owner") ||
                "Add New Source Owner"}
            </Typography>
          </Box>
          <Divider
            sx={{
              mb: "20px",
            }}
          />

          <Box component={"form"}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {/* <TextField
                  required
                  fullWidth
                  size="small"
                  {...register("name")}
                  id="outlined-required"
                  label="Source Owner Name"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                /> */}
                <GlobalInput
                  control={control}
                  size="small"
                  name="name"
                  label="Source Owner Name"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  required
                  // disabled={isEditMode}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <TextField
                  fullWidth
                  {...register("remark")}
                  id="outlined-required"
                  label="Remark"
                  size="small"
                  multiline
                  rows={4}
                  error={!!errors.remark}
                  helperText={errors.remark?.message}
                /> */}
                <GlobalInput
                  control={control}
                  size="small"
                  name="remark"
                  label="Remark"
                  multiline
                  rows={4}
                  error={!!errors.remark}
                  helperText={errors.remark?.message}
                  // disabled={isEditMode}
                />
              </Grid>
            </Grid>
          </Box>

          <Box
            position={"fixed"}
            bottom={8}
            sx={{
              backgroundColor: "#fefffe",
              width: "100%",
            }}
          >
            <Grid container spacing={2} sx={{ width: "50vw" }}>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  style={{ width: "100%" }}
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="outlined"
                  disabled={res.isLoading || res1.isLoading}
                  style={{
                    color: "#ff831f",
                    width: "100%",
                    borderColor: "#ff831f",
                    opacity: res.isLoading || res1.isLoading ? 0.8 : 1,
                    pointerEvents:
                      res.isLoading || res1.isLoading ? "none" : "auto",
                    cursor:
                      res.isLoading || res1.isLoading
                        ? "not-allowed"
                        : "pointer",
                  }}
                  onClick={handleSubmit(onFormSubmit)}
                >
                  {res.isLoading || res1.isLoading
                    ? "Wait..."
                    : isEditMode
                    ? "Update"
                    : "Add"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default NewSourceOwner;
