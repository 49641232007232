import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const rawData = createSlice({
  name: "rawData",
  initialState,
  reducers: {
    updateRawData: (state, action) => {
      state.data = action.payload;
    },
    removeData: (state, action) => {
      state.data = state.data.filter((item) => item?.value !== action.payload);
    },
  },
});

export const { updateRawData, removeData } = rawData.actions;
export default rawData.reducer;
